import { Notification, Stack } from '@mantine/core';
import { ButtonWithConfirm } from 'shared/ui/buttons';

type PropsType = {
  confirmDeleteCallback: () => void;
  // entityType: 'org' | 'employee';
};

const FiredView = ({ confirmDeleteCallback }: PropsType) => {
  return (
    <Stack style={{ overflow: 'hidden' }}>
      <Notification withCloseButton={false} color='red'>
        Вашу запись удалили из организации.
      </Notification>
      <ButtonWithConfirm
        onConfirmCallback={confirmDeleteCallback}
        confirmColor='red'
        buttonText='Удалить запись'
        buttonSize='sm'
      />
    </Stack>
  );
};

export default FiredView;
