import { Box, Divider, FloatingPosition, Menu } from '@mantine/core';
import {
  Fragment,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Plus as PlusIcon, Trash } from 'react-feather';
import { EditIcon } from 'shared/model';

type PropsType = {
  handleAddClick?: (() => void) | undefined;
  handleDeleteClick?: (() => void) | undefined;
  handleEditClick?: (() => void) | undefined;
  position?: FloatingPosition | undefined;
  children?: ReactNode;
  disabled?: boolean;
};

export const CrudModalActionsBar = ({
  handleEditClick,
  handleDeleteClick,
  handleAddClick,
  position = 'bottom',
  children,
  disabled,
}: PropsType) => {
  const [isOpened, setIsOpened] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (buttonRef.current && buttonRef.current.contains(e.target as Node))
      return;
    setIsOpened(false);
  };

  const handleScroll = () => {
    setIsOpened(false);
  };

  const handleAddClickWrapper = (e: ReactMouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (handleAddClick) handleAddClick();
  };

  const handleEditClickWrapper = (e: ReactMouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (handleEditClick) handleEditClick();
  };

  const handleDeleteClickWrapper = (e: ReactMouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (handleDeleteClick) handleDeleteClick();
  };

  const handlePreventDefault = (
    e: ReactMouseEvent<HTMLButtonElement>,
    action: (() => void) | undefined,
  ) => {
    e.stopPropagation();
    if (action) action();
  };

  const menuItemsParams = [
    {
      text: 'Добавить',
      icon: <PlusIcon size='20px' />,
      color: '',
      action: handleAddClick,
    },
    {
      text: 'Изменить',
      icon: <EditIcon width='20px' />,
      color: '',
      action: handleEditClick,
    },
    {
      color: 'red',
      text: 'Удалить',
      icon: <Trash size='20px' />,
      action: handleDeleteClick,
    },
  ];

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const handleButtonClick = (e: ReactMouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) return;
    setIsOpened((prev) => !prev);
  };

  return (
    <Menu
      styles={{
        dropdown: { padding: 0, overflow: 'hidden', border: 'none' },
        item: { borderRadius: '0px' },
      }}
      width={250}
      opened={isOpened}
      position={position}
      radius={16}
      shadow='md'>
      <Menu.Dropdown>
        {menuItemsParams
          .filter((it) => Boolean(it.action))
          .map((item) => (
            <Fragment key={item.text}>
              <Menu.Item
                h={52}
                fz='md'
                color={item.color}
                onClick={item.action}
                rightSection={item.icon}>
                {item.text}
              </Menu.Item>
              <Divider />
            </Fragment>
          ))}
      </Menu.Dropdown>
      <Menu.Target>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={handleButtonClick}
          ref={buttonRef}>
          {children}
        </Box>
      </Menu.Target>
    </Menu>
  );
};
