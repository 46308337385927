import { Button, DefaultMantineColor, Group, MantineSize } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatedSwitchSlideHorizontally150px250ms } from 'shared/ui/switch-transition-animation';

type PropsType = {
  onConfirmCallback: () => void;
  buttonText: string;
  confirmColor?: DefaultMantineColor;
  buttonSize?:
    | string
    | MantineSize
    | 'compact-xs'
    | 'compact-sm'
    | 'compact-md'
    | 'compact-lg'
    | 'compact-xl';
};
const ButtonWithConfirm = ({
  onConfirmCallback,
  confirmColor,
  buttonSize,
  buttonText,
}: PropsType) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const ref = useClickOutside(() => setIsDialogOpen(false));
  return (
    <AnimatedSwitchSlideHorizontally150px250ms
      direction={isDialogOpen ? 'right' : 'left'}
      reverseInOutDirection={true}
      watchedKey={isDialogOpen}>
      {isDialogOpen ? (
        <Group
          justify='space-between'
          gap='xs'
          ref={ref}
          style={{ overflow: 'hidden' }}>
          <Button
            size={buttonSize}
            variant='light'
            flex={1}
            color='gray'
            onClick={() => setIsDialogOpen(false)}>
            {t('SHARED.commonVerbs.cancel')}
          </Button>
          <Button
            size={buttonSize}
            variant='light'
            flex={1}
            color={confirmColor}
            onClick={() => {
              onConfirmCallback();
              setIsDialogOpen(false);
            }}>
            {t('SHARED.commonVerbs.confirm')}
          </Button>
        </Group>
      ) : (
        <Button
          size={buttonSize}
          variant='light'
          color={confirmColor}
          onClick={() => setIsDialogOpen(true)}>
          {buttonText}
        </Button>
      )}
    </AnimatedSwitchSlideHorizontally150px250ms>
  );
};

export default ButtonWithConfirm;
