import ky from 'ky';
import { or } from 'ramda';

type RequestType = 'TIPS' | 'SBP' | 'FILE' | 'API' | 'ALERT';
export const request = (service: RequestType) => {
  console.log(`
  BOOTSTRAP SETTINGS CHECK: 
  _____________________________________
  API_SERVICE_PROXY_URL: ${window.API_SERVICE_PROXY_URL},
  API_SERVICE_BASE_URL: ${window.API_SERVICE_BASE_URL},

  SBP_SERVICE_PROXY_URL: ${window.SBP_SERVICE_PROXY_URL},
  SBP_SERVICE_BASE_URL: ${window.SBP_SERVICE_BASE_URL},

  FILE_SERVICE_PROXY_URL: ${window.FILE_SERVICE_PROXY_URL},
  FILE_SERVICE_BASE_URL: ${window.FILE_SERVICE_BASE_URL}, 

  TIPS_SERVICE_PROXY_URL: ${window.TIPS_SERVICE_PROXY_URL},
  TIPS_SERVICE_BASE_URL: ${window.TIPS_SERVICE_BASE_URL}, 
  
  ALERT_SERVICE_BASE_URL: ${window.ALERT_SERVICE_PROXY_URL},
  ALERT_SERVICE_PROXY_URL: ${window.ALERT_SERVICE_BASE_URL},
  `);
  const prefixUrlMap = {
    TIPS: or(window.API_SERVICE_PROXY_URL, window.API_SERVICE_BASE_URL),
    SBP: or(window.SBP_SERVICE_PROXY_URL, window.SBP_SERVICE_BASE_URL),
    FILE: or(window.FILE_SERVICE_PROXY_URL, window.FILE_SERVICE_BASE_URL),
    API: or(window.TIPS_SERVICE_PROXY_URL, window.TIPS_SERVICE_BASE_URL),
    ALERT: or(window.ALERT_SERVICE_PROXY_URL, window.ALERT_SERVICE_BASE_URL),
  };

  return ky.create({
    credentials: 'include',
    headers: {
      Authorization: '86859005-b87f-4d71-8f3a-70b03cf697e1',
    },
    prefixUrl: prefixUrlMap[service],
    throwHttpErrors: true,
    timeout: 30000,
  });
};
