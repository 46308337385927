import { getThemeColor, useMantineTheme } from '@mantine/core';
import { headerCollapsableBoxStateEdited } from 'entities/app-flows';
import { useHeader } from 'features/header';
import { UserVisitsHistory } from 'features/visit';
import { useEffect } from 'react';

import { useUnit } from 'effector-react';
import { $currentSessionInfo } from 'entities/store';
import { HomePageTopPanel } from 'widgets/user-flow/home-page-top-panel';

const Page = () => {
  const theme = useMantineTheme();
  const { 0: currentSessionInfo } = useUnit([$currentSessionInfo]);
  const orgId = currentSessionInfo?.orgId;
  const userId = currentSessionInfo?.userId;
  const employeeId = currentSessionInfo?.employeeId;

  useHeader({
    background: 'colorful',
    contentBg: getThemeColor('white', theme),
    collapsableBoxChildren: <HomePageTopPanel />,
  });
  useEffect(() => {
    headerCollapsableBoxStateEdited({ opacity: 1, scrollY: 0 });
  }, []);

  return <UserVisitsHistory userId={userId} employeeId={employeeId} />;
};

export default Page;
