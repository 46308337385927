import { backgroundColorReset } from 'entities/app-flows';
import { $header, headerEdited, headerReset } from 'entities/app-flows/header';
import { useEffect } from 'react';

const targetStore = $header.getState();

type PropsType = Partial<typeof targetStore>;

export function useHeader(args: PropsType) {
  useEffect(() => {
    headerEdited({ ...args });
    return () => {
      headerReset();
      backgroundColorReset();
    };
  }, [args]);
}
