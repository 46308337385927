import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_VISIT } from 'entities/visit/endpoints';
import { prop } from 'ramda';

export const visitCreateOneFx = createApiEffect<
  typeof ENDPOINTS_VISIT.CREATE_ONE.req,
  typeof ENDPOINTS_VISIT.CREATE_ONE.res
>({
  fxName: 'visitCreateOneFx',
  service: 'TIPS',
  endpoint: prop('CREATE_ONE', ENDPOINTS_VISIT),
});

// export const visitGetManyFx = createApiEffect<
//   typeof ENDPOINTS_VISIT.GET_MANY.req,
//   typeof ENDPOINTS_VISIT.GET_MANY.res
// >({
//   fxName: false,
//   service: 'API',
//   endpoint: ENDPOINTS_VISIT.GET_MANY,
// });
export const visitGetManyFx = createApiEffect<
  typeof ENDPOINTS_VISIT.GET_MANY.req,
  typeof ENDPOINTS_VISIT.GET_MANY.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_VISIT.GET_MANY,
});

export const visitGetStatisticFx = createApiEffect<
  typeof ENDPOINTS_VISIT.GET_STATISTICS.req,
  typeof ENDPOINTS_VISIT.GET_STATISTICS.res
>({
  service: 'TIPS',
  endpoint: ENDPOINTS_VISIT.GET_STATISTICS,
  fxName: false,
});

export const visitGetStatisticsGroupedFx = createApiEffect<
  typeof ENDPOINTS_VISIT.GET_STATISTICS_GROUPED.req,
  typeof ENDPOINTS_VISIT.GET_STATISTICS_GROUPED.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_VISIT.GET_STATISTICS_GROUPED,
});
