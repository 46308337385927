import { createStore } from 'effector';
import { SharingRuleEntityType } from 'entities/entities-types';
import {
  sharingRuleGetAllOwnFx,
  sharingRuleGetAllOwnInOrgFx,
} from 'entities/sharing-rule/effects';

export const $sharingRulesAllOwn = createStore<SharingRuleEntityType[]>([]).on(
  sharingRuleGetAllOwnFx.doneData,
  (state, payload) => payload.data,
);
export const $sharingRulesAllOwnInOrg = createStore<SharingRuleEntityType[]>(
  [],
).on(sharingRuleGetAllOwnInOrgFx.doneData, (state, payload) => payload.data);
