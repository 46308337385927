import {
  Box,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { $userOneOwn, getUserFio } from 'entities/user';
import { ReactNode } from 'react';
import { ChevronRight as ChevronRightIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { toPhoneFormat } from 'shared/lib/formatters';
import { PATHS } from 'shared/model/const';
import {
  IconEmail,
  IconLock,
  IconPhone,
  IconUsers,
} from 'shared/model/vector-images';
import { NavLinkBorder } from 'shared/ui/linkBorder';

type ListItemPropsType = {
  href: typeof PATHS['/user']['currentView'];
  text: string;
  label?: string;
  icon: ReactNode;
  action?: () => void;
};

type PropsType = {};

export const MyUserDataEditBox = ({}: PropsType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useUnit($userOneOwn);
  const theme = useMantineTheme();
  const listItems: ListItemPropsType[] = [
    {
      href: `my-fio-change`,
      label: t('SHARED.commonNouns.fio'),
      text: getUserFio(currentUser),
      icon: <IconUsers />,
    },
    {
      href: `my-phone-change`,
      label: t('SHARED.commonNouns.phoneNumber'),

      text: currentUser?.phoneNumber
        ? toPhoneFormat(currentUser.phoneNumber)
        : '-',
      icon: <IconPhone />,
    },
    {
      href: `my-email-change`,
      label: t('SHARED.commonNouns.email'),

      text: currentUser?.email || '-',
      icon: <IconEmail />,
    },
    {
      href: `my-password-change`,
      text: 'Сменить пароль',
      icon: <IconLock />,
    },
  ];

  return (
    <Stack gap='md'>
      <Title>Данные и безопасность</Title>
      <Stack
        bg='white'
        style={{ overflow: 'hidden', borderRadius: '16px' }}
        gap={0}>
        {listItems.map((item) => {
          return (
            <NavLinkBorder
              h={60}
              onClick={() =>
                navigate(getHref({ '/user': { currentView: item.href } }))
              }
              label={
                <Text fz={14} c='dimmed'>
                  {item.label}
                </Text>
              }
              description={
                <Box w={{ base: 220, sm: 400 }}>
                  <Text c='dark' fz={16} truncate='end'>
                    {item.text}
                  </Text>
                </Box>
              }
              key={item.href}
              leftSection={
                <ThemeIcon variant='transparent' size='md'>
                  {item.icon}
                </ThemeIcon>
              }
              rightSection={
                <ThemeIcon color='gray' variant='transparent' size='lg'>
                  <ChevronRightIcon />
                </ThemeIcon>
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
