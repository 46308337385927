export const defineTypeOfInput = (
  value: string | number,
  defLenght: number,
) => {
  if (
    value?.toString().length >= defLenght &&
    value
      ?.toString()
      .split('')
      .every((char: string) => char.match(/[0-9]/g))
  ) {
    return 'phone';
  }
  if (value?.toString().includes('@')) {
    return 'email';
  }
  return 'uncertain';
};
