import { Badge, BadgeVariant, Group } from '@mantine/core';
import { CategoryItemEntityType } from 'entities/entities-types';
import { or } from 'ramda';
import { generateColorFromId } from 'shared/lib/generators';

type PropsType = {
  categoryItems: CategoryItemEntityType[];
  badgeVariant?: BadgeVariant;
};

const CategoryItemsGroup = ({ categoryItems, badgeVariant }: PropsType) => {
  return (
    <Group gap={5}>
      {categoryItems?.map?.((catItem) => (
        <Badge
          size='xs'
          variant={or(badgeVariant, 'light')}
          key={catItem.categoryItemId}
          color={generateColorFromId(catItem?.categoryTitleId)}
          // color={generateColorFromId(catItem.categoryItemId)}
        >
          {catItem.name}
        </Badge>
      ))}
      {or(categoryItems?.length, 0) < 1 && (
        <Badge size='xs' variant={or(badgeVariant, 'light')} color='gray'>
          Нет категорий
        </Badge>
      )}
    </Group>
  );
};

export default CategoryItemsGroup;
