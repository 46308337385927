import { Notification, Stack } from '@mantine/core';
import { ButtonWithConfirm } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';

type PropsType = {
  entityType: 'org' | 'employee';
  onCancelConfirm: () => void;
};

const ProcessingView = ({ entityType, onCancelConfirm }: PropsType) => {
  return (
    <Stack style={{ overflow: 'hidden' }}>
      <Condition when={entityType === 'employee'}>
        <Notification withCloseButton={false}>
          Ваша заявка на вступление в организацию в процессе обработки
        </Notification>
      </Condition>
      <Condition when={entityType === 'org'}>
        <Notification withCloseButton={false}>
          Ваша заявка на регистрацию заведения в процессе обработки
        </Notification>
      </Condition>
      <ButtonWithConfirm
        onConfirmCallback={onCancelConfirm}
        buttonText='Отменить заявку'
        buttonSize='sm'
        confirmColor='red'
      />
    </Stack>
  );
};

export default ProcessingView;
