import { OrgInvitationEntityType } from 'entities/entities-types';
import { allPass, pick, pipe, propIs } from 'ramda';
import { comparePropsGte } from 'shared/lib/math';

const getNow = () => new Date().getTime();

export const getIsOrgInvUsedUp = (
  orgInv: OrgInvitationEntityType | undefined | null,
) =>
  allPass([
    propIs(Number, 'count'),
    propIs(Number, 'maxUsesCount'),
    pipe(
      pick(['count', 'maxUsesCount']),
      comparePropsGte('count', 'maxUsesCount'),
    ),
  ])(orgInv);
