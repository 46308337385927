import { createStore } from 'effector';
import { RatingEntityType } from 'entities/entities-types';
import { ratingGetManyFx, ratingGetOneFx } from 'entities/rating/effects';

type RatingMapType = {
  users: {
    [key: string]: RatingEntityType;
  };
  orgs: {
    [key: string]: RatingEntityType;
  };
  employees: {
    [key: string]: RatingEntityType;
  };
};

export const $ratingMap = createStore<RatingMapType>({
  orgs: {},
  users: {},
  employees: {},
}).on(ratingGetOneFx.done, (state, payload) => {
  const result = state;

  const userId = payload.params?.userId;
  const orgId = payload.params?.orgId;
  const employeeId = payload.params?.employeeId;

  if (userId) result.users[String(userId)] = payload.result.data;
  else if (orgId) result.orgs[String(orgId)] = payload.result.data;
  else if (employeeId)
    result.employees[String(employeeId)] = payload.result.data;
  return result;
});

export const $ratingMany = createStore<RatingEntityType[] | []>([]).on(
  ratingGetManyFx.doneData,
  (state, payload) => {
    return payload.data;
  },
);
