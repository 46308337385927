import {
  Button,
  Divider,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import dayjs from 'dayjs';
import { $formBalanceWithdrawalUser } from 'entities/balance';
import { VisitEntityType } from 'entities/entities-types';
import { FeedbackGradeIcon } from 'entities/feedback';
import { Link } from 'react-router-dom';
import {
  getFeedbackGradeText,
  getHref,
  getTransactionStatus,
} from 'shared/lib';
import { visitTransactionStatusColor } from 'shared/lib/generators';
import { Condition } from 'shared/ui/display';

type PropTypes = ContextModalProps<{
  visit: VisitEntityType;
  isOwnVisit: boolean;
}>;

const VisitDataModal = ({ context, id, innerProps }: PropTypes) => {
  const isWithdrawalButtonShown =
    innerProps.isOwnVisit &&
    innerProps.visit.visitTransaction?.status === 'completed';

  const feedbackMessage = innerProps.visit.visitFeedback?.message;

  const dataArray = [
    {
      title: 'Сумма комиссии',
      value: innerProps.visit.visitTransaction?.totalCommission ?? 0,
      isShown: !!innerProps.visit.visitTransaction?.isCommissionCompensated,
    },
    {
      title: 'Сумма чека',
      value: innerProps.visit.bill ?? 0,
      isShown: Boolean(innerProps.visit.bill),
    },
    {
      title: 'Номер операции',
      value: innerProps.visit.visitTransaction?.transactionUUID ?? 0,
      isShown: true,
    },
    {
      title: 'Статус',
      isShown: false,
      value: getTransactionStatus(
        innerProps.visit.visitTransaction?.status ?? '',
      ),
    },
  ];

  return (
    <Stack>
      <Group justify='space-between'>
        <Group align='center' justify='center' gap={20}>
          <ThemeIcon color='gray' variant='light' size={50} radius={1000}>
            <FeedbackGradeIcon
              feedbackGrade={innerProps?.visit?.visitFeedback?.grade}
              size={30}
            />
          </ThemeIcon>
          <Title order={3}>
            {getFeedbackGradeText(innerProps.visit.visitFeedback?.grade)}
          </Title>
        </Group>
        <Title
          order={3}
          c={visitTransactionStatusColor(
            innerProps.visit?.visitTransaction?.status,
          )}>
          {innerProps.visit?.visitTransaction?.status !== 'failed' && '+'}
          {innerProps.visit.visitTransaction?.netAmount}₽
        </Title>
      </Group>
      <Title order={2}>
        {getTransactionStatus(innerProps.visit.visitTransaction?.status ?? '')}
      </Title>
      <Text c='dimmed'>
        {dayjs(innerProps.visit.visitTransaction.createdAt).format(
          'YYYY-MM-DD HH:mm:ss',
        )}
      </Text>
      <Condition
        when={Boolean(
          innerProps?.visit?.visitTransaction?.isCommissionCompensated,
        )}>
        <Text
          c='brand'
          bg='blue.1'
          style={{ borderRadius: '10px' }}
          px={10}
          py={5}
          component='p'
          w='fit-content'
          fz='sm'>
          Комиссия оплачена гостем
        </Text>
      </Condition>
      <Divider h={2} />
      {dataArray.map((item, index) => (
        <Condition when={item.isShown} key={index}>
          <Group key={index} justify='space-between'>
            <Text c='dimmed'>{item.title}:</Text>
            <Text>{item.value}</Text>
          </Group>
        </Condition>
      ))}
      <Divider h={2} />
      {Boolean(feedbackMessage) && (
        <Stack gap={5} p={16} bg='gray.1' style={{ borderRadius: '10px' }}>
          <Text c='dimmed'>Отзыв:</Text>
          <Text>{feedbackMessage}</Text>
        </Stack>
      )}
      {isWithdrawalButtonShown && (
        <Button
          component={Link}
          to={getHref({ '/user': { currentView: 'balance-withdrawal' } })}
          onClick={() => {
            context.closeModal(id);
            $formBalanceWithdrawalUser.setForm({
              sum: innerProps.visit.visitTransaction?.netAmount,
            });
          }}>
          Вывести
        </Button>
      )}
    </Stack>
  );
};

export default VisitDataModal;
