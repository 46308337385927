import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_ORG } from './endpoints';

export const orgCreateOneOwnProposedFx = createApiEffect<
  typeof ENDPOINTS_ORG.CREATE_ONE_OWN_PROPOSED.req,
  typeof ENDPOINTS_ORG.CREATE_ONE_OWN_PROPOSED.res
>({
  fxName: 'orgCreateOneProposedFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.CREATE_ONE_OWN_PROPOSED,
});
export const orgGetOneFx = createApiEffect<
  typeof ENDPOINTS_ORG.GET_ONE.req,
  typeof ENDPOINTS_ORG.GET_ONE.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.GET_ONE,
});
export const orgEditOneOwnFx = createApiEffect<
  typeof ENDPOINTS_ORG.EDIT_ONE_OWN.req,
  typeof ENDPOINTS_ORG.EDIT_ONE_OWN.res
>({
  fxName: 'orgEditOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.EDIT_ONE_OWN,
});
export const orgCancelOneOwnFx = createApiEffect<
  typeof ENDPOINTS_ORG.CANCEL_ONE_OWN.req,
  typeof ENDPOINTS_ORG.CANCEL_ONE_OWN.res
>({
  fxName: 'orgCancelOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.CANCEL_ONE_OWN,
});
export const orgChangeOneOwnAvatarFx = createApiEffect<
  typeof ENDPOINTS_ORG.EDIT_ONE_OWN_AVATAR.req,
  typeof ENDPOINTS_ORG.EDIT_ONE_OWN_AVATAR.res
>({
  fxName: 'orgChangeOneAvatarFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.EDIT_ONE_OWN_AVATAR,
});

export const orgDenyOneOwnFx = createApiEffect<
  typeof ENDPOINTS_ORG.DENY_ONE_OWN.req,
  typeof ENDPOINTS_ORG.DENY_ONE_OWN.res
>({
  fxName: 'orgDenyOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG.DENY_ONE_OWN,
});
