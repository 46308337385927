import { Box, BoxProps, Overlay, useMantineTheme } from '@mantine/core';
import { PropsWithChildren, useState } from 'react';

type PropsType = PropsWithChildren<{
  onClick?: () => void;
  disabled?: boolean;
  hidePassiveFill?: boolean;
  color?: string;
  noActive?: boolean; // Нужен чтобы делать кнопку не активной, но при этом не делать ее прозрачной
}> &
  BoxProps;

export const PressableBox = ({
  children,
  disabled,
  onClick,
  hidePassiveFill,
  color,
  noActive,
  ...props
}: PropsType) => {
  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const handlePointerDown = () => {
    setIsActive(true);
  };
  const handlePointerUp = () => {
    setIsActive(false);
  };
  const handlePointerOver = () => {
    if (disabled || noActive) return;
    setIsHover(true);
  };
  const handlePointerLeave = () => {
    if (disabled || noActive) return;
    setIsHover(false);
    setIsActive(false);
  };

  const handleOnClick = () => {
    if (disabled || noActive) return;
    onClick && onClick();
  };

  const passiveOverlayOpacity = hidePassiveFill ? 0 : 0.2;
  const hoverOverlayOpacity = 0.2;
  const activeOverlayOpacity = 0.4;

  const theme = useMantineTheme();

  const overlayFillingColor = color ? color : theme.white;
  const overlayOpacity = isHover
    ? isActive
      ? activeOverlayOpacity
      : hoverOverlayOpacity
    : passiveOverlayOpacity;

  return (
    <Box
      {...props}
      style={{
        ...props.style,
        zIndex: 5,
        position: 'relative',
        overflow: 'hidden',
        cursor: disabled || noActive ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1 || (noActive && 1),
      }}
      onClick={handleOnClick}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerLeave}
      onPointerOver={handlePointerOver}>
      <Overlay
        color={overlayFillingColor}
        opacity={overlayOpacity}
        style={{ transition: 'all 0.2s' }}
      />
      {children}
    </Box>
  );
};
