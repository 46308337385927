import {
  VisitCreateOneEntityType,
  VisitEntityType,
  VisitStatisticsType,
} from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api/types';
//
/* --- ЭНДПРОИНТЫ --- */
export const ENDPOINTS_VISIT = {
  CREATE_ONE: {
    path: '/visit/create_one',
    method: 'post' as HttpMethod,
    req: {
      userId: 1,
      visitFeedback: {
        message: 'Отлично',
        grade: 5,
      },
      visitTransaction: { billAmount: 15000, grossAmount: 2000 },
    } as VisitCreateOneEntityType,
    res: { message: 'OK' } as TipsApiResType<void>,
  },

  // GET_MANY: {
  //   path: 'visit/get_many_v2',
  //   method: 'post' as HttpMethod,
  //   req: {} as PaginatedReqType<{
  //     employeeIds: number[];
  //     feedbackIds: number[];
  //     orgIds: number[];
  //     receiverEntities: number[];
  //     transactionIds: number[];
  //     userIds: number[];
  //     visitCreatedAtMax: Date;
  //     visitCreatedAtMin: Date;
  //     visitIds: number[];
  //     visitStatuses: ('received' | 'completed' | 'in_progress' | 'failed')[];
  //     visitUUIDs: string[];
  //   }>,
  //   res: {} as TipsApiResType<PaginatedResType<VisitEntityType>>,
  // },

  // GET_MANY: {
  //   path: 'visit/get_many_v2',
  //   method: 'post' as HttpMethod,
  //   req: {} as PaginatedReqType<{
  //     feedbackIds?: number[];
  //     transactionIds?: number[];
  //     orgIds?: number[];
  //     userIds?: number[];
  //     employeeIds?: number[];
  //     receiverEntities?: ('user' | 'org' | 'employee')[];
  //     visitStatuses?: ('received' | 'completed' | 'in_progress' | 'failed')[];
  //     visitIds?: number[];
  //     visitUUIDs?: string[];
  //     createdAtMax?: Date;
  //     createdAtMin?: Date;
  //   }>,
  //   res: {} as TipsApiResType<PaginatedResType<VisitEntityType>>,
  // },

  GET_MANY: {
    path: 'visit/get_many_v2',
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<{
      transactionUUIds?: string[];
      feedbackIds?: number[];
      orgIds?: number[];
      userIds?: number[];
      employeeIds?: number[];
      visitIds?: number[];
      receiverEntities?: ('user' | 'org' | 'employee')[];

      metaItemIds?: number[];
      visitStatuses?: ('received' | 'completed' | 'in_progress' | 'failed')[];
      visitCreatedAtMax?: Date;
      visitCreatedAtMin?: Date;
    }>,
    res: {} as TipsApiResType<PaginatedResType<VisitEntityType>>,
  },

  GET_STATISTICS: {
    path: 'visit/statistics',
    method: 'post' as HttpMethod,
    req: {} as {
      createdAtMax?: Date;
      createdAtMin?: Date;
      groupBy: 'day' | 'month' | 'all';
      receiverEntities: ('user' | 'org' | 'employee')[];
      orgIds?: number[];
      userIds?: number[];
      employeeIds?: number[];
      categoryItemIds?: number[];
    },
    res: {} as TipsApiResType<VisitStatisticsType>,
  },
  GET_STATISTICS_GROUPED: {
    path: 'visit/statistics_grouped',
    method: 'post' as HttpMethod,
    req: {} as {
      createdAtMax?: Date;
      createdAtMin?: Date;
      groupBy: 'day' | 'month' | 'all';
      receiverEntities: ('user' | 'org' | 'employee')[];
      orgIds?: number[];
      userIds?: number[];
      employeeIds?: number[];
      categoryItemIds?: number[];
    },
    res: {} as TipsApiResType<{
      [key: string]: VisitStatisticsType;
    }>,
  },
};
