import { or } from 'ramda';

export const getPic = (uuid: string | null | undefined) => {
  if (!uuid) return undefined;
  const origin = or(
    window.FILE_SERVICE_PROXY_URL,
    window.FILE_SERVICE_BASE_URL,
  );
  return `${origin}/static/${uuid}`;
};
