import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';

import {
  Cafe,
  Delivery,
  DriversCouriers,
  Education,
  Entertainment,
  GasStations,
  Hotels,
  Nails,
} from 'shared/model';

export const GettingTips = () => {
  const list = [
    { icon: Cafe, text: 'Рестораны и кафе' },
    { icon: Hotels, text: 'Гостиничный сервис' },
    { icon: GasStations, text: 'Автозаправки' },
    { icon: DriversCouriers, text: 'Сервисы такси' },
    { icon: Education, text: 'Образовательные платформы' },
    { icon: Entertainment, text: 'Развлекательные мероприятия' },
    { icon: Nails, text: 'Салоны красоты' },
    { icon: Delivery, text: 'Сервисы доставки' },
  ];

  return (
    <Flex id='getting-tips' direction='column' gap={{ base: 24, sm: 36 }}>
      <Title ta='center' fz={{ base: 28, md: 56 }}>
        Кто может получать чаевые
      </Title>

      <SimpleGrid
        style={{ justifyContent: 'space-between' }}
        spacing={{ base: 20, sm: 44 }}
        cols={{ base: 2, sm: 3, md: 4 }}>
        {list.map((item) => (
          <Stack gap={30} pos='relative' align='center' key={item.text}>
            <Box
              w={{ xs: 200, base: '100%' }}
              h={140}
              style={{ borderRadius: '44px' }}
              bg='background'></Box>
            <Image
              style={{ objectFit: 'contain' }}
              top='60px'
              pos='absolute'
              h={100}
              src={item.icon}
            />

            <Text ta='center' fz={{ base: 16, sm: 20 }}>
              {item.text}
            </Text>
          </Stack>
        ))}
      </SimpleGrid>
    </Flex>
  );
};
