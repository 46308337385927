import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_BALANCE } from './endpoints';

export const balanceGetOneOwnOfUserFx = createApiEffect<
  typeof ENDPOINTS_BALANCE.GET_ONE_OWN.req,
  typeof ENDPOINTS_BALANCE.GET_ONE_OWN.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_BALANCE.GET_ONE_OWN,
});

export const balanceGetOneOwnOfOrgFx = createApiEffect<
  typeof ENDPOINTS_BALANCE.GET_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_BALANCE.GET_ONE_OWN_IN_ORG.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_BALANCE.GET_ONE_OWN_IN_ORG,
});
