import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { visitGetStatisticFx } from 'entities/visit/effects';
import { ENDPOINTS_VISIT } from 'entities/visit/endpoints';

export const $$totalNetAmountUser = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_VISIT.GET_STATISTICS.req,
  typeof ENDPOINTS_VISIT.GET_STATISTICS.res
>({
  initialReq: {
    receiverEntities: ['user'],
    userIds: [0],
    groupBy: 'all',
  },
  initialFx: visitGetStatisticFx,
});
