import { Loader, Stack } from '@mantine/core';

const LoadingWindow = () => {
  return (
    <Stack style={{ height: '80dvh' }} justify='center' align='center'>
      <Loader size='xl' variant='bars' />
    </Stack>
  );
};

export default LoadingWindow;
