import { PropsWithChildren } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

interface PropsType {
  watchedKey: any;
}

export const AnimatedSwitchFade100ms = ({
  watchedKey,
  children,
}: PropsWithChildren<PropsType>) => {
  return (
    <SwitchTransition>
      <CSSTransition classNames='fade100ms' timeout={100} key={watchedKey}>
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};
