import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
// eslint-disable-next-line import/no-internal-modules
import 'shared/styles/index.css';

import type { PropsWithChildren } from 'react';
import { getCustomTheme } from './theme';

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const lightThemeOverride = getCustomTheme('light');
  const theme = createTheme({ ...lightThemeOverride });

  return (
    <MantineProvider theme={theme} defaultColorScheme='light'>
      <Notifications />
      {children}
    </MantineProvider>
  );
};

export default ThemeProvider;
