import { MantineColor } from '@mantine/core';
import { FeedbackGradeValueType } from 'entities/entities-types';
import { T, always, cond, equals } from 'ramda';
import { useTranslation } from 'react-i18next';

type REQ = undefined | FeedbackGradeValueType;

export function useGetFeedbackUiParamsByGrade() {
  const { t } = useTranslation();

  return cond<REQ[], { text: string; textColor: MantineColor }>([
    [
      equals(1 as REQ),
      always({ text: t('FEEDBACK.components.grade.1'), textColor: 'red' }),
    ],
    [
      equals(3 as REQ),
      always({ text: t('FEEDBACK.components.grade.3'), textColor: 'green' }),
    ],
    [
      equals(5 as REQ),
      always({ text: t('FEEDBACK.components.grade.5'), textColor: 'green' }),
    ],
    [
      T,
      always({
        text: t('FEEDBACK.components.grade.empty'),
        textColor: 'dimmed',
      }),
    ],
  ]);
}
