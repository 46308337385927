import { Stack, Text, Title } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

type PropsType = ContextModalProps<{}>;

const TransferPaymentModal = ({}: PropsType) => {
  return (
    <Stack align='center'>
      {/* <ChainikMainIcon /> */}
      <Title order={5}>Спасибо, что воспользовались нашим сервисом!</Title>
      <Text>Вы будете перенаправлены на сайт для оплаты Best2Pay.</Text>
      {/* <Text>Вы будете перенаправлены на сайт для оплаты Best2Pay.</Text> */}
    </Stack>
  );
};

export default TransferPaymentModal;
