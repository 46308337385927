import dayjs from 'dayjs';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { transactionGetStatisticsFx } from 'entities/transaction/effects';
import { ENDPOINTS_TRANSACTION } from 'entities/transaction/endpoints';

export const $$transactionStatCurrentUser = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_TRANSACTION.GET_STATISTICS.req,
  typeof ENDPOINTS_TRANSACTION.GET_STATISTICS.res
>({
  initialReq: {
    createdAtMin: dayjs().toDate(),
    createdAtMax: dayjs().toDate(),
    groupBy: 'month',
    balanceIds: [0],
    entities: ['user'],
  },
  initialFx: transactionGetStatisticsFx,
});
