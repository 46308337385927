import { append, ifElse, includes, without } from 'ramda';

export const toggleNumber = (num: number, arr: number[]) =>
  ifElse(includes(num), without([num]), append(num))(arr);

export const toggleString = (str: string, arr: string[]) =>
  ifElse(includes(str), without([str]), append(str))(arr);

export function toggleArrayItem<T>(str: T, arr: T[]): T[] {
  return ifElse(includes(str), without([str]), append(str))(arr);
}
