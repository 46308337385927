import { createStore, sample } from 'effector';
import { EmployeeEntityType } from 'entities/entities-types';

import { getEmployeeFromXuevyiEmp } from 'entities/employee';
import {
  employeeCancelOneOwnFx,
  employeeDeleteOneOwnFx,
  employeeGetOneFx,
  employeeLogoutOneOwnFx,
  employeeSwitchOneOwnFx,
} from 'entities/employee/effects';
import {
  currentSessionInvalidated,
  userOneOwnInvalidated,
} from 'entities/events';
import { employeeOneOwnChanged } from './events';

sample({
  clock: [
    employeeSwitchOneOwnFx.done,
    employeeLogoutOneOwnFx.done,
    employeeCancelOneOwnFx.done,
    employeeDeleteOneOwnFx.done,
  ],
  target: [userOneOwnInvalidated, currentSessionInvalidated],
});

export const $employeeOneCurrent = createStore<EmployeeEntityType | null>(null);
export const $employeeManyOrgTop = createStore<{
  gradeTop: EmployeeEntityType[];
  tipsTop: EmployeeEntityType[];
}>({ gradeTop: [], tipsTop: [] });

$employeeOneCurrent
  .on(employeeGetOneFx.doneData, (_, payload) => {
    return getEmployeeFromXuevyiEmp(payload.data);
  })
  // Отвечает за изменение токена, поэтому ничего не возвращает
  .on(employeeSwitchOneOwnFx, () => undefined)
  .reset(employeeOneOwnChanged);
