import { Button, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { userDeleteOneOwnFx } from 'entities/user';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type PropsType = {};

export const UserOwnDeleteButton = ({}: PropsType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleSignOut = useCallback(() => {
    userDeleteOneOwnFx().then(() => navigate('/auth'));
  }, []);
  const handleClick = () =>
    modals.openConfirmModal({
      title: t('SHARED.form.confirmTheAction'),
      children: (
        <Text>{t('USER.components.userOwnDeleteConfirmQuestion')}</Text>
      ),
      labels: {
        confirm: t('SHARED.commonVerbs.confirm'),
        cancel: t('SHARED.commonVerbs.cancel'),
      },
      confirmProps: { color: 'red', size: 'sm' },
      cancelProps: { size: 'sm' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        // TODO: добавить ручку удаления пользователя
        handleSignOut();
        console.log('Confirmed');
      },
    });

  return (
    <Button variant='white' onClick={handleClick} c='red'>
      {t('SERVER_ACTIONS.userDeleteOneFx.button')}
    </Button>
  );
};
