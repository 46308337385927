import { createForm } from 'effector-forms';
import { formRules } from 'shared/lib/forms';
import { GuestVisitFormType } from '../types';

export const $guestVisitAmountForm = createForm<GuestVisitFormType>({
  validateOn: ['blur', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
    netAmount: {
      init: null,
      rules: [formRules.required()],
    },
    isCommissionCompensated: {
      init: true,
    },
    feedbackGrade: {
      init: 5,
    },
    feedbackMessage: {
      init: '',
    },
    paymentMethod: { init: 'SBP' },
  },
});
