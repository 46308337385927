import { ActionIcon, Flex, Space, Text, useMantineTheme } from '@mantine/core';
import { useUnit } from 'effector-react';
import {
  $header,
  $headerCollapsableBoxParams,
  $headerMaxHeight,
} from 'entities/app-flows/header';

import { ReactNode, memo } from 'react';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ColorfulContainerWithBubbles } from 'shared/ui/display';

type PropsType = {
  height: number;
  rightSection: ReactNode | null;
  backButtonAction: (() => void) | null;
  invertColor: boolean;
};

export const HeaderNavBar = memo(function HeaderNavBar({
  height,
  rightSection,
  invertColor,
  backButtonAction,
}: PropsType) {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { background, navTitle } = useUnit($header);
  const { opacity, gap } = useUnit($headerCollapsableBoxParams);
  const maxHeight = useUnit($headerMaxHeight);
  const pageTitle = navTitle;
  const textColor = Boolean(background === 'colorful' && opacity !== 0)
    ? theme.white
    : theme.black;

  const handleBackIconClick = () => {
    if (backButtonAction) backButtonAction();
    else navigate(-1);
  };

  return (
    <Flex
      style={{
        position: 'relative',
        transition: 'all 0.5s',
        flexShrink: 0,
      }}
      px='md'>
      <ColorfulContainerWithBubbles
        background={background}
        isActive={Boolean(background !== 'common' && opacity !== 0)}
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          height: `calc(100% + (${maxHeight}px + 22px ))`,
        }}
      />

      <Flex
        opacity={Boolean(pageTitle) ? 1 : 0}
        h={Boolean(pageTitle) ? height : 0}
        align='center'
        justify='space-between'
        w='100%'
        gap={20}
        ta='center'
        style={{ zIndex: 1, color: textColor, transition: 'all 0.5s' }}>
        <ActionIcon
          size='lg'
          c='inherit'
          color='inherit'
          variant='subtle'
          style={{ transition: 'all 0.3s' }}
          onClick={handleBackIconClick}
          aria-label='Sign out'>
          <ChevronLeftIcon size='100%' />
        </ActionIcon>

        <Text fz='md' fw='bold' ta='center'>
          {pageTitle}
        </Text>
        {Boolean(rightSection) ? rightSection : <Space w='lg' />}
      </Flex>
    </Flex>
  );
});
