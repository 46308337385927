import { Stack, Text } from '@mantine/core';

type PropsType = {};

// eslint-disable-next-line react/display-name,import/no-anonymous-default-export
const RemovingView = ({}: PropsType) => {
  return (
    <Stack>
      <Text>Ваша заявка на вступление в организацию в процессе обработки</Text>
      <Text>Ваша заявка на регистрацию заведения в процессе обработки</Text>
    </Stack>
  );
};
export default RemovingView;
