import { EmployeeEntityType } from 'entities/entities-types';

import { T, always, cond, equals } from 'ramda';
import { useTranslation } from 'react-i18next';

export function useGetEmployeeRoleText() {
  const { t } = useTranslation();
  return (roleValue: string) =>
    cond([
      [equals('owner'), always(t('ROLE.components.roleValue.owner'))],
      [equals('manager'), always(t('ROLE.components.roleValue.manager'))],
      [equals('employee'), always(t('ROLE.components.roleValue.employee'))],
      [T, always('')],
    ])(roleValue || '');
}

export function useGetEmployeeRoleTextTranslate() {
  const { t } = useTranslation();
  return (employee: EmployeeEntityType | undefined | null) =>
    cond([
      [equals('owner'), always(t('ROLE.components.roleValue.owner'))],
      [equals('manager'), always(t('ROLE.components.roleValue.manager'))],
      [equals('employee'), always(t('ROLE.components.roleValue.employee'))],
      [T, always('')],
      // @ts-ignore
    ])(employee?.employeeRole || '');
}
