import { Button, Center, Flex, LoadingOverlay } from '@mantine/core';
import { useUnit } from 'effector-react';
import { SessionRoleType } from 'entities/entities-types';
import { currentSessionInvalidated } from 'entities/events';
import { $currentSessionInfo, $currentSessionReqStatus } from 'entities/store';
import { userGetOwnSessionInfoFx } from 'entities/user/effects';
import { includes, not } from 'ramda';
import { PropsWithChildren } from 'react';
import { Search } from 'react-feather';
import { Navigate } from 'react-router-dom';
import { MessagePanel } from 'shared/ui/display';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';

type PropsType = {
  allowedRoles: SessionRoleType[];
  rejectNavigatePath: string;
} & PropsWithChildren;

const SessionProtectedRouteWrapper = ({
  allowedRoles,
  rejectNavigatePath,
  children,
}: PropsType) => {
  const {
    0: currentSessionInfo,
    1: currentSessionInfoReqStatus,
    2: currentSessionInfoLoading,
  } = useUnit([
    $currentSessionInfo,
    $currentSessionReqStatus,
    userGetOwnSessionInfoFx.pending,
  ]);
  const employeeRoleValue = currentSessionInfo?.roleValue;
  const sessionRoleValue: SessionRoleType = Boolean(currentSessionInfo)
    ? employeeRoleValue || 'user'
    : 'unauthorized';

  const isErrorGotOnLoadingSession = 300 <= currentSessionInfoReqStatus;

  return (
    <AnimatedSwitchFade300ms watchedKey={currentSessionInfo}>
      <>
        {isErrorGotOnLoadingSession && (
          <Center h='100vh' w='100vw'>
            <Flex direction='column' align='center'>
              <MessagePanel
                size={50}
                icon={<Search size='100%' />}
                title='Ошибка при получении сессии'
                dscr='Вероятно у нас проблемы или у вас пропало интернет соединение...'
              />
              <Button
                disabled={currentSessionInfoLoading}
                onClick={() => currentSessionInvalidated()}>
                Попробовать снова
              </Button>
            </Flex>
          </Center>
        )}

        {/* {isSessionLoadingOnInit && ( */}
        {currentSessionInfoLoading && (
          <LoadingOverlay
            h='100vh'
            visible={true}
            zIndex={1000}
            overlayProps={{ radius: 'xl' }}
            loaderProps={{ type: 'bars' }}
          />
        )}

        {!currentSessionInfoLoading &&
        not(includes(sessionRoleValue, allowedRoles)) ? (
          <Navigate to={rejectNavigatePath} replace />
        ) : (
          <>{children}</>
        )}
      </>
    </AnimatedSwitchFade300ms>
  );
};
export default SessionProtectedRouteWrapper;
