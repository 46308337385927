import { ActionIcon, Avatar, Flex, Group, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { EmployeeEntityType } from 'entities/entities-types';
import { getPic } from 'entities/files';
import { ChevronRight } from 'react-feather';
import { TrashCanIcon } from 'shared/model/icons';
import { Condition } from 'shared/ui/display';

type PropsType = {
  item: EmployeeEntityType;
  inModal?: boolean;
};

export const EmployeeInfoBar = ({ item, inModal }: PropsType) => {
  // const employeeRole = item.employeeRole.roleValue === 'manager' ? 'Менеджер' : 'Сотрудник';
  // @ts-ignore
  const employeeRole = item.roleValue === 'manager' ? 'Менеджер' : 'Сотрудник';

  return (
    <Flex align='center' gap='xs' justify='space-between' w='100%'>
      <Group>
        <Avatar
          variant='transparent'
          src={getPic(item?.employeeOrg?.avatar)}
          radius={1000}
        />
        <Stack gap={0}>
          <Text fw={500} fz='md'>
            {item?.employeeOrg?.name}
          </Text>
          <Condition when={!inModal}>
            <Condition when={item?.employeeOrg?.status === 'considering'}>
              <Text c='yellow' fz='sm'>
                Заявка на рассмотрении
              </Text>
            </Condition>
            <Condition when={item?.employeeOrg?.status === 'denied'}>
              <Group
                style={{ gap: 0, cursor: 'pointer' }}
                onClick={() =>
                  modals.openContextModal({
                    modal: 'orgDeclinedModal',
                    title: 'Заявка отклонена',
                    innerProps: {
                      item: item,
                    },
                  })
                }
                align='center'>
                <Text c='red' fz='sm'>
                  Заявка отклонена
                </Text>
                <ChevronRight width={15} height={15} />
              </Group>
            </Condition>
          </Condition>
        </Stack>
      </Group>
      <Condition
        when={
          ['denied', 'canceled', 'proposed'].includes(
            item?.employeeOrg?.status as
              | 'proposed'
              | 'accepted'
              | 'denied'
              | 'canceled'
              | 'edited',
          ) && !inModal
        }>
        <ActionIcon
          onClick={() =>
            modals.openConfirmModal({
              labels: { confirm: 'Удалить', cancel: 'Отмена' },
              title: 'Удалить заявку',
            })
          }>
          <TrashCanIcon />
        </ActionIcon>
      </Condition>
    </Flex>
  );
};
