import {
  OrgEntityType,
  SessionEntityType,
  UserEntityType,
} from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api';

export const ENDPOINTS_USER = {
  GET_ONE: {
    path: 'user/get_one',
    method: 'post' as HttpMethod,
    req: { userId: 12 } as Pick<UserEntityType, 'userId'>,
    res: {} as TipsApiResType<UserEntityType>,
  },

  CHECK_PHONE_NUMBER_OCCUPATION: {
    path: 'user/check_phone_number_occupation',
    method: 'post' as HttpMethod,
    req: { phoneNumber: '89163685055' } as Pick<UserEntityType, 'phoneNumber'>,
    res: {} as TipsApiResType<{
      isOccupied: boolean;
    }>,
  },

  CHECK_EMAIL_OCCUPATION: {
    path: 'user/check_email_occupation',
    method: 'post' as HttpMethod,
    req: { email: '89163685055@list.ru' } as Pick<UserEntityType, 'email'>,
    res: {} as TipsApiResType<{
      isOccupied: boolean;
    }>,
  },

  CHECK_PASSWORD: {
    path: 'user/check_password',
    method: 'post' as HttpMethod,
    req: { password: '666666' } as Pick<UserEntityType, 'password'>,
    res: {} as TipsApiResType<{
      isOccupied: boolean;
    }>,
  },

  GET_ONE_OWN: {
    path: 'user/get_one_own',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<UserEntityType>,
  },

  GET_OWN_SESSION_INFO: {
    path: 'user/get_own_session_info',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {
      data: {
        userId: 1,
        orgId: 2,
        employeeId: 3,
        roleValue: 'manager',
        verificatedSources: [''],
      },
    } as TipsApiResType<SessionEntityType>,
  },

  CHANGE_OWN_AVATAR: {
    path: 'user/change_avatar_own',
    method: 'patch' as HttpMethod,
    req: {} as FormData,
    res: {} as TipsApiResType<UserEntityType>,
  },

  COMPARE_AND_CHANGE_OWN_PASSWORD: {
    path: 'user/compare_and_change_own_password',
    method: 'patch' as HttpMethod,
    req: {} as {
      passwordOld: string;
      passwordNew: string;
    },
    res: {} as TipsApiResType<void>,
  },

  CHANGE_OWN_PASSWORD: {
    path: 'user/change_own_password',
    // path: 'user/forgot_password',
    method: 'patch' as HttpMethod,
    req: {} as {
      newPassword: string;
    },
    res: {} as TipsApiResType<void>,
  },

  CHANGE_FORGOTTEN_PASSWORD: {
    path: 'user/change_forgotten_password',
    method: 'patch' as HttpMethod,
    req: {} as {
      source: string;
      newPassword: string;
    },
    res: {} as TipsApiResType<void>,
  },

  CHANGE_OWN_FULL_NAME: {
    path: 'user/change_own_full_name',
    method: 'patch' as HttpMethod,
    req: {} as Pick<UserEntityType, 'firstname' | 'lastname' | 'patronymic'>,
    res: {} as TipsApiResType<void>,
  },

  CHANGE_OWN_EMAIL: {
    path: 'user/change_own_email',
    method: 'patch' as HttpMethod,
    req: {} as { newEmail: string },
    res: {} as TipsApiResType<void>,
  },

  CHANGE_OWN_PHONE_NUMBER: {
    path: 'user/change_own_phone_number',
    method: 'patch' as HttpMethod,
    req: {} as { newPhoneNumber: string },
    res: {} as TipsApiResType<void>,
  },

  SIGN_IN: {
    path: 'user/sign_in',
    method: 'post' as HttpMethod,
    req: {
      phoneNumber: '89163685055',
      password: '666666',
    } as Partial<Pick<UserEntityType, 'phoneNumber' | 'email'>>,
    res: {} as TipsApiResType<void>,
  },

  SIGN_OUT: {
    path: 'user/sign_out',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<void>,
  },

  DELETE_ONE_OWN: {
    path: 'user/delete_one_own',
    method: 'delete' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<void>,
  },

  SIGN_UP: {
    path: 'user/sign_up',
    method: 'post' as HttpMethod,
    req: {
      firstname: 'Никита',
      lastname: 'Путинцев',
      patronymic: 'Евгеньевич',
      phoneNumber: '89163685055',
      password: '666666',
      email: '89163685055@list.ru',
    } as Omit<UserEntityType, 'userId' | 'balanceId'>,
    res: {} as TipsApiResType<void>,
    err: {
      '407': 'asdfasf', // возвращается при номер телефона уже занят
    },
  },

  // при создании пользователя создаётся ORG со статусом 'proposed', создаётся EMPLOYEE с ролью 'owner'
  SIGN_UP_WITH_ORG_APPLICATION: {
    path: 'user/sign_up_user_and_organization',
    method: 'post' as HttpMethod,
    req: {
      user: {
        firstname: 'Никита',
        lastname: 'Путинцев',
        patronymic: 'Евгеньевич',
        phoneNumber: '89163685055',
        password: '666666',
        email: '89163685055@list.ru',
      },
      org: {
        name: 'Макдак',
        inn: '1234567890',
      },
    } as {
      user: Omit<UserEntityType, 'userId'>;
      org: Omit<OrgEntityType, 'orgId' | 'status'>;
    },
    res: {} as TipsApiResType<void>,
  },
};
