import { Loader, Stack } from '@mantine/core';
import { useUnit } from 'effector-react/compat';
import {
  TransactionEntityType,
  VisitEntityType,
} from 'entities/entities-types';
import { $$transactionManyForHomePage } from 'entities/transaction';
import { $$visitManyForHomePage } from 'entities/visit';
import { isEmpty, isNotEmpty } from 'ramda';
import { useEffect } from 'react';
import { IconBill as BanknotesIcon } from 'shared/model';
import { DateGroupedList } from 'shared/ui/containers';
import { MessagePanel } from 'shared/ui/display';
import { VisitBar } from './visit-bar';

type PropsType = {
  userId: number | undefined | null;
  employeeId: number | undefined | null;
};

const UserVisitsHistory = ({ userId, employeeId }: PropsType) => {
  const {
    0: visits,
    1: isLoadingVisits,
    2: transactions,
    3: isLoadingTransactions,
  } = useUnit([
    // TODO NIKITA: в последующем поправить. Нам нужно, чтобы нам возвращались с бэка все статусы,
    //  кроме RECEIVED (транзакция начата, но клиент не ввёл данные карты).
    //  Для этого мы должны фильтровать GET_MANY своим реквестом, либо бэки не должны отдавать RECIEVED по-умолчанию
    $$visitManyForHomePage.$store.map((s) => s?.res?.data?.items || []),
    $$visitManyForHomePage.attachedFx.pending,

    $$transactionManyForHomePage.$store.map((s) => s?.res?.data || []),
    $$transactionManyForHomePage.attachedFx.pending,
  ]);
  const isLoading = isLoadingVisits || isLoadingTransactions;

  const visitWithTransaction = [
    ...visits.filter((visit) => visit?.visitTransaction?.status !== 'received'),
    ...(transactions as TransactionEntityType[]),
  ];

  useEffect(() => {
    if (employeeId && !Boolean(userId)) {
      $$visitManyForHomePage.argEditEvent({
        currentPage: 1,
        itemsPerPage: 100,
        visitStatuses: ['failed', 'in_progress', 'completed'],
        receiverEntities: ['employee'],
        employeeIds: [employeeId],
      });
    } else if (userId) {
      $$visitManyForHomePage.argEditEvent({
        currentPage: 1,
        itemsPerPage: 100,
        visitStatuses: ['failed', 'in_progress', 'completed'],
        userIds: [userId],
        receiverEntities: ['user', 'employee'],
      });
    }
    $$transactionManyForHomePage.argEditEvent({
      userIds: [userId ?? 0],
      itemsPerPage: 100,
      currentPage: 1,
      action: ['payout'],
    });
  }, [userId, employeeId]);

  return (
    <Stack style={{ flex: 1, borderRadius: 16 }} gap='xs'>
      {isEmpty(visits) && !isLoading && (
        <MessagePanel
          icon={<BanknotesIcon />}
          title='Чаевых за выбранный период пока нет'
          dscr='Здесь будут отображаться оставленные вам чаевые за выбранный период'
        />
      )}
      {isNotEmpty(visits) && (
        <DateGroupedList<VisitEntityType | TransactionEntityType>
          itemsArr={visitWithTransaction}
          dateAttributeName='createdAt'
          barComponent={(item: VisitEntityType | TransactionEntityType) => (
            <VisitBar
              visitsOrTransaction={item}
              key={item.transactionUUID}
              variant='jibun'
            />
          )}
        />
      )}
      {isLoading && (
        <Loader style={{ alignSelf: 'center' }} type='dots' size='xl' />
      )}
    </Stack>
  );
};

export default UserVisitsHistory;
