import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { transactionGetManyFx } from 'entities/transaction/effects';
import { ENDPOINTS_TRANSACTION } from 'entities/transaction/endpoints';

export const $$transactionManyForHomePage = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_TRANSACTION.GET_MANY.req,
  typeof ENDPOINTS_TRANSACTION.GET_MANY.res
>({
  initialReq: {
    userIds: [1],
    action: ['payout'],
    currentPage: 1,
    itemsPerPage: 10,
  },
  initialFx: transactionGetManyFx,
});
