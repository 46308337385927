import { OrgInvitationEntityType } from 'entities/entities-types';

const getNow = () => new Date().getTime();

export const getIsOrgInvExpired = (
  orgInv: OrgInvitationEntityType | undefined | null,
) => {
  const expTime = new Date(orgInv?.expiresAt || 0)?.getTime();
  return Boolean(expTime) && expTime < getNow();
};
