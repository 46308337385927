import { Button, Stack, Text } from '@mantine/core';
import { ContextModalProps, openContextModal } from '@mantine/modals';
import { useUnit } from 'effector-react';
import {
  getPayoutExecuterLinkFx,
  getPayoutOrgLinkFx,
} from 'entities/payment-system/effects';
import { ENDPOINTS_SBP } from 'entities/payment-system/endpoints';
import { or, replace } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'shared/lib/hooks';

type PropTypes = ContextModalProps<{
  host: string;
  withdrawExecuter: 'org' | 'user';
  formValues: {
    email: string | null;
    sum: number | null;
    sbpBank?: string | null;
    withdrawalType: 'CARD' | 'SBP';
    phoneNumber: string;
  };
  pam: string;
}>;

const SbpConfirmWithdrawModal = ({ context, id, innerProps }: PropTypes) => {
  const navigate = useNavigate();
  const notify = useNotifications();
  const [isLoadingLink, isLoadingLinkSbp] = useUnit([
    getPayoutExecuterLinkFx.pending,
    getPayoutOrgLinkFx.pending,
  ]);

  const { email, sbpBank, phoneNumber, sum } = innerProps.formValues;

  const commonParams:
    | typeof ENDPOINTS_SBP.PAYOUT_ORG.req
    | typeof ENDPOINTS_SBP.PAYOUT.req = {
    bankId:
      innerProps.host?.includes('chainik.fun') ||
      innerProps.host?.includes('localhost')
        ? '1crt88888881'
        : replace('bank', '', sbpBank || '') ?? '',
    paymentMethod: 'SBP',
    phoneNumber: phoneNumber,
    email: email,
    amount: or(sum, 0),
    failRedirectUrl: `${innerProps.host}/home`,
    redirectUrl: `${innerProps.host}/home`,
  };

  const handleSuccess = () => {
    openContextModal({
      title: 'Успех!',
      modal: 'sbpWithdrawalSuccessModal',
      innerProps: { withdrawalSum: sum },
      closeButtonProps: { onClick: () => navigate('/home') },
      closeOnClickOutside: true,
      onClose: () => navigate('/home'),
    });
  };

  const handleError = () => {
    notify('error', 'Ошибка', 'Ошибка при получении ссылки');
  };

  console.log(innerProps.withdrawExecuter);

  const handleSubmit = () => {
    const fx =
      innerProps.withdrawExecuter === 'org'
        ? getPayoutOrgLinkFx
        : getPayoutExecuterLinkFx;
    fx(commonParams)
      .then((res) => {
        if (res.status === 200) {
          handleSuccess();
        } else {
          throw new Error();
        }
      })
      .catch(handleError)
      .finally(() => context.closeModal(id));
  };

  return (
    <Stack>
      <Text ta='center'>
        Перевод по номеру:
        <Text fw={600}>{phoneNumber}</Text>
      </Text>
      {innerProps.pam && (
        <Text ta='center'>
          На имя:
          <Text fw={600}>{innerProps.pam}</Text>
        </Text>
      )}
      <Button
        loading={isLoadingLink || isLoadingLinkSbp}
        onClick={handleSubmit}>
        Подтверждаю
      </Button>
    </Stack>
  );
};

export default SbpConfirmWithdrawModal;
