import {
  Avatar,
  Box,
  NavLink,
  Skeleton,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$employeeAvatarGroupWithCount } from 'entities/employee';
import { getPic } from 'entities/files';
import { equals, length, pipe } from 'ramda';
import { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { IconFallbackUser } from 'shared/model/vector-images';
import { Condition } from 'shared/ui/display';

type PropsType = {};

const isArrayEmpty = pipe(length, equals(0));

export const EmployeeAvatarGroupWithCount = ({}: PropsType) => {
  const { t } = useTranslation();

  const avatarSize = 40;
  const theme = useMantineTheme();
  const { 0: items, 1: totalItems, 2: isLoading } = useUnit([
    $$employeeAvatarGroupWithCount.$store.map((s) => s?.res?.data?.items || []),
    $$employeeAvatarGroupWithCount.$store.map(
      (s) => s?.res?.data?.totalItems || 0,
    ),
    $$employeeAvatarGroupWithCount.attachedFx.pending,
  ]);

  useEffect(() => {
    $$employeeAvatarGroupWithCount.argEditEvent({
      currentPage: 1,
      itemsPerPage: 3,
      employee: { status: ['approved'] },
    });
    return () => {
      $$employeeAvatarGroupWithCount.argResetEvent();
    };
  }, []);

  return (
    <Box style={{ overflow: 'hidden', borderRadius: 16 }} bg='white'>
      <NavLink
        component={Link}
        to={getHref({ '/org': { currentView: 'employee-list' } })}
        p='md'
        fw={600}
        styles={{
          root: { alignItems: 'start', transition: 'all 0.3s' },
          label: { fontSize: 16 },
          body: { display: 'flex', flexDirection: 'column', gap: 14 },
        }}
        label={t('ORG.components.tabs.all-employees')}
        rightSection={<ChevronRight opacity={0.5} />}
        description={
          <Skeleton visible={isLoading} h={avatarSize}>
            {!isArrayEmpty(items) && (
              <Avatar.Group spacing='sm'>
                {items &&
                  items.map((item) => (
                    <Avatar
                      variant='transparent'
                      radius={1000}
                      size={40}
                      key={item.employeeId}
                      src={getPic(item?.employeeUser?.avatar)}>
                      {' '}
                      <IconFallbackUser />
                    </Avatar>
                  ))}
                <Condition when={totalItems > 3}>
                  <Avatar variant='transparent' radius={1000} size={avatarSize}>
                    {`+${totalItems - 3}`}
                  </Avatar>
                </Condition>
              </Avatar.Group>
            )}

            {isArrayEmpty(items) && (
              <Text c='dimmed' fz='md'>
                {t('ORG.components.noFeedbacks')}
              </Text>
            )}
          </Skeleton>
        }
      />
    </Box>
  );
};
