import { getPic } from 'entities';
import { SharingRuleEntityType, UserEntityType } from 'entities/entities-types';
import {
  T,
  always,
  assoc,
  cond,
  ifElse,
  is,
  modify,
  objOf,
  path,
  pickAll,
  pipe,
  propIs,
} from 'ramda';
import { ReactNode } from 'react';
import { Home as IconHome, User as IconUser } from 'react-feather';
import { IconGroup } from 'shared/model/icons';

function getUserFioAndAvatar(user: UserEntityType | undefined | null) {
  const resultArr = [];
  if (user?.lastname) resultArr.push(user.lastname);
  if (user?.firstname) resultArr.push(user.firstname[0] + '.');
  if (user?.patronymic) resultArr.push(user.patronymic[0] + '.');
  return { name: resultArr.join(' '), avatar: String(user?.avatar) };
}

export const getSharingRuleUiParams = (
  rule: Partial<SharingRuleEntityType> | undefined,
  entity: 'donor' | 'receiver',
) => {
  return cond<
    (Partial<SharingRuleEntityType> | undefined | null)[],
    {
      entity: string;
      name: string;
      avatar: string;
      avatarPlaceholder: ReactNode;
    }
  >([
    [
      pipe(path([entity, 'org', 'orgId']), is(Number)),
      pipe(
        path([entity, 'org']),
        pickAll(['avatar', 'name']),
        ifElse(
          propIs(String, 'avatar'),
          modify('avatar', getPic),
          assoc('avatar', ''),
        ),
        assoc('entity', 'Организация'),
        assoc('avatarPlaceholder', <IconHome />),
      ),
    ],
    [
      pipe(path([entity, 'employee', 'employeeId']), is(Number)),
      pipe(
        path([entity, 'employee', 'employeeUser']),
        getUserFioAndAvatar,
        modify('avatar', getPic),
        assoc('entity', 'Сотрудник'),
        assoc('avatarPlaceholder', <IconUser />),
      ),
    ],
    [
      pipe(path([entity, 'categoryItem', 'categoryItemId']), is(Number)),
      pipe(
        path([entity, 'categoryItem', 'name']),
        String,
        objOf('name'),
        assoc('entity', 'Категория'),
        assoc('avatar', ''),
        assoc('avatarPlaceholder', <IconGroup height='66%' width='66%' />),
      ),
    ],
    [
      T,
      always({ entity: 'нет', name: 'нет', avatar: '', avatarPlaceholder: '' }),
    ],
  ])(rule);
};
