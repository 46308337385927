import { Embla } from '@mantine/carousel';
import { Image, NavLink, ScrollArea, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { ConnectedFields } from 'effector-forms';
import { includes, isEmpty, sort } from 'ramda';
import { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { MyTextInput } from 'shared/ui/inputs';

type PropTypes = ContextModalProps<{
  banks: {
    bankName: string;
    logoURL: string;
    schema: string;
    package_name?: string;
  }[];
  fields: ConnectedFields<{
    email: string | null;
    sum: number | null;
    sbpBank?: string | null | undefined;
    withdrawalType: 'card' | 'sbp';
  }>;
  embla: Embla;
}>;

const BanksPickerModal = ({ context, id, innerProps }: PropTypes) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const items = innerProps.banks;
  const filteredItemsSchemas = items
    .filter(
      (bank) =>
        !bank.bankName
          .toUpperCase()
          .includes(searchQuery?.trim()?.toUpperCase() ?? ''),
    )
    .map((i) => i.schema);

  const sortedItems = sort((item) => {
    if (isEmpty(filteredItemsSchemas)) return 0;
    if (includes(item.schema, filteredItemsSchemas)) return 0;
    else return -1;
  }, items);

  return (
    <Stack gap={0}>
      <MyTextInput
        placeholder='Найти свой банк'
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery ?? ''}
      />
      <ScrollArea.Autosize
        mah='50vh'
        type='always'
        scrollbarSize='10px'
        offsetScrollbars='y'>
        {sortedItems.map((bank) => (
          <NavLink
            mih={50}
            style={{ borderBottom: '1px solid lightgray' }}
            onClick={() => {
              innerProps.fields.sbpBank?.set(bank.schema);
              innerProps.embla.scrollTo(0);
              context.closeModal(id);
            }}
            label={bank.bankName}
            key={bank.schema}
            disabled={includes(bank.schema, filteredItemsSchemas)}
            leftSection={<Image src={bank.logoURL} h={30} w={30} />}
            rightSection={<ChevronRight color='gray' />}
          />
        ))}
      </ScrollArea.Autosize>
    </Stack>
  );
};

export default BanksPickerModal;
