import { sample } from 'effector';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import {
  employeeEditManyFx,
  employeeGetManyOwnInOrgFx,
} from 'entities/employee/effects';
import { ENDPOINTS_EMPLOYEE } from 'entities/employee/endpoints';

export const $$employeeManyOwnForActiveList = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.req,
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.res
>({
  initialReq: {
    itemsPerPage: 0,
    currentPage: 1,
    employee: { status: ['considering'] },
    fioSearchString: '',
  },
  initialFx: employeeGetManyOwnInOrgFx,
});

sample({
  clock: employeeEditManyFx.done,
  source: $$employeeManyOwnForActiveList.$store,
  fn: (sourceData) => sourceData.req,
  target: $$employeeManyOwnForActiveList.argEditEvent,
});
