import { LoadingOverlay } from '@mantine/core';
import RestrictedGoods from 'pages/docs/restricted-goods';
import { T, always, cond, equals } from 'ramda';
import { ReactNode, Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PATHS } from 'shared/model/const';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';

const Main = lazy(() => import('pages/docs/main'));
const AboutInformation = lazy(() => import('pages/docs/about-information'));
const ContactInformation = lazy(() => import('pages/docs/contact-information'));
const UserAgreementOrg = lazy(() => import('pages/docs/user-agreement-org'));
const UserAgreement = lazy(() => import('pages/docs/user-agreement'));
const PaymentSecurity = lazy(() => import('pages/docs/payment-security'));
const PersonalDataProcessingPolicies = lazy(
  () => import('pages/docs/personal-data-processing-policies'),
);

type ViewType = typeof PATHS['/docs']['currentView'];

const Page = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView') as ViewType;

  const getCurrentView = cond<ViewType[], ReactNode>([
    [equals('payment-security' as ViewType), always(<PaymentSecurity />)],
    [equals('contact-information' as ViewType), always(<ContactInformation />)],
    [equals('user-agreement' as ViewType), always(<UserAgreement />)],
    [equals('user-agreement-org' as ViewType), always(<UserAgreementOrg />)],
    [equals('restricted-goods' as ViewType), always(<RestrictedGoods />)],
    [
      equals('personal-data-processing-policies' as ViewType),
      always(<PersonalDataProcessingPolicies />),
    ],
    [equals('about-information' as ViewType), always(<AboutInformation />)],
    [T, always(<Main />)],
  ]);

  return (
    <AnimatedSwitchFade300ms watchedKey={currentView || ''}>
      <Suspense
        fallback={
          <LoadingOverlay
            visible={true}
            h='100vh'
            zIndex={1000}
            overlayProps={{ radius: 'xl' }}
            loaderProps={{ type: 'bars' }}
          />
        }>
        {getCurrentView(currentView)}
      </Suspense>
    </AnimatedSwitchFade300ms>
  );
};

export default Page;
