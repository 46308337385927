import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { goalGetOneFx } from '../effects';
import { ENDPOINTS_GOAL } from '../endpoints';

export const $$goalGetOne = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_GOAL.GET_ONE.req,
  typeof ENDPOINTS_GOAL.GET_ONE.res
>({
  initialReq: { goalId: 0 },
  initialFx: goalGetOneFx,
});
