import { Box, Flex, Stack, Transition } from '@mantine/core';
import { useEffect, useState } from 'react';
import { LogoImg, LogoWord } from 'shared/model/images';

const IntroWindow = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [boxStyle, setBoxStyle] = useState({
    width: '0px',
    overflow: 'hidden',
    transition: '1s all',
  });

  useEffect(() => {
    setTimeout(() => {
      setBoxStyle((prevState) => ({
        ...prevState,
        width: '200px',
      }));
    }, 100);
  }, []);

  useEffect(() => {
    setIsMounted(true);
    setTimeout(() => {
      setIsMounted(false);
    }, 3000);
  }, []);

  return (
    <Stack
      style={{
        padding: 20,
        width: '20.5rem',
        height: '100dvh',
        overflow: 'auto',
        alignSelf: 'center',
      }}
      justify='center'
      align='center'>
      <Transition
        mounted={isMounted}
        transition='fade'
        duration={1000}
        timingFunction='ease'>
        {(styles) => (
          <Flex align='center' style={styles}>
            <LogoImg />
            <Box pos='relative' h={44} style={{ ...styles, ...boxStyle }}>
              <LogoWord
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  right: 0,
                }}
              />
            </Box>
          </Flex>
        )}
      </Transition>
    </Stack>
  );
};

export default IntroWindow;
