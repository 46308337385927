import { Indicator } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ChangeEvent, PropsWithChildren, useRef } from 'react';
import { Camera } from 'react-feather';
import { isNilOrEmpty } from 'shared/lib';
import { CrudModalActionsBar } from 'shared/ui/buttons';

type PropsType = PropsWithChildren<{
  disabled?: boolean;
  isLoading?: boolean;
  src: string | null | undefined;
  onChange: (fd: FormData) => void;
}>;

const ImageChangeWrapper = ({
  disabled,
  children,
  onChange,
  isLoading,
  src,
}: PropsType) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleStartFilePicking = () => inputRef?.current?.click?.();
  const handleDeleteAvatarClick = () => {
    const formData = new FormData();
    formData.append('avatar', (null as unknown) as string | Blob);
    return onChange(formData);
  };
  const handleChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    const fileType = file.type.toLowerCase();
    const fileSize = file.size;
    const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxAcceptedSize = 5_000_000;
    if (!acceptedTypes.includes(fileType))
      return notifications.show({
        color: 'red',
        title: 'Ошибка загрузки файла',
        message: 'Тип файла должен быть одним из: .jpeg, .png, .gif',
        autoClose: 3000,
        withBorder: true,
      });
    if (fileSize > maxAcceptedSize)
      return notifications.show({
        color: 'red',
        title: 'Ошибка загрузки файла',
        message: 'Размер файла не должен превышать 5MB',
        autoClose: 3000,
        withBorder: true,
      });
    handleSaveChanges(file);
  };

  const handleSaveChanges = (file: File) => {
    const blobFile = new Blob([file]);
    const formData = new FormData();
    formData.append('avatar', blobFile);
    return onChange(formData);
  };

  return (
    <CrudModalActionsBar
      position='bottom-start'
      disabled={isLoading}
      handleDeleteClick={
        !isNilOrEmpty(src) ? handleDeleteAvatarClick : undefined
      }
      handleAddClick={isNilOrEmpty(src) ? handleStartFilePicking : undefined}
      handleEditClick={!isNilOrEmpty(src) ? handleStartFilePicking : undefined}>
      <Indicator
        disabled={true}
        color='rgba(255, 255, 255, 0.14)'
        label={<Camera color='white' size='13px' />}
        size={24}
        offset={3}
        position='bottom-start'>
        {children}

        <input
          hidden
          type='file'
          name='avatar'
          ref={inputRef}
          onChange={handleChangeFileInput}
          disabled={disabled}
        />
      </Indicator>
    </CrudModalActionsBar>
  );
};

export default ImageChangeWrapper;
