import {
  $footer,
  footerEdited,
  footerReset,
} from 'entities/app-flows/footer-flow';
import { useEffect } from 'react';

const targetStore = $footer.getState();

type PropsType = Partial<typeof targetStore>;

export function useFooter(args: PropsType) {
  useEffect(() => {
    footerEdited({ ...args });
    return () => {
      footerReset();
    };
  }, [args]);
}
