import { useNotifications } from './use-notifications';

export function useCopyToClipboardAndNotify(notificationTitle: string) {
  const toastNote = useNotifications();
  return (copiedText: string) =>
    navigator.clipboard
      .writeText(copiedText)
      .then(() => {
        toastNote('success', notificationTitle, 'Успех');
      })
      .catch(() => {
        toastNote('error', notificationTitle, 'Ошибка при копировании');
      });
}
