import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { goalGetManyFx } from '../effects';
import { ENDPOINTS_GOAL } from '../endpoints';

export const $$goalGetManyOwn = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_GOAL.GET_MANY_OWN.req,
  typeof ENDPOINTS_GOAL.GET_MANY_OWN.res
>({
  initialReq: { currentPage: 1, itemsPerPage: 10 },
  initialFx: goalGetManyFx,
});
