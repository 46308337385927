import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';

type PropsType = PropsWithChildren<{ isActive: boolean }> &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    background: 'gray' | 'common' | 'colorful';
  };

export const ColorfulContainerWithBubbles = ({
  background,
  isActive,
  children,
  ...divProps
}: PropsType) => {
  // const bubbleBg = 'rgba(255, 255, 255, 0.025)';
  const bgColor =
    background === 'gray'
      ? '#f2f3f8'
      : 'linear-gradient(180deg, rgba(74, 103, 255, 1), rgba(52, 83, 248, 1))';

  return (
    <>
      <div
        {...divProps}
        style={{
          ...divProps.style,
          transition: 'all 0.3s',
          opacity: isActive ? 1 : 0,
          overflow: 'hidden',
          background: bgColor,
        }}>
        <span
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            width: '30%',
            aspectRatio: 1,
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)',
            // background: bubbleBg,
          }}
        />
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            left: '30%',
            width: '60%',
            aspectRatio: 1,
            borderRadius: '100%',
            transform: 'translate(-50%, 90%)',
            // background: bubbleBg,
          }}
        />
        <span
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '130%',
            aspectRatio: 1,
            borderRadius: '100%',
            transform: 'translate(-50%, -54%)',
            // background: bubbleBg,
          }}
        />
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            right: '25%',
            height: '150%',
            aspectRatio: 1,
            borderRadius: '100%',
            transform: 'translate(50%, 50%)',
            // background: bubbleBg,
          }}
        />
        <span
          style={{
            position: 'absolute',
            top: '50%',
            right: 0,
            width: '25%',
            aspectRatio: 1,
            borderRadius: '100%',
            transform: 'translate(50%, -50%)',
            // background: bubbleBg,
          }}
        />
        {children}
      </div>
      {/* </Condition>*/}
    </>
  );
};
