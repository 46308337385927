import {
  Avatar,
  Group,
  Loader,
  Space,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { getPic } from 'entities';
import { CategoryItemsGroup } from 'entities/category';
import { useGetEmployeeRoleText } from 'entities/employee';
import { CategoryItemEntityType, RoleValueType } from 'entities/entities-types';
import { $currentSessionInfo } from 'entities/store';
import {
  $userOneOwn,
  getEmployeeAndOrgFromUser,
  getUserFio,
  userChangeOwnAvatarFx,
} from 'entities/user';
import { UserGoalBar } from 'features/goal';
import { isEmpty, isNotEmpty } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { IconFallbackUser } from 'shared/model/vector-images';
import { HeaderPressableBox, ImageChangeWrapper } from 'shared/ui/buttons';

const MyUserPageTopPanel = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const getEmployeeRoleText = useGetEmployeeRoleText();
  const [currentSessionInfo, userOneOwn, avatarChangeIsLoading] = useUnit([
    $currentSessionInfo,
    $userOneOwn,
    userChangeOwnAvatarFx.pending,
  ]);

  const currentUserGoal = userOneOwn?.goal;

  const currentUserId = currentSessionInfo?.userId;
  const currentEmployeeId = currentSessionInfo?.employeeId;

  const currentEntityId = currentEmployeeId || currentUserId;

  const {
    org: currentOrg,
    employee: currentEmployee,
  } = getEmployeeAndOrgFromUser(userOneOwn, currentEmployeeId);

  const currentRoleValue: RoleValueType | '' =
    currentEmployee?.employeeRole?.roleValue || '';

  const currentCatItems: CategoryItemEntityType[] =
    currentEmployee?.employeeCategoryItems || [];

  return (
    <Stack p={16} gap={16} maw='100vw'>
      {/* Оставил на случай, если всё поёбается */}
      {/* <UserAvatar */}
      {/*   user={userOneOwn} */}
      {/*   isAvatarChangeDialogOpen={isOpen} */}
      {/*   setIsAvatarChangeDialogOpen={setIsOpen} */}
      {/*   showAvatarChangeLogic={true} */}
      {/* /> */}

      <Group gap={8} c='inherit' align='stretch'>
        <Stack justify='start'>
          <ImageChangeWrapper
            isLoading={avatarChangeIsLoading}
            onChange={userChangeOwnAvatarFx}
            src={userOneOwn?.avatar}>
            <Avatar
              size={50}
              variant='transparent'
              src={
                avatarChangeIsLoading ? undefined : getPic(userOneOwn?.avatar)
              }
              radius={1000}>
              {avatarChangeIsLoading ? (
                <Loader color='white' />
              ) : (
                <IconFallbackUser />
              )}
            </Avatar>
          </ImageChangeWrapper>
        </Stack>

        <Stack gap={0} flex={1} justify='center'>
          <Group justify='space-between'>
            <Text fw={600} fz={16} style={{ textWrap: 'nowrap' }}>
              {getUserFio(userOneOwn, true)}
            </Text>
            <Text fz='sm' opacity={0.7}>
              ID: {currentEntityId}
            </Text>
          </Group>

          {currentRoleValue && (
            <Text fw={400} fz={14} opacity={0.7} style={{ textWrap: 'nowrap' }}>
              {getEmployeeRoleText(currentRoleValue)}
            </Text>
          )}
          {isNotEmpty(currentCatItems) && (
            <>
              <Space h={16} />
              <CategoryItemsGroup
                badgeVariant='filled'
                categoryItems={currentCatItems}
              />
            </>
          )}
        </Stack>
      </Group>

      {currentUserGoal && (
        <HeaderPressableBox
          padding={0}
          variant='light'
          onClick={() =>
            isEmpty(currentUserGoal)
              ? navigate(getHref({ '/user': { currentView: 'my-goal-add' } }))
              : navigate(getHref({ '/user': { currentView: 'my-goal' } }))
          }>
          <UserGoalBar goalData={currentUserGoal} isArray={true} />
        </HeaderPressableBox>
      )}
    </Stack>
  );
};
export default MyUserPageTopPanel;
