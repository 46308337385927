import { PropsWithChildren } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

interface PropsType {
  watchedKey: any;
  direction: 'right' | 'left';
  reverseInOutDirection?: boolean;
}

export const AnimatedSwitchSlideHorizontally150px250ms = ({
  watchedKey,
  children,
  direction,
  reverseInOutDirection,
}: PropsWithChildren<PropsType>) => {
  const directionClassStrait =
    direction === 'right'
      ? 'slide-left-right-150px-250ms'
      : 'slide-right-left-150px-250ms';
  const directionClassReversed =
    direction === 'right'
      ? 'slide-right-right-150px-250ms'
      : 'slide-left-left-150px-250ms';

  return (
    <SwitchTransition>
      <CSSTransition
        classNames={
          reverseInOutDirection ? directionClassReversed : directionClassStrait
        }
        timeout={250}
        key={watchedKey}>
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};
