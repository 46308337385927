import styles from './style.module.css';

const CirclesStripes = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 490 490'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      className={styles.rotate_clockwise}
      d='M471.981 246.278C471.981 247.751 473.175 248.945 474.648 248.945C476.12 248.945 477.314 247.751 477.314 246.278C477.314 244.805 476.12 243.611 474.648 243.611C473.175 243.611 471.981 244.805 471.981 246.278ZM237.82 5.10491L237.809 4.60504L237.82 5.10491ZM429.679 109.654C429.821 109.891 430.129 109.967 430.365 109.824C430.601 109.681 430.677 109.374 430.535 109.138L429.679 109.654ZM474.148 246.278C474.148 379.207 368.332 486.951 237.82 486.951V487.951C368.901 487.951 475.148 379.742 475.148 246.278H474.148ZM237.82 486.951C107.308 486.951 1.49219 379.207 1.49219 246.278H0.492188C0.492188 379.742 106.739 487.951 237.82 487.951V486.951ZM1.49219 246.278C1.49219 113.359 107.333 8.50475 237.831 5.60479L237.809 4.60504C106.779 7.51681 0.492188 112.804 0.492188 246.278H1.49219ZM237.831 5.60479C335.393 3.43675 394.99 52.2007 429.679 109.654L430.535 109.138C395.696 51.4366 335.793 2.42761 237.809 4.60504L237.831 5.60479Z'
      fill='url(#paint0_linear_10228_79911)'
    />
    <path
      className={styles.rotate_counter_clockwise}
      d='M115.81 131.917C115.629 132.124 115.649 132.44 115.857 132.622C116.065 132.804 116.381 132.783 116.563 132.576L115.81 131.917ZM130.154 376.829L130.475 376.445L130.154 376.829ZM77.1168 229.905C78.0871 228.797 77.9755 227.112 76.8676 226.142C75.7597 225.172 74.0749 225.283 73.1046 226.391C72.1343 227.499 72.2459 229.184 73.3539 230.154C74.4618 231.124 76.1465 231.013 77.1168 229.905ZM116.563 132.576C179.988 60.1531 289.044 51.943 360.145 114.211L360.804 113.459C289.275 50.8156 179.588 59.0917 115.81 131.917L116.563 132.576ZM360.145 114.211C431.246 176.479 437.491 285.665 374.066 358.088L374.818 358.747C438.596 285.922 432.334 176.102 360.804 113.459L360.145 114.211ZM374.066 358.088C310.647 430.503 202.956 437.13 130.475 376.445L129.833 377.212C202.722 438.238 311.034 431.579 374.818 358.747L374.066 358.088ZM130.475 376.445C76.2897 331.079 67.0895 276.086 75.603 228.236L74.6185 228.06C66.0544 276.196 75.3237 331.574 129.833 377.212L130.475 376.445Z'
      fill='url(#paint1_linear_10228_79911)'
    />
    <path
      className={styles.rotate_clockwise}
      d='M317.587 317.662C317.782 317.467 317.782 317.15 317.587 316.955C317.392 316.759 317.075 316.759 316.88 316.955L317.587 317.662ZM318.553 172.093L318.899 171.732L318.553 172.093ZM343.401 260.307C342.359 261.348 342.359 263.037 343.401 264.078C344.442 265.119 346.13 265.119 347.172 264.078C348.213 263.037 348.213 261.348 347.172 260.307C346.13 259.265 344.442 259.265 343.401 260.307ZM316.88 316.955C276.604 357.23 211.906 357.809 172.371 318.275L171.664 318.982C211.601 358.919 276.933 358.316 317.587 317.662L316.88 316.955ZM172.371 318.275C132.837 278.741 133.416 214.042 173.692 173.767L172.984 173.059C132.331 213.713 131.727 279.045 171.664 318.982L172.371 318.275ZM173.692 173.767C213.96 133.498 277.791 133.795 318.208 172.454L318.899 171.732C278.098 132.705 213.645 132.398 172.984 173.059L173.692 173.767ZM318.208 172.454C348.418 201.351 351.697 234.16 344.801 262.072L345.772 262.312C352.737 234.119 349.415 200.921 318.899 171.732L318.208 172.454Z'
      fill='url(#paint2_linear_10228_79911)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_10228_79911'
        x1='411.813'
        y1='87.9138'
        x2='432.89'
        y2='361.556'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#86AFFF' />
        <stop offset='1' stopColor='#0032E3' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_10228_79911'
        x1='74.8273'
        y1='248.596'
        x2='194'
        y2='89.3314'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0032E3' />
        <stop offset='1' stopColor='#86AFFF' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_10228_79911'
        x1='346.255'
        y1='250.109'
        x2='269.526'
        y2='339.644'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0032E3' />
        <stop offset='1' stopColor='#86AFFF' />
      </linearGradient>
    </defs>
  </svg>
);

export default CirclesStripes;
