import { sample } from 'effector';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { userCheckEmailOccupationFx } from 'entities/user/effects';
import { ENDPOINTS_USER } from 'entities/user/endpoints';
import { $userSignUpForm } from 'entities/user/form';
import { defineTypeOfInput } from 'shared/lib';

export const $$userEmailOccupation = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_USER.CHECK_EMAIL_OCCUPATION.req,
  typeof ENDPOINTS_USER.CHECK_EMAIL_OCCUPATION.res
>({
  initialReq: { email: '' },
  initialFx: userCheckEmailOccupationFx,
});

sample({
  clock: $userSignUpForm.fields.phoneOrEmail.$value,
  filter: (clockData) =>
    Boolean(clockData && defineTypeOfInput(clockData, 1) === 'email'),
  fn: (clockData) => {
    return { email: clockData };
  },
  target: $$userEmailOccupation.argEditEvent,
});

// Изменение статуса isEmailOccupied на результат
sample({
  clock: $$userEmailOccupation.$store,
  filter: (clockData) => typeof clockData?.res?.data?.isOccupied === 'boolean',
  fn: (clockData) => {
    return Boolean(clockData?.res?.data?.isOccupied);
  },
  target: $userSignUpForm.fields.isEmailOccupied.set,
});
