import { UserEntityType } from 'entities/entities-types';

export function getUserFio(
  user: UserEntityType | undefined | null,
  withoutPatronymic?: boolean,
) {
  if (!user) return '';
  const resultArr = [];
  if (user?.lastname) resultArr.push(user.lastname);
  if (user?.firstname) resultArr.push(user.firstname);
  if (user?.patronymic && !withoutPatronymic) resultArr.push(user.patronymic);
  return resultArr.join(' ');
}
