import { createEvent } from 'effector';
import {
  CollapsableBoxStateType,
  HeaderMaxHeightStateType,
  HeaderStateType,
} from './types';

export const headerEdited = createEvent<Partial<HeaderStateType>>();
export const headerMaxHeightStateEdited = createEvent<
  Partial<HeaderMaxHeightStateType>
>();
export const headerCollapsableBoxStateEdited = createEvent<
  Partial<CollapsableBoxStateType>
>();

export const headerReset = createEvent();
