import { createStore } from 'effector';

import { SessionEntityType } from 'entities/entities-types';
import {
  appReset,
  currentSessionReceived,
  highlightedElementIdChanged,
  highlightedElementIdReset,
  navigateInstanceChanged,
  navigateUsed,
  translationInstanceChanged,
} from 'entities/events';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';

export const $currentSessionInfo = createStore<SessionEntityType | null>(null)
  .on(currentSessionReceived, (state, payload) => payload.data)
  .reset(appReset);

export const $currentSessionReqStatus = createStore<number>(0)
  .on(currentSessionReceived, (state, payload) => payload.status)
  .reset([appReset]);

export const $translate = createStore<TFunction | null>(null).on(
  translationInstanceChanged,
  (state, payload) => payload,
);

//
/* REACT ROUTER DOM */
export const $navigation = createStore<NavigateFunction | null>(null)
  .on(navigateInstanceChanged, (state, payload) => payload)
  .on(navigateUsed, (state, payload) => {
    if (state) state(payload);
  });
/* REACT ROUTER DOM */
//

export const $highlightedElementId = createStore<string>('')
  .on(highlightedElementIdChanged, (state, payload) => payload)
  .reset([appReset, highlightedElementIdReset]);

export const $maxAmountTransfer = createStore(3000);
