import { createEvent } from 'effector';
import { VisitCreateOneEntityType } from 'entities/entities-types';
import { GuestVisitFormType } from './types';

export const visitCreateOneFormEdited = createEvent<VisitCreateOneEntityType>();
export const guestVisitFormEdited = createEvent<Partial<GuestVisitFormType>>();
export const guestVisitFormReset = createEvent<void>();
export const visitStatMonthInUserChanged = createEvent<number>();
export const visitStatMonthInOrgChanged = createEvent<number>();
export const transactionStatMonthInUserChanged = createEvent<number>();
export const transactionStatMonthInOrgChanged = createEvent<number>();
