import { Group, MantineSpacing, Stack, Text, Title } from '@mantine/core';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ReactNode } from 'react';
import { roundNumber } from 'shared/lib/formatters';
import { groupArrByDate } from 'shared/lib/grouppers';
import { Condition } from 'shared/ui/display';

interface PropsType<T> {
  barComponent: (item: T) => ReactNode;
  itemsArr: T[] | undefined;
  dateAttributeName: string;
  groupGap?: MantineSpacing;
}

const DateGroupedList = <T,>({
  barComponent,
  itemsArr,
  dateAttributeName,
  groupGap,
}: PropsType<T>) => {
  const currentYear = new Date().getFullYear();
  const groupedItems = groupArrByDate({
    array: itemsArr,
    dateAttributeName,
  });
  const groupedItemsKeys = Object.keys(groupedItems);

  return (
    <Stack gap='xs'>
      <Condition when={Boolean(itemsArr)}>
        {groupedItemsKeys.map((key) => {
          const date = dayjs(key, 'YYYY-MM-DD');
          const isCurrentYear = currentYear === date.year();
          const dateStr = date
            .locale('ru')
            .format(isCurrentYear ? 'D MMMM' : 'D MMMM YYYY');

          // TODO NIKITA: перепридумать эту хрень...
          const transactionAmountSum = groupedItems?.[key]?.reduce(
            (acc, item) => {
              const netAmount = item?.visitTransaction?.netAmount ?? 0;
              if (netAmount) return acc + netAmount;
              return acc;
            },
            0,
          );

          return (
            <Stack
              key={dateStr}
              gap={groupGap ?? 0}
              bg='white'
              style={{ borderRadius: 16, overflow: 'hidden' }}>
              <Text
                pt='md'
                px='md'
                fz='md'
                c='dimmed'
                component={Group}
                wrap='wrap'
                justify='space-between'>
                <Title c='black' order={4}>
                  {dateStr}
                </Title>
                <Condition when={Boolean(transactionAmountSum)}>
                  <Text>{`${roundNumber(transactionAmountSum, 2)} ₽`}</Text>
                </Condition>
              </Text>
              {groupedItems[key]
                ?.sort((a, b) =>
                  dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1,
                )
                ?.map((item, index) => barComponent(item))}
            </Stack>
          );
        })}
      </Condition>
    </Stack>
  );
};

export default DateGroupedList;
