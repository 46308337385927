import { Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useForm } from 'effector-forms';
import { $categoryItemPutForm } from 'entities/category/forms';
import { CategoryItemEntityType } from 'entities/entities-types';
import { CategoryItemPutForm } from 'features/category';
import { useEffect } from 'react';

type PropsType = ContextModalProps<{
  action: 'create' | 'edit';
  categoryTitleId: number;
  categoryItem?: CategoryItemEntityType;
}>;

export const CategoryItemPutModal = ({
  context,
  id,
  innerProps,
}: PropsType) => {
  const title =
    innerProps.action === 'edit'
      ? 'После отправки формы пункт раздела изменит свои параметры.'
      : 'Это критерии, по которым Вы можете фильтровать, сортировать и группировать ваших сотрудников. Вы можете создать любые категории с любыми значениями';

  const { fields, setForm, reset } = useForm($categoryItemPutForm);
  const onSuccess = () => {
    context.closeModal(id);
    reset();
  };
  useEffect(() => {
    if (innerProps.action === 'edit') setForm({ ...innerProps.categoryItem });
    fields.categoryTitleId.set(innerProps.categoryTitleId);
  }, []);

  return (
    <Stack>
      <Text style={{ flex: 1 }} fz='sm'>
        {title}
      </Text>
      <CategoryItemPutForm action={innerProps.action} onSuccess={onSuccess} />
    </Stack>
  );
};
