import { createApiEffect } from '../effects';
import { ENDPOINTS_CATEGORY_ITEM, ENDPOINTS_CATEGORY_TITLE } from './endpoints';

export const categoryTitleGetAllOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_TITLE.GET_ALL_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_TITLE.GET_ALL_OWN_IN_ORG.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_TITLE.GET_ALL_OWN_IN_ORG,
});

export const categoryTitleCreateOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_TITLE.CREATE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_TITLE.CREATE_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryTitleCreateOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_TITLE.CREATE_ONE_OWN_IN_ORG,
});

export const categoryTitleDeleteOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_TITLE.DELETE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_TITLE.DELETE_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryTitleDeleteOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_TITLE.DELETE_ONE_OWN_IN_ORG,
});

export const categoryTitleEditOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_TITLE.EDIT_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_TITLE.EDIT_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryTitleEditOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_TITLE.EDIT_ONE_OWN_IN_ORG,
});

export const categoryItemCreateOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_ITEM.CREATE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_ITEM.CREATE_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryItemCreateOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_ITEM.CREATE_ONE_OWN_IN_ORG,
});

export const categoryItemDeleteOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_ITEM.DELETE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_ITEM.DELETE_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryItemDeleteOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_ITEM.DELETE_ONE_OWN_IN_ORG,
});
export const categoryItemEditOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_CATEGORY_ITEM.EDIT_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_CATEGORY_ITEM.EDIT_ONE_OWN_IN_ORG.res
>({
  fxName: 'categoryItemEditOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_CATEGORY_ITEM.EDIT_ONE_OWN_IN_ORG,
});
