import { Box, Flex, Image, Stack, Text, Title } from '@mantine/core';
import { useIsMobile } from 'shared/lib/hooks';
import { TelDarkTheme } from 'shared/model';
import {
  CardsTeapot,
  Plans1,
  Plans2,
  Plans3,
  Plans4,
  Plans5,
} from 'shared/model/images';
import styles from './style.module.css';

export const Plans = () => {
  const isMobile = useIsMobile(700);

  // TODO отрефакторить все это

  return (
    <Flex direction='column' id='opportunities' gap={{ base: 24, sm: 64 }}>
      <Title ta='center' fz={{ base: 28, md: 56 }}>
        Наши возможности в разработке
      </Title>
      <Flex direction='column' gap={{ md: 24, base: 16 }}>
        <Box className={styles.grid_wrapper}>
          <Box style={{ gridArea: 'p1' }}>
            {' '}
            <Flex
              h='100%'
              bg='background'
              direction={{ sm: 'row', base: 'column' }}
              pl={{ md: 28, base: 16 }}
              pt={{ md: 28, base: 16 }}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack pb={{ md: 28, base: 16 }}>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Интеграция с системами автоматизации
                </Text>
                <Text c='gray.7'>
                  Поддержка интеграции с системами автоматизации (r_keeper, iiko
                  и др.) для оплаты счетов целиком
                </Text>
              </Stack>

              <Flex justify='flex-end' pos='relative' align='flex-end'>
                <Box
                  top={-82}
                  right={-44}
                  pos={{ sm: 'unset', base: 'absolute' }}>
                  <Plans1 />
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box style={{ gridArea: 'p2' }}>
            <Flex
              h='100%'
              bg='background'
              pl={{ md: 28, base: 16 }}
              pt={{ md: 28, base: 16 }}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack gap={16} pb={{ md: 28, base: 16 }}>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Чаевые без организации
                </Text>
                <Text c='gray.7'>
                  Возможность самостоятельного получения чаевых без привязки к
                  организации
                </Text>
              </Stack>

              <Flex justify='flex-end' pos='relative' align='flex-end'>
                <Box
                  top={80}
                  right={-44}
                  pos={{ sm: 'unset', base: 'absolute' }}>
                  <Plans2 />
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box style={{ gridArea: 'p3' }}>
            <Flex
              h='100%'
              gap={16}
              bg='background'
              direction={{ sm: 'column', base: 'row' }}
              pl={{ md: 28, base: 16 }}
              pt={{ md: 28, base: 16 }}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Тёмная тема
                </Text>
                <Text c='gray.7'>
                  Возможность настроить приложение под свои предпочтения
                </Text>
              </Stack>

              <Flex w='100%' pr={{ md: 86, base: 0 }} justify='flex-end'>
                <Image
                  style={{ objectFit: 'inherit' }}
                  w={{ md: 200, sm: 100, base: '100%' }}
                  src={TelDarkTheme}
                />
              </Flex>
            </Flex>
          </Box>
          <Box style={{ gridArea: 'p4' }}>
            {' '}
            <Stack
              h='100%'
              bg='background'
              p={{ md: 28, base: 16 }}
              gap={10}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Бронирование
                </Text>
                <Text c='gray.7'>
                  Функционал бронирования столов для гостей
                </Text>
              </Stack>

              <Flex pos='relative' justify={{ sm: 'center', base: 'flex-end' }}>
                <Plans3 />
              </Flex>
            </Stack>
          </Box>
          <Box style={{ gridArea: 'p5' }}>
            {' '}
            <Flex
              h='100%'
              bg='background'
              direction={{ sm: 'column', base: 'row' }}
              px={{ md: 28, base: 16 }}
              pt={{ md: 28, base: 16 }}
              pb={{ md: 28, base: 0 }}
              gap={10}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Топ сотрудников
                </Text>
                <Text c='gray.7'>
                  Возможность настроить приложение под свои предпочтения
                </Text>
              </Stack>

              <Flex
                align='flex-end'
                justify={{ sm: 'center', base: 'flex-end' }}>
                <Plans4 />
              </Flex>
            </Flex>
          </Box>
          <Box style={{ gridArea: 'p6' }}>
            {' '}
            <Stack
              h='100%'
              bg='background'
              pl={{ md: 28, base: 16 }}
              gap={10}
              pt={{ md: 28, base: 16 }}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack pr={{ md: 28, base: 16 }}>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Телеграм БОТ
                </Text>
                <Text c='gray.7'>
                  Телеграм-бот для отслеживания чаевых и технической поддержки
                </Text>
              </Stack>

              <Flex
                h={{ sm: '100%', base: 100 }}
                justify='flex-end'
                align='flex-end'>
                <Plans5 height='100%' />
              </Flex>
            </Stack>
          </Box>
          <Box style={{ gridArea: 'p7' }}>
            {' '}
            <Flex
              h='100%'
              bg='background'
              pl={{ md: 28, base: 16 }}
              pt={{ md: 28, base: 16 }}
              direction={{ md: 'column', base: 'row' }}
              gap={10}
              style={{
                borderRadius: `${isMobile ? '24px' : '44px'}`,
                overflow: 'hidden',
              }}
              justify='space-between'>
              <Stack pr={{ md: 28, base: 16 }}>
                <Text fw={600} fz={{ sm: 24, base: 20 }}>
                  Виртуальная карта
                </Text>
                <Text c='gray.7'>
                  Сотрудники смогут получать чаевые напрямую на банковскую карту
                  и сразу же оплачивать с нее покупки
                </Text>
              </Stack>

              <Flex
                w='100%'
                h={{ md: 90, base: '100%' }}
                pos='relative'
                align='flex-end'>
                <Image
                  w={160}
                  right={-57}
                  bottom={-70}
                  pos='absolute'
                  src={CardsTeapot}
                />
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};
