import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { OrgEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';
import { orgCreateOneOwnProposedFx } from 'entities/org/effects';
import { formRules } from 'shared/lib/forms';

export const $orgProposeForm = createForm<
  Pick<OrgEntityType, 'avatar' | 'name' | 'inn' | 'email' | 'phoneNumber'>
>({
  validateOn: ['blur', 'submit'],
  fields: {
    inn: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.innIsValid(),
      ],
    },
    name: {
      init: '',
      rules: [formRules.required(), formRules.isEmpty()],
    },
    phoneNumber: {
      init: null,
      rules: [formRules.phoneIsValid()],
    },
    avatar: {
      init: null,
      rules: [formRules.imageFormat(), formRules.imageSize()],
    },
    email: {
      init: null,
      rules: [formRules.emailIsValid()],
    },
  },
});

sample({
  clock: [appReset, orgCreateOneOwnProposedFx.done],
  target: [$orgProposeForm.reset],
});
