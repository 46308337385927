import { Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useForm } from 'effector-forms';
import { $categoryTitlePutForm } from 'entities/category/forms';
import { CategoryTitleEntityType } from 'entities/entities-types';
import { CategoryTitlePutForm } from 'features/category';
import { useEffect } from 'react';

type PropsType = ContextModalProps<{
  action: 'create' | 'edit';
  categoryTitle?: CategoryTitleEntityType;
}>;

export const CategoryTitlePutModal = ({
  context,
  id,
  innerProps,
}: PropsType) => {
  const onSuccess = () => {
    context.closeModal(id);
    reset();
  };
  const title =
    innerProps.action === 'edit'
      ? 'После отправки формы раздель изменит свои параметры.'
      : 'Это критерии, по которым Вы можете фильтровать, сортировать и группировать ваших сотрудников. Вы можете создать любые категории с любыми значениями';

  const { setForm, reset } = useForm($categoryTitlePutForm);

  useEffect(() => {
    if (innerProps.action === 'edit') setForm({ ...innerProps.categoryTitle });
  }, []);

  return (
    <Stack>
      <Text style={{ flex: 1 }} fz='sm'>
        {title}
      </Text>
      <CategoryTitlePutForm action={innerProps.action} onSuccess={onSuccess} />
    </Stack>
  );
};
