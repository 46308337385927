import { RatingEntityType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api';

export const ENDPOINTS_RATING = {
  GET_ONE: {
    path: `rating/get_one`,
    method: 'post' as HttpMethod,
    req: { userId: 1 } as {
      orgId?: number;
      userId?: number;
      employeeId?: number;
    },
    res: {} as TipsApiResType<RatingEntityType>,
    err: {
      400: 'лдорфаорывлдоардлыфорв', // ыдварывдлпждыфрвпждфывжпждфлырвпжджвдып
      403: 'Нет доступа',
      407: 'Имя занято',
    },
  },

  GET_MANY: {
    path: `rating/get_many`,
    method: 'post' as HttpMethod,
    req: { userId: 1 } as {
      orgIds?: number[];
      userIds?: number[];
      employeeIds?: number[];
      receiverEntities?: ('user' | 'org' | 'employee')[];
    },
    res: {} as TipsApiResType<RatingEntityType[]>,
    err: {
      400: 'лдорфаорывлдоардлыфорв', // ыдварывдлпждыфрвпждфывжпждфлырвпжджвдып
      403: 'Нет доступа',
      407: 'Имя занято',
    },
  },
};
