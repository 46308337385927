import { sample } from 'effector';
import { createForm } from 'effector-forms';
import {
  categoryItemCreateOneOwnInOrgFx,
  categoryItemEditOneOwnInOrgFx,
  categoryTitleCreateOneOwnInOrgFx,
  categoryTitleEditOneOwnInOrgFx,
} from 'entities/category/effects';
import {
  ENDPOINTS_CATEGORY_ITEM,
  ENDPOINTS_CATEGORY_TITLE,
} from 'entities/category/endpoints';
import { appReset } from 'entities/events';
import { formRules } from 'shared/lib/forms';

export const $categoryTitlePutForm = createForm<
  typeof ENDPOINTS_CATEGORY_TITLE.EDIT_ONE_OWN_IN_ORG.req
>({
  fields: {
    name: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(30),
      ],
    },
    categoryTitleId: {
      init: 0,
    },
    avatar: {
      init: null,
      rules: [],
    },
    dscr: {
      init: '',
      rules: [],
    },
  },
  validateOn: ['blur', 'submit'],
});

export const $categoryItemPutForm = createForm<
  typeof ENDPOINTS_CATEGORY_ITEM.CREATE_ONE_OWN_IN_ORG.req & {
    categoryItemId?: number;
  }
>({
  fields: {
    categoryItemId: {
      init: 0,
    },
    categoryTitleId: {
      init: 0,
    },
    name: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(30),
      ],
    },
    avatar: {
      init: null,
      rules: [],
    },
    dscr: {
      init: '',
      rules: [],
    },
  },
  validateOn: ['blur', 'submit'],
});

sample({
  clock: [
    appReset,
    categoryTitleCreateOneOwnInOrgFx.done,
    categoryTitleEditOneOwnInOrgFx.done,
  ],
  target: [$categoryTitlePutForm.reset],
});

sample({
  clock: [
    appReset,
    categoryItemCreateOneOwnInOrgFx.done,
    categoryItemEditOneOwnInOrgFx.done,
  ],
  target: [$categoryItemPutForm.reset],
});
