import { Flex, Overlay, ScrollArea, Stack } from '@mantine/core';
import { useUnit } from 'effector-react';

import { useClickOutside } from '@mantine/hooks';
import {
  $header,
  $headerCollapsableBoxParams,
  headerCollapsableBoxStateEdited,
} from 'entities/app-flows/header';
import {
  currentSessionInvalidated,
  highlightedElementIdReset,
  translationInstanceChanged,
  userOneOwnInvalidated,
} from 'entities/events';
import { $currentSessionInfo, $highlightedElementId } from 'entities/store';
import { FooterBar } from 'features/footer';
import { HeaderCollapsableBox } from 'features/header';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';
import { HeaderPanel } from 'widgets/header-flow';
import IntroWindow from 'widgets/windows/intro-window';

const AppMainLayout = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const highlightOverlay = useClickOutside(highlightedElementIdReset);

  const [opacity, currentSessionInfo, header, highlightedElementId] = useUnit([
    $headerCollapsableBoxParams.map((s) => s.opacity),
    $currentSessionInfo,
    $header,
    $highlightedElementId,
  ]);

  const [isDOMContentLoaded, setIsDOMContentLoaded] = useState(false);
  const isIntroPage = pathname.endsWith('/intro');
  const animationCondition = isIntroPage || isDOMContentLoaded;
  const resultLayoutPadding = header.padding;
  const resultMaxContentWidth = header.contentMaxWidth;
  const contentBgColor = header.contentBg;
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView');
  const scrollView = useRef<HTMLDivElement>(null);

  const scrollToTop = () => scrollView.current!.scrollTo({ top: 0 });

  useEffect(() => {
    // @ts-ignore
    translationInstanceChanged(t);
    currentSessionInvalidated();
    userOneOwnInvalidated();
    if (typeof window !== 'undefined') {
      setTimeout(() => setIsDOMContentLoaded(true), 650);
      return;
    }
    return;
  }, []);

  useEffect(() => {
    if (scrollView.current?.scrollTop) scrollToTop();
  }, [currentView, pathname]);

  return (
    <AnimatedSwitchFade300ms watchedKey={animationCondition}>
      {/* <AnimatedSwitchFade300ms watchedKey={outletAnimationCondition}> */}
      <Stack
        bg={contentBgColor}
        gap={0}
        miw={320}
        h='100dvh'
        pos='relative'
        style={{ overflow: 'hidden', position: 'relative' }}>
        {!isDOMContentLoaded && <IntroWindow />}

        {isDOMContentLoaded && (
          <>
            <HeaderPanel />

            <ScrollArea
              viewportRef={scrollView}
              style={{ flex: 1, overflowX: 'hidden' }}
              styles={{ scrollbar: { zIndex: 1000 } }}
              onScrollPositionChange={(e) => {
                headerCollapsableBoxStateEdited({ scrollY: e.y });
              }}>
              <HeaderCollapsableBox containerWidth={resultMaxContentWidth} />

              <Flex
                pos='relative'
                justify='center'
                h='100%'
                bg={contentBgColor}
                style={{
                  flex: 1,
                  borderRadius: `${16}px ${16}px 0 0`,
                  zIndex: 2,
                }}>
                <Flex
                  direction='column'
                  flex={1}
                  p={resultLayoutPadding}
                  maw={resultMaxContentWidth}>
                  <Outlet />
                  {highlightedElementId && (
                    <Overlay
                      ref={highlightOverlay}
                      zIndex={1000}
                      pos='fixed'
                      blur={5}
                      bg='black'
                      opacity={0.5}
                      onClick={() => highlightedElementIdReset()}
                    />
                  )}
                </Flex>
              </Flex>
            </ScrollArea>
            <FooterBar />
          </>
        )}
      </Stack>
    </AnimatedSwitchFade300ms>
  );
};
export default AppMainLayout;
