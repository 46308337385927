import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_ORG_INVITATION } from 'entities/org-invitation/endpoints';

export const orgInvitationGetOneFx = createApiEffect<
  typeof ENDPOINTS_ORG_INVITATION.GET_ONE.req,
  typeof ENDPOINTS_ORG_INVITATION.GET_ONE.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG_INVITATION.GET_ONE,
});

export const orgInvGetAllInOrgFx = createApiEffect<
  typeof ENDPOINTS_ORG_INVITATION.GET_ALL_OWN_IN_ORG.req,
  typeof ENDPOINTS_ORG_INVITATION.GET_ALL_OWN_IN_ORG.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG_INVITATION.GET_ALL_OWN_IN_ORG,
});

export const orgInvitationUseOneFx = createApiEffect<
  typeof ENDPOINTS_ORG_INVITATION.USE_ONE.req,
  typeof ENDPOINTS_ORG_INVITATION.USE_ONE.res
>({
  fxName: 'orgInvitationUseOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG_INVITATION.USE_ONE,
});

export const orgInvCreateOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_ORG_INVITATION.CREATE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_ORG_INVITATION.CREATE_ONE_OWN_IN_ORG.res
>({
  fxName: 'orgInvitationCreateOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG_INVITATION.CREATE_ONE_OWN_IN_ORG,
});

export const orgInvDeleteOneOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_ORG_INVITATION.DELETE_ONE_OWN_IN_ORG.req,
  typeof ENDPOINTS_ORG_INVITATION.DELETE_ONE_OWN_IN_ORG.res
>({
  fxName: 'orgInvitationDeleteOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_ORG_INVITATION.DELETE_ONE_OWN_IN_ORG,
});
