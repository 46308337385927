import { createForm } from 'effector-forms';
import { SharingRuleEntityType } from 'entities/entities-types';

type FormType = {
  put: {
    donor: SharingRuleEntityType['donor'];
    receivers: (SharingRuleEntityType['receiver'] &
      Pick<SharingRuleEntityType, 'shareRatio'>)[];
  };
  delete: {
    sharingRuleIds: number[];
  };
};

export const $sharingRulePutDeleteForm = createForm<FormType>({
  validateOn: ['blur', 'submit'],
  fields: {
    put: {
      init: {
        donor: {
          categoryItem: null,
          employee: null,
          org: null,
        },
        receivers: [],
      },
    },
    delete: {
      init: { sharingRuleIds: [] },
    },
  },
});

// sample({
//   clock: [appReset],
//   target: [$userSignUpForm.reset, $userSignUpForm.reset],
// });
