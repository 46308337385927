import { FeedbackGradeValueType } from 'entities/entities-types';
import { always, cond, equals, T } from 'ramda';

export const getFeedbackGradeText = (
  grade: FeedbackGradeValueType | undefined,
) => {
  return cond([
    [equals(1), always('Плохо')],
    [equals(3), always('Хорошо')],
    [equals(5), always('Отлично')],
    [T, always('Без оценки')],
  ])(grade as number);
};
