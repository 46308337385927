import {
  Avatar,
  Box,
  NavLink,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { getPic } from 'entities';
import { employeeLogoutOneOwnFx } from 'entities/employee';
import { $currentSessionInfo, $highlightedElementId } from 'entities/store';
import { $userOneOwn, getUserFio } from 'entities/user';
import { MyEmployeeSwitcher } from 'features/employee';
import { Check } from 'react-feather';
import { IconFallbackUser } from 'shared/model/vector-images';

type PropTypes = {};

const MyEmploymentsPanel = ({}: PropTypes) => {
  const [currentSessionInfo, userOneOwn, highlightedElementId] = useUnit([
    $currentSessionInfo,
    $userOneOwn,
    $highlightedElementId,
  ]);

  const isEmployeeSelected = Boolean(currentSessionInfo?.employeeId);
  const handleEmployeeLogout = () => employeeLogoutOneOwnFx(undefined);

  return (
    <Stack
      gap='md'
      style={{
        zIndex: highlightedElementId === 'EMPLOYEE_SWITCHER' ? 2000 : undefined,
      }}>
      <Title>Личный аккаунт</Title>
      <Box
        bg='white'
        pos='relative'
        style={{ overflow: 'hidden', borderRadius: 16 }}>
        <NavLink
          onClick={handleEmployeeLogout}
          rightSection={
            !isEmployeeSelected && (
              <ThemeIcon variant='transparent'>
                <Check />
              </ThemeIcon>
            )
          }
          opacity={1}
          h={60}
          label={<Text fz={14}>{getUserFio(userOneOwn, true)}</Text>}
          description={
            <Text fz={14} c='dimmed'>
              Мой аккаунт
            </Text>
          }
          leftSection={
            <Avatar
              variant='transparent'
              radius={1000}
              src={getPic(userOneOwn?.avatar as string)}>
              <IconFallbackUser />
            </Avatar>
          }
        />
      </Box>

      <Title>Мои организации</Title>
      <MyEmployeeSwitcher />
    </Stack>
  );
};

export default MyEmploymentsPanel;
