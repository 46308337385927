import { createForm } from 'effector-forms';
import { ENDPOINTS_ORG_INVITATION } from 'entities/org-invitation/endpoints';

export const $orgInvitationCreateForm = createForm<
  typeof ENDPOINTS_ORG_INVITATION.CREATE_ONE_OWN_IN_ORG.req & {
    str: string | null;
    num: number | null;
  }
>({
  fields: {
    employeeRoleID: {
      init: 2,
    },
    str: {
      init: '',
    },
    num: {
      init: null,
    },
    maxUsesCount: {
      init: null,
      rules: [
        {
          name: 'maxLength',
          validator: (value) => {
            if (value) return value <= 100;
            return true;
          },
          errorText: 'Максимум 100',
        },
      ],
    },
    expiresAt: {
      init: null,
      rules: [
        {
          name: 'minDate',
          validator: (value) => {
            if (!value) return true;
            else return value.getTime() >= new Date().getTime();
          },
          errorText: 'Время окончания работы ссылки должно быть в будущем',
        },
      ],
    },
  },
  validateOn: ['change', 'submit'],
});
