import {
  Button,
  Divider,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { EmployeeEntityType } from 'entities/entities-types';
import { EmployeeInfoBar } from 'features/employee';
import { Link } from 'react-router-dom';
import { getHref } from 'shared/lib';

const OrgDeclinedModal = ({
  innerProps,
  context,
  id,
}: ContextModalProps<{
  item: EmployeeEntityType;
}>) => {
  const theme = useMantineTheme();
  return (
    <Stack>
      <Stack
        style={{
          border: `1px solid ${theme.colors.gray[4]}`,
          borderRadius: 12,
          gap: 0,
          padding: 5,
          paddingTop: 20,
        }}
        align='center'>
        <EmployeeInfoBar item={innerProps.item} inModal={true} />
        <Divider w='100%' mt={20} />
        <Text c='red'>Отказано</Text>
      </Stack>
      <Stack bg={theme.colors.gray[2]} style={{ borderRadius: 12 }} p={10}>
        <Title order={4}>Заявка рассмотренна:</Title>
        <Text>31 января 2024, 23:23</Text>
      </Stack>
      <Stack bg={theme.colors.gray[2]} style={{ borderRadius: 12 }} p={10}>
        <Title order={4}>Причина:</Title>
        <Text>1. ИНН не действителен</Text>
        <Text>2. Вы не явяетесь владельцем</Text>
      </Stack>
      <Button
        component={Link}
        to={getHref({ '/user': { currentView: 'org-application' } })}
        onClick={() => {
          context.closeContextModal(id);
        }}>
        Подать заново
      </Button>
    </Stack>
  );
};

export default OrgDeclinedModal;
