import { sample } from 'effector';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import {
  employeeEditManyFx,
  employeeGetOneFx,
} from 'entities/employee/effects';
import { ENDPOINTS_EMPLOYEE } from 'entities/employee/endpoints';

export const $$employeeOneForOrgEmployeePage = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_EMPLOYEE.GET_ONE.req,
  typeof ENDPOINTS_EMPLOYEE.GET_ONE.res
>({
  initialReq: { employeeId: 1 },
  initialFx: employeeGetOneFx,
});

sample({
  clock: employeeEditManyFx.done,
  source: $$employeeOneForOrgEmployeePage.$store,
  fn: (sourceData) => sourceData.req,
  target: $$employeeOneForOrgEmployeePage.argEditEvent,
});
