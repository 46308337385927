import { ActionIcon } from '@mantine/core';
import { useUnit } from 'effector-react';
import { userSignOutFx } from 'entities/user/effects';
import { useNavigate } from 'react-router-dom';
import { IconExit } from 'shared/model/vector-images';

const UserSignOutIcon = () => {
  const navigate = useNavigate();
  const { 0: isLoading } = useUnit([userSignOutFx.pending]);
  const handleClick = () => userSignOutFx().then(() => navigate('/auth'));
  return (
    <ActionIcon
      c='inherit'
      color='inherit'
      variant='subtle'
      size='md'
      loading={isLoading}
      style={{ transition: 'all 0.3s' }}
      onClick={handleClick}
      aria-label='Sign out'>
      <IconExit />
    </ActionIcon>
  );
};
export default UserSignOutIcon;
