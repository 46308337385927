import {
  Box,
  Button,
  Flex,
  FloatingIndicator,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';

import { LogoTipsImg } from 'shared/model';

import styles from './style.module.css';

import { useHeader } from 'features';
import { useState } from 'react';
import { useIsMobile } from 'shared/lib/hooks';
import { LogoImg, LogoWord } from 'shared/model/images';
import {
  Advantages,
  EasyTips,
  GettingTips,
  Main,
  Plans,
  Questions,
} from 'widgets/landing';

const Page = () => {
  const theme = useMantineTheme();
  const host = window.location.host;
  const anchors = document.querySelectorAll('a[href*="#"]');
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
  const [controlsRefs, setControlsRefs] = useState<
    Record<string, HTMLButtonElement | null>
  >({});
  const isMobile = useIsMobile(600);
  const [active, setActive] = useState(0);
  const setControlRef = (index: number) => (node: HTMLButtonElement) => {
    controlsRefs[index] = node;
    setControlsRefs(controlsRefs);
  };
  anchors.forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      const blockID = anchor?.getAttribute('href');

      blockID &&
        document.getElementById(blockID.substr(1))?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    });
  });

  const dataNavigate = [
    { text: 'Оплата чаевых', href: '#easy-tips' },
    { text: 'Как это работает', href: '#work' },
    { text: 'Для кого', href: '#getting-tips' },
    { text: 'Возможности', href: '#opportunities' },
    // { text: 'Вопросы', href: '#questions' },
    { text: 'Контакты', href: '#contacts' },
  ];
  const linksDocs = [
    {
      href: '/docs?currentView=user-agreement-org',
      label: 'Оферта организации',
    },
    { href: '/docs?currentView=user-agreement', label: 'Оферта сотрудника' },
    {
      href: '/docs?currentView=personal-data-processing-policies',
      label: 'Политика конфиденциальности',
    },
    {
      href: '/docs?currentView=payment-security',
      label: 'Безопасность платежей',
    },
    {
      href: '/docs?currentView=restricted-goods',
      label: 'Перечень запрещенных товаров и услуг',
    },
  ];
  const controls = dataNavigate.map((item, index) => (
    <UnstyledButton
      key={item.href}
      className={styles.control}
      ref={setControlRef(index)}
      onClick={() => setActive(index)}
      mod={{ active: active === index }}>
      <Box
        mod={{ active: active === index }}
        component='a'
        href={item.href}
        className={styles.controlLabel}>
        {item.text}
      </Box>
    </UnstyledButton>
  ));

  useHeader({ contentBg: `${isMobile ? 'background' : ''}` });

  return (
    <Stack
      style={{
        zIndex: '0',
        height: '100%',
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
      }}
      h='100%'>
      <Stack visibleFrom='md'>
        <Flex justify='center'>
          <Stack
            pt={26}
            pl={{ base: 16, sm: 20, lg: 100 }}
            pr={{ base: 16, sm: 20, lg: 100 }}
            w={1400}>
            <Flex align='center' justify='space-between'>
              <LogoTipsImg />

              <div className={styles.root} ref={setRootRef}>
                {controls}

                <FloatingIndicator
                  target={controlsRefs[active]}
                  parent={rootRef}
                  className={styles.indicator}
                />
              </div>

              <Button
                radius={80}
                fz={14}
                bg='background'
                variant='light'
                href='/auth'
                component='a'>
                Войти
              </Button>
            </Flex>
          </Stack>
        </Flex>
      </Stack>

      <Stack>
        <Flex style={{ overflow: 'hidden' }} justify='center' mb={30}>
          <Flex
            justify='center'
            maw={1400}
            gap={{ sm: 104, base: 44 }}
            direction='column'
            mt={{ base: 30, md: 80 }}
            pl={{ base: 16, sm: 20, lg: 100 }}
            pr={{ base: 16, sm: 20, lg: 100 }}>
            <Main />
            <EasyTips />
            <Advantages />
            <Flex
              direction='column'
              gap={{ sm: 104, base: 44 }}
              mx={{ base: -16, sm: 0 }}
              style={{ borderRadius: '24px', overflow: 'hidden' }}
              py={{ base: 24, sm: 0 }}
              px={{ base: 16, sm: 0 }}
              bg='white'>
              {' '}
              <GettingTips />
              <Plans />
              <Questions />
            </Flex>
          </Flex>
        </Flex>
        {/* footer */}
        <Stack id='contacts' pos='relative' pb={200}>
          <Stack
            bg='gray.8'
            pb={30}
            style={{
              overflow: 'hidden',
              position: 'absolute',
              width: '100%',
              top: 0,
              left: 0,
              zIndex: 3,
            }}>
            <Flex justify='center'>
              <Stack
                pt={32}
                pl={{ base: 16, sm: 20, lg: 100 }}
                pr={{ base: 16, sm: 20, lg: 100 }}
                w={1400}>
                <Flex gap={1} w={148} h={38} align='center'>
                  <LogoImg />
                  <LogoWord />
                </Flex>
                <Flex
                  gap={40}
                  wrap='wrap'
                  align='center'
                  justify='space-between'>
                  <Stack gap={8}>
                    <Flex wrap='wrap' gap={4}>
                      <Text fw={500}>Техническая поддержка:</Text>
                      <Text c='gray.7'>support@chainik.pro</Text>
                    </Flex>
                    <Flex wrap='wrap' gap={4}>
                      <Text fw={500}>Юридический адрес:</Text>{' '}
                      <Text c='gray.7'>
                        123112, г. Москва, наб. Пресненская, д. 12, этаж 3, оф.
                        322
                      </Text>
                    </Flex>
                    <Flex wrap='wrap' gap={4}>
                      <Text fw={500}>ИНН: </Text>{' '}
                      <Text c='gray.7'>9715296578</Text>
                    </Flex>
                    <Flex wrap='wrap' gap={4}>
                      <Text fw={500}>ОГРН: </Text>{' '}
                      <Text c='gray.7'>1177746268138</Text>
                    </Flex>
                  </Stack>

                  <Stack gap={16}>
                    {linksDocs.map((link, index) => (
                      <Text key={index} href={link.href} component='a' fw={500}>
                        {link.label}
                      </Text>
                    ))}
                  </Stack>
                </Flex>
              </Stack>
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Page;
