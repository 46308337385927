import { Flex, Stack, useMantineTheme } from '@mantine/core';
import { useUnit } from 'effector-react';
import {
  $header,
  $headerCollapsableBoxParams,
  $headerMaxHeight,
  headerCollapsableBoxStateEdited,
  headerMaxHeightStateEdited,
} from 'entities/app-flows/header';

import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';

type PropsType = {
  containerWidth: number;
};

export const HeaderCollapsableBox = ({ containerWidth }: PropsType) => {
  const theme = useMantineTheme();
  const { collapsableBoxChildren: children } = useUnit($header);
  const isActive = Boolean(children);

  const { scrollY, opacity } = useUnit($headerCollapsableBoxParams);
  const maxHeight = useUnit($headerMaxHeight);
  const { background, navTitle } = useUnit($header);

  const textColor = Boolean(background === 'colorful' && opacity !== 0)
    ? theme.white
    : theme.black;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView');

  const ref = useRef<HTMLDivElement>(null);

  const height = maxHeight ? Math.max(maxHeight - scrollY, 0) : undefined;

  useEffect(() => {
    const handleContentChange = () => {
      headerMaxHeightStateEdited(ref.current?.scrollHeight);
    };

    // Добавляем слушатель изменения содержимого страницы
    const observer = new MutationObserver(handleContentChange);
    const config = { subtree: true, childList: true };

    if (ref.current) {
      observer.observe(ref.current, config);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    headerMaxHeightStateEdited(ref.current?.scrollHeight);
    if (typeof height === 'number' && typeof maxHeight === 'number')
      headerCollapsableBoxStateEdited({ opacity: height / maxHeight });
  }, [height, maxHeight, scrollY]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        position: 'sticky',
        top: 0,
        overflow: 'hidden',
        justifyContent: 'center',
        maxHeight: '100%',
        opacity: isActive ? opacity : 0,
        transition: '0.7s all',
      }}>
      <Flex justify='center' style={{ width: '100%' }}>
        <AnimatedSwitchFade300ms watchedKey={`${pathname}-${currentView}`}>
          <Stack maw={containerWidth} flex={1} c={textColor}>
            {children}
          </Stack>
        </AnimatedSwitchFade300ms>
      </Flex>
    </div>
  );
};
