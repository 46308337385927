import {
  ActionIcon,
  Button,
  Chip,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useUnit } from 'effector-react';
import {
  $orgSelectedCategoryItemIds,
  orgSelectedCategoryItemIdsReplaced,
  orgSelectedCategoryItemIdsToggled,
} from 'entities/app-flows/org-flow';
import {
  $categoryTitlesAllCurrent,
  categoryTitleGetAllOwnInOrgFx,
} from 'entities/category';
import { CategoryItemEntityType } from 'entities/entities-types';
import {
  equals,
  filter,
  includes,
  length,
  lt,
  pathSatisfies,
  pipe,
} from 'ramda';
import { List as IconList, Settings as SettingsIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { generateColorFromId } from 'shared/lib/generators';
import { Condition, MessagePanel } from 'shared/ui/display';

type PropTypes = ContextModalProps;

const CategoryItemsFilterModal = ({ context, id, innerProps }: PropTypes) => {
  const theme = useMantineTheme();

  const {
    0: orgSelectedCategoryItemIds,
    1: categoryTitlesAllCurrent,
    2: categoryTitlesAllCurrentIsLoading,
  } = useUnit([
    $orgSelectedCategoryItemIds,
    $categoryTitlesAllCurrent,
    categoryTitleGetAllOwnInOrgFx.pending,
  ]);
  const handleCloseModal = () => context.closeModal(id);

  const getFilteredItems = filter(
    pipe(pathSatisfies(lt(0), ['categoryTitleItems', 'length'])),
  );
  const isArrayEmpty = pipe(length, equals(0));

  const onChangeChip = (item: CategoryItemEntityType) => {
    orgSelectedCategoryItemIdsToggled(item.categoryItemId);
  };

  return (
    <Stack gap={40}>
      <Stack gap={24}>
        <LoadingOverlay visible={categoryTitlesAllCurrentIsLoading} />
        <Divider />
        {getFilteredItems(categoryTitlesAllCurrent).map((title) => (
          <Stack key={title.categoryTitleId}>
            <Title fw={600} order={4}>
              {title.name}
            </Title>
            <Group>
              {title?.categoryTitleItems?.map?.((item) => (
                <Chip
                  styles={{ label: { height: '32px' } }}
                  variant='light'
                  color={generateColorFromId(item.categoryTitleId)}
                  key={item.categoryItemId}
                  checked={includes(
                    item.categoryItemId,
                    orgSelectedCategoryItemIds,
                  )}
                  onChange={() => onChangeChip(item)}>
                  {item.name}
                </Chip>
              ))}
            </Group>
          </Stack>
        ))}
      </Stack>

      <Condition
        when={isArrayEmpty(getFilteredItems(categoryTitlesAllCurrent))}>
        <MessagePanel
          icon={<IconList size='100%' />}
          title='Нет категорий'
          dscr='Добавьте категории и пункты категорий в соответствующем меню, чтобы тут появились пункты филтрации'
        />
      </Condition>

      <Condition
        when={!isArrayEmpty(getFilteredItems(categoryTitlesAllCurrent))}>
        <>
          <Group>
            <Button
              flex={1}
              size='lg'
              radius={12}
              variant='light'
              onClick={() => {
                orgSelectedCategoryItemIdsReplaced([]);
              }}>
              Сбросить
            </Button>
            <Button flex={1} size='lg' radius={12} onClick={handleCloseModal}>
              Применить
            </Button>
          </Group>
        </>
      </Condition>
      <Stack>
        <Divider />
        <Group align='center'>
          <Text style={{ flex: 1 }} fz='sm' ta='end' c='dimmed'>
            {'Подсказка: сотрудника можно прикрепить' +
              ' не\xa0более, чем\xa0к\xa0одному пункту категории'}
          </Text>
          <ActionIcon
            component={Link}
            to={getHref({
              '/org/settings': { currentView: 'category-management' },
            })}
            size='xl'
            color='gray'
            variant='light'
            onClick={() => {
              context.closeModal(id);
            }}>
            <SettingsIcon color={theme.colors.gray[6]} size='80%' />
          </ActionIcon>
        </Group>
      </Stack>
    </Stack>
  );
};

export default CategoryItemsFilterModal;
