import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { UserEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';
import { isNil } from 'ramda';
import { formRules } from 'shared/lib/forms';

export const $userSignUpForm = createForm<
  Pick<
    UserEntityType,
    'firstname' | 'lastname' | 'patronymic' | 'email' | 'phoneNumber' | 'avatar'
  > & {
    password: string;
    passwordConfirm: string;
    isOrgApplied: boolean;
    isPhoneOccupied: boolean | null;
    isEmailOccupied: boolean | null;
    phoneOrEmail: string;
  }
>({
  validateOn: ['blur', 'submit'],
  fields: {
    lastname: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(50),
      ],
    },
    firstname: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(50),
      ],
    },
    patronymic: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(50),
      ],
    },
    phoneNumber: {
      init: '',
      rules: [
        formRules.required(),
        formRules.phoneIsValid(),
        {
          name: 'phoneOccupationEmpty',
          validator: (value, { isPhoneOccupied }) => !isNil(isPhoneOccupied),
          errorText: 'Номер телефона еще не проверен',
        },
        {
          name: 'phoneOccupationTrue',
          validator: (value, { isPhoneOccupied }) => isPhoneOccupied === false,
          errorText: 'Номер телефона уже занят',
        },
      ],
    },
    email: {
      init: '',
      rules: [formRules.required(), formRules.emailIsValid()],
    },
    phoneOrEmail: {
      init: '',
      rules: [
        formRules.required(),
        formRules.emailOrPhoneIsValid(),
        {
          name: 'phoneOccupationEmpty',
          validator: (value, { isPhoneOccupied }) => {
            if (!/^\+?[1-9]\d{10}$/.test(value)) return true;
            return !isNil(isPhoneOccupied);
          },
          errorText: 'Номер телефона еще не проверен',
        },
        {
          name: 'phoneOccupationTrue',
          validator: (value, { isPhoneOccupied }) => {
            if (!/^\+?[1-9]\d{10}$/.test(value)) return true;
            return isPhoneOccupied === false;
          },
          errorText: 'Номер телефона уже занят',
        },
        {
          name: 'emailOccupationEmpty',
          validator: (value, { isEmailOccupied }) => {
            if (
              !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value,
              )
            )
              return true;
            return !isNil(isEmailOccupied);
          },
          errorText: 'Почта еще не проверена',
        },
        {
          name: 'emailOccupationTrue',
          validator: (value, { isEmailOccupied }) => {
            if (
              !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value,
              )
            )
              return true;
            return isEmailOccupied === false;
          },
          errorText: 'Почта уже занята',
        },
      ],
    },
    password: {
      init: '',
      rules: [
        formRules.required(),
        formRules.isEmpty(),
        formRules.maxLength(30),
        formRules.minLength(6),
      ],
    },
    passwordConfirm: {
      init: '',
      rules: [
        formRules.isEmpty(),
        {
          name: 'passwordConfirm',
          validator: (value, { password }) => value === password,
          errorText: 'USER.form.newPasswordConfirm.notEqual',
        },
      ],
    },
    avatar: {
      init: null,
      rules: [formRules.imageSize(), formRules.imageFormat()],
    },
    isOrgApplied: {
      init: false,
    },
    isPhoneOccupied: {
      init: null,
    },
    isEmailOccupied: {
      init: null,
    },
  },
});

export const $userSignInForm = createForm<{
  phoneOrEmail: string;
  password: string;
}>({
  validateOn: ['blur', 'submit'],
  fields: {
    phoneOrEmail: {
      init: '',
      rules: [formRules.required(), formRules.emailOrPhoneIsValid()],
    },
    password: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.minLength(6),
      ],
    },
  },
});

sample({
  clock: [appReset],
  target: [$userSignUpForm.reset, $userSignUpForm.reset],
});
