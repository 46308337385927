import { createStore, sample } from 'effector';
import { OrgInvitationEntityType } from 'entities/entities-types';
import { appReset, userOneOwnInvalidated } from 'entities/events';
import {
  orgInvModalClosed,
  orgInvitationCodeReceived,
} from 'entities/org-invitation/events';
import {
  orgInvCreateOneOwnInOrgFx,
  orgInvDeleteOneOwnInOrgFx,
  orgInvGetAllInOrgFx,
  orgInvitationGetOneFx,
  orgInvitationUseOneFx,
} from './effects';

export const $orgInvitationCurrent = createStore<OrgInvitationEntityType | null>(
  null,
)
  .on(orgInvitationGetOneFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset([orgInvModalClosed, orgInvitationUseOneFx.done]);

export const $orgInvitationAllInOrgCurrent = createStore<
  OrgInvitationEntityType[]
>([])
  .on(orgInvGetAllInOrgFx.doneData, (state, payload) => {
    return payload.data || [];
  })
  .reset(appReset);

sample({
  clock: [orgInvDeleteOneOwnInOrgFx.done, orgInvCreateOneOwnInOrgFx.done],
  target: orgInvGetAllInOrgFx,
});

export const $orgInvitationCurrentErrorCode = createStore<number | null>(null)
  .on(orgInvitationGetOneFx.fail, (state, payload) => {
    return payload?.error?.status || 0;
  })
  .reset([
    orgInvModalClosed,
    orgInvitationGetOneFx.done,
    orgInvitationUseOneFx.done,
  ]);

export const $orgInvitationCurrentCode = createStore<string | null>(null)
  .on(orgInvitationCodeReceived, (state, payload) => {
    return payload;
  })
  .reset([orgInvModalClosed, orgInvitationUseOneFx.done]);

sample({ clock: orgInvitationUseOneFx.done, target: userOneOwnInvalidated });
