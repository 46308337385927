import {
  MantineColorScheme,
  MantineTheme,
  MantineThemeOverride,
} from '@mantine/core';
import { breakPoints } from 'shared/model/const';

export const getCustomTheme = (
  themeColor: Partial<MantineColorScheme>,
): MantineThemeOverride => {
  return {
    fontFamily: 'Inter, sans-serif',
    components: {
      PasswordInput: {
        defaultProps: {
          size: 'lg',
          radius: '0.75rem',
        },
        styles: {
          root: { '& input': { border: '0' } },
          innerInput: {
            border: '0',
          },
          rightSection: {
            background: 'none',
          },
        },
      },
      Title: {
        defaultProps: {
          order: 4,
        },
        // styles: (theme) => SelectInputStyles(theme),
      },
      DatePickerInput: {
        defaultProps: {
          size: 'lg',
          minDate: new Date(2015, 0, 1),
          maxDate: new Date(2030, 0, 1),
        },
        // styles: (theme) => SelectInputStyles(theme),
      },
      Select: {
        defaultProps: {
          size: 'lg',
          transitionProps: {
            transition: 'pop',
            duration: 200,
            timingFunction: 'linear',
          },
        },
      },
      Overlay: {
        defaultProps: {
          color: themeColor === 'dark' ? '#141517' : '#fff',
        },
      },

      Drawer: {
        styles: (theme: MantineTheme) => ({
          content: {
            height: 'auto',
            maxWidth: 600,
            borderRadius: '12px 12px 0 0 ',
            // paddingBottom: theme.spacing.md,
          },

          title: { fontWeight: 700, fontSize: '24px' },
          inner: { justifyContent: 'center' },
        }),
        defaultProps: {
          position: 'bottom',
          overlayProps: {
            blur: 3,
            bg: 'rgba(33, 33, 33, 0.5)',
          },
        },
      },
      Collapse: {
        defaultProps: {
          transitionDuration: 300,
          transitionTimingFunction: 'linear',
        },
      },
      Button: {
        styles: {
          root: { fontWeight: 500, fontSize: '16px' },
        },
        defaultProps: {
          size: 'lg',
          radius: 'lg',
        },
      },

      Chip: {
        styles: {
          label: { height: '32px' },
        },
      },
      PinInput: {
        defaultProps: {
          size: 'lg',
          length: 6,
          radius: '12px',
          placeholder: '',
          oneTimeCode: true,
          inputMode: 'numeric',
          type: 'number',
        },
      },
      NumberInput: {
        defaultProps: {
          size: 'lg',
        },
        styles: {
          root: { '& input': { marginTop: '12px' } },
          rightSection: { display: 'none' },
        },
      },
      Switch: {
        styles: { track: { cursor: 'pointer' } },
      },
      FileInput: {
        defaultProps: { size: 'lg' },
        styles: {
          root: { '& input': { border: '0' } },
          innerInput: {
            border: '0',
          },
          rightSection: {
            background: 'none',
          },
        },
      },
      Modal: {
        defaultProps: {
          centered: true,
          overlayProps: { color: 'gray' },
          closeButtonProps: { size: 'md' },
          transitionProps: {
            transition: 'pop',
            duration: 300,
            timingFunction: 'linear',
          },
        },
        styles: {
          title: {
            fontWeight: 600,
            fontSize: 20,
            '& h2': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            },
          },
        },
      },
      MultiSelect: {
        defaultProps: {
          transitionProps: {
            transition: 'fade',
            duration: 400,
            timingFunction: 'linear',
          },
        },
      },
      Menu: {
        defaultProps: {
          transitionProps: {
            transition: 'fade',
            duration: 200,
            timingFunction: 'linear',
          },
        },
      },
      ScrollArea: {
        defaultProps: {
          scrollbarSize: 6,
        },
      },
      Text: {
        defaultProps: {
          weight: 400,
        },
      },
      Paper: {
        defaultProps: {
          shadow: 'md',
          radius: 'lg',
        },
      },
    },
    // fontFamily: font.style.fontFamily,
    white: '#FFF',
    black: '#2B2B2B',

    // headings: { fontFamily: font.style.fontFamily },
    colors: {
      background: [
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
        '#F4F5FB',
      ],
      brand: [
        'rgba(111,128,174,0.5)',
        '#cfd8ff',
        '#9dadff',
        '#667ffd',
        '#3958fb',
        '#1d40fa',
        '#3756FB',
        '#0026e1',
        '#0022ca',
        '#001CB2',
      ],

      red: [
        '#FCDADA',
        '#FAC7C7',
        '#F9B4B4',
        '#F7A1A1',
        '#F58F8F',
        '#F47C7C',
        '#F26969',
        '#F15757',
        '#EF4444',
        '#DF4343',
      ],
      green: [
        '#CFF1E6',
        '#B7EAD9',
        '#34C759',
        '#87DCC0',
        '#70D5B3',
        '#58CEA7',
        '#34C759',
        '#28C08E',
        '#10B981',
        '#21A87B',
      ],
      fadedGray: [
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#989CAD',
      ],
      dark: [
        '#FFFFFF',
        '#A6A7AB',
        '#909296',
        '#5C5F66',
        '#373A40',
        '#2C2E33',
        '#25262B',
        '#1A1B1E',
        '#141517',
        '#101113',
      ],
      gray: [
        '#F8F9FA',
        '#F1F3F5',
        '#E9ECEF',
        '#DEE2E6',
        '#CED4DA',
        '#ADB5BD',
        '#7F8798',
        '#9A9A9A',
        '#F4F5FB',
        '#2B2B2B',
      ],
    },
    breakpoints: breakPoints,
    primaryColor: 'brand',
  };
};
