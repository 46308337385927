import i18n from 'i18next';
import { keys } from 'ramda';
import { Fragment, PropsWithChildren } from 'react';
import { initReactI18next } from 'react-i18next';

import { i18resources } from './locales';

const TranslationsProvider = ({ children }: PropsWithChildren) => {
  const supportedLanguages = keys(i18resources);
  const defaultLanguage = 'ru';

  i18n.use(initReactI18next).init({
    resources: i18resources,
    fallbackLng: defaultLanguage,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
    parseMissingKeyHandler: function (key) {
      return ''; // Return an empty string for missing keys
    },
  });

  return <Fragment>{children}</Fragment>;
};
export default TranslationsProvider;
