import { Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import MyEmployeeSharingRules from 'widgets/user-flow/my-employee-sharing-rules';

type PropsType = ContextModalProps;

const SharingRulesNotificationModal = ({
  context,
  id,
  innerProps,
}: PropsType) => {
  return (
    <Stack>
      <MyEmployeeSharingRules />
    </Stack>
  );
};

export default SharingRulesNotificationModal;
