import { Paper, Stack, Stepper, Text } from '@mantine/core';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useState } from 'react';
import './embla.css';

type PropType = {
  slides: {
    id: number;
    title: string;
    text: string;
    img: JSX.Element;
  }[];
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
  });

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi],
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();

    emblaMainApi.on('select', onSelect).on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <Stack justify='center' w='100%'>
      <div className='embla'>
        <div className='embla__viewport' ref={emblaMainRef}>
          <div className='embla__container'>
            {slides.map((item) => (
              <div className='embla__slide' key={item.id}>
                <div className='embla__slide__number'>
                  <Paper
                    w={290}
                    h={464}
                    radius={44}
                    display='flex'
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      gap: '19px',
                      overflow: 'hidden',
                    }}
                    pb={24}>
                    {item.img}

                    <Stack px={24} gap={10}>
                      <Text fz={20} fw={700}>
                        {item.title}
                      </Text>
                      <Text c='gray'>{item.text}</Text>
                    </Stack>
                  </Paper>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Stepper
        classNames={{
          stepIcon: 'step_icon',
          root: 'root',
        }}
        styles={{
          stepIcon: {
            backgroundColor: 'white',
            fontWeight: 500,
          },
        }}
        m='auto'
        w={300}
        active={selectedIndex}
        onStepClick={(index) => onThumbClick(index)}>
        {slides.map((item, index) => (
          <Stepper.Step key={item.id} />
        ))}
      </Stepper>
    </Stack>
  );
};

export default EmblaCarousel;
