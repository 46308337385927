import {
  T,
  always,
  cond,
  equals,
  pipe,
  is,
  ifElse,
  split,
  map,
  forEach,
  when,
  objOf,
  head,
  prop,
  pick,
  values,
} from 'ramda';
import { ReactNode, Suspense, lazy, useEffect, useMemo } from 'react';

import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';

import { Center, Loader, LoadingOverlay } from '@mantine/core';
import { useGetEmployeeRoleText } from 'entities/employee';
import {
  $ratingMap, ratingGetOneFx,
} from 'entities/rating';
import { Search as IconSearch } from 'react-feather';
import { MessagePanel } from 'shared/ui/display';

import { useUnit } from 'effector-react';
import { getUserFio, getUserFioShort } from 'entities/user';

import { useSearchParams } from 'react-router-dom';
import { PATHS } from 'shared/model/const';
import { PublicPagePropsType } from 'pages/public/type';
import EntitySelectPage from 'pages/public/entity-select';

const TipPaymentSuccess = lazy(
  () => import('pages/public/tip-payment-success'),
);
const TipPaymentError = lazy(() => import('pages/public/tip-payment-error'));
const FeedbackHistory = lazy(() => import('pages/public/feedback-history'));
const Guest = lazy(() => import('pages/public/guest'));
const Qr = lazy(() => import('pages/public/qr'));

type ViewType = typeof PATHS['/public']['currentView'];

const getMultipleIds = ifElse(
  is(String),
  pipe(split(','), map(Number)),
  always([]),
);

const Page = () => {
  const [searchParams] = useSearchParams();
  const orgIds = getMultipleIds(searchParams.get('oid'));
  const userIds = getMultipleIds(searchParams.get('uid'));
  const employeeIds = getMultipleIds(searchParams.get('eid'));
 
  const orgId = head(orgIds);
  const userId = head(userIds);
  const employeeId = head(employeeIds);


  const billAmount = Number(searchParams.get('ba'));
  const visitUuid = String(searchParams.get('uuid'));
  const showWith = String(searchParams.get('showWith'))
  const currentView = String(searchParams.get('currentView')) as ViewType;
  const getRoleStr = useGetEmployeeRoleText();


  const [
    ratingMap,
    ratingIsLoading,
  ] = useUnit([
    $ratingMap,
    ratingGetOneFx.pending,
  ]);
  const employeesRatings = prop('employees', ratingMap);
  const userRatings = prop('users', ratingMap);
  const orgRatings = prop('orgs', ratingMap);

  const currentRatingEmps = pipe(pick(map(String, employeeIds)), values)(employeesRatings);
  const currentRatingOrgs = pipe(pick(map(String, orgIds)), values)(orgRatings);
  const currentRatingUsers = pipe(pick(map(String, userIds)), values)(userRatings);

  
  const multipleEntitiesAreGiven =  employeeIds.length > 1 || currentRatingOrgs.length > 1 || showWith==='emps' || showWith=== 'empsAndOrg' ||  currentRatingUsers.length > 1;
   
  const currentRatingEmp = head(currentRatingEmps);
  const currentRatingOrg = head(currentRatingOrgs);
  const currentRatingUser = head(currentRatingUsers);


  const currentRating =
    currentRatingEmp ||
    currentRatingOrg ||
    currentRatingUser ||
    undefined;

  const isLoading = ratingIsLoading;

  const entityAvatar =
    currentRating?.ratingEmployee?.employeeUser?.avatar ||
    currentRating?.ratingOrg?.avatar ||
    currentRating?.ratingUser?.avatar ||
    '';

  const entityName =
    getUserFioShort(currentRating?.ratingUser) ||
    getUserFioShort(currentRating?.ratingEmployee?.employeeUser) ||
    currentRating?.ratingOrg?.name ||
    'Данные не получены';

  const entityDscr =
    // TODO: убрать этого говно нахуй отсюда
    // @ts-ignore
    (currentRating?.ratingEmployee
      ? getRoleStr(
        // @ts-ignore
        currentRating?.ratingEmployee?.employeeRole?.roleValue ||
        currentRating?.ratingEmployee?.employeeRole ||
        '',
      )
      : '') ||
    (currentRating?.ratingUser ? 'Официант' : '') ||
    (currentRating?.ratingOrg ? 'Организация' : '');

  const entityGoalId =
    currentRating?.ratingEmployee?.employeeUser?.currentGoalId ||
    currentRating?.ratingUser?.currentGoalId;

  const publicPageCommonProps: PublicPagePropsType = {
    query: {
      orgId,
      userId,
      employeeId,
      billAmount,
      visitUuid,
    },
    entity: {
      name: entityName,
      dscr: entityDscr,
      currentGoalId: entityGoalId,
      avatar: entityAvatar as string | null | undefined,
      rating: currentRating,
    },
  };


  const getCurrentView = cond<ViewType[], ReactNode>([
    [always(multipleEntitiesAreGiven), always(<EntitySelectPage showWith={showWith} orgRatings={currentRatingOrgs}
                                                                userRatings={currentRatingUsers}
                                                                employeeRatings={currentRatingEmps} />)],
    [
      equals('feedback-history' as ViewType),
      always(<FeedbackHistory {...publicPageCommonProps} />),
    ],
    [equals('guest' as ViewType), always(<Guest {...publicPageCommonProps} />)],
    [equals('qr' as ViewType), always(<Qr {...publicPageCommonProps} />)],
    [
      equals('tip-payment-success' as ViewType),
      always(<TipPaymentSuccess {...publicPageCommonProps} />),
    ],
    [
      equals('tip-payment-error' as ViewType),
      always(<TipPaymentError {...publicPageCommonProps} />),
    ],
    [T, always(<FeedbackHistory {...publicPageCommonProps} />)],
  ]);

  useEffect(() => {
    forEach(when(is(Number), pipe(objOf('orgId'), ratingGetOneFx)))(orgIds);
    forEach(when(is(Number), pipe(objOf('userId'), ratingGetOneFx)))(userIds);
    forEach(when(is(Number), pipe(objOf('employeeId'), ratingGetOneFx)))(employeeIds);
  }, []);

  return (
    <AnimatedSwitchFade300ms watchedKey={currentView || ''}>
      <Suspense
        fallback={
          <LoadingOverlay
            h="100dvh"
            zIndex={1000}
            visible={true}
            loaderProps={{ type: 'bars' }}
            overlayProps={{ radius: 'xl' }}
          />
        }>
        {isLoading && (
          <Center h="100dvh">
            <Loader type="bars" />
          </Center>
        )}
        {!isLoading && currentRating && getCurrentView(currentView)}
        {!isLoading && !currentRating && (
          <Center h="100dvh">
            <MessagePanel
              icon={<IconSearch size="100%" />}
              title="Неверная ссылка"
              dscr="Адрес ссылки указывает на несуществующие данные"
            />
          </Center>
        )}
      </Suspense>
    </AnimatedSwitchFade300ms>
  );
};

export default Page;
