import { Flex, Stack, Text } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $header } from 'entities/app-flows';
import { $footer } from 'entities/app-flows/footer-flow';

import { Link } from 'react-router-dom';
import { Condition } from 'shared/ui/display';

type PropsType = {};

export const FooterBar = ({}: PropsType) => {
  const [footerState, header] = useUnit([$footer, $header]);

  return (
    <Condition when={Boolean(footerState.content) || footerState.isVisibleDoc}>
      <Stack px='md' py='md' align='center'>
        <Stack w='100%' maw={header.contentMaxWidth}>
          {footerState.content}
          <Condition when={Boolean(footerState.isVisibleDoc)}>
            <Flex justify='center' align='center' style={{ gap: '12px' }}>
              <Text fz='xs' c='dimmed' maw={100}>
                2024 © Чайник
              </Text>
              <Text fz='xs' c='dimmed'>
                |
              </Text>
              <Text fz='xs' component={Link} c='dimmed' to='/docs'>
                О сервисе
              </Text>
            </Flex>
          </Condition>
        </Stack>
      </Stack>
    </Condition>
  );
};
