import {
  DefaultMantineColor,
  MantineSpacing,
  Paper,
  Stack,
  StyleProp,
} from '@mantine/core';
import { PropsWithChildren } from 'react';

type PropsType = PropsWithChildren<{
  gap?: MantineSpacing | undefined;
  p?: StyleProp<MantineSpacing> | undefined;
  bg?: StyleProp<DefaultMantineColor> | undefined;
}>;

const MyPaper = ({ children, gap, p = 'sm', bg = 'background' }: PropsType) => {
  return (
    <Paper p={p} bg={bg} radius={12} shadow=''>
      <Stack gap={gap}>{children}</Stack>
    </Paper>
  );
};
export default MyPaper;
