import { Notification, Stack } from '@mantine/core';
import { ButtonWithConfirm } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';

type PropsType = {
  confirmDeleteCallback: () => void;
  entityType: 'org' | 'employee';
};

const CanceledView = ({ entityType, confirmDeleteCallback }: PropsType) => {
  return (
    <Stack style={{ overflow: 'hidden' }}>
      <Condition when={entityType === 'org'}>
        <Notification withCloseButton={false} color='red'>
          Вы отконили свою заявку на регистрацию заведения.
        </Notification>
      </Condition>
      <Condition when={entityType === 'employee'}>
        <Notification withCloseButton={false} color='red'>
          Вы отконили свою заявку на вступление в организацию.
        </Notification>
      </Condition>
      <ButtonWithConfirm
        onConfirmCallback={confirmDeleteCallback}
        confirmColor='red'
        buttonText='Удалить запись'
        buttonSize='sm'
      />
    </Stack>
  );
};

export default CanceledView;
