import { Stack, ThemeIcon } from '@mantine/core';
import { useHeader } from 'features';
import { Tool } from 'react-feather';

const Page = () => {
  useHeader({ navTitle: 'В разработке' });
  return (
    <Stack style={{ flex: 1 }} justify='center' align='center'>
      <ThemeIcon size='xl' variant='outline'>
        <Tool />
      </ThemeIcon>
    </Stack>
  );
};

export default Page;
