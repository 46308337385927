import { Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import {
  employeeCancelOneOwnFx,
  employeeDeleteOneOwnFx,
} from 'entities/employee/effects';
import { EmployeeEntityType, StatusValueType } from 'entities/entities-types';
import { orgCancelOneOwnFx } from 'entities/org/effects';
import { T, always, anyPass, cond, either, pathEq, propEq } from 'ramda';
import { Fragment, ReactNode } from 'react';
import { default as ApprovedView } from './approved-view';
import { default as CanceledView } from './canceled-view';
import { default as DeniedView } from './denied-view';
import { default as FiredView } from './fired-view';
import { default as ProcessingView } from './processing-view';
import { default as RemovingView } from './removing-view';

type PropsType = ContextModalProps<{ employee: EmployeeEntityType }>;

const EmploymentInfoModal = ({ context, id, innerProps }: PropsType) => {
  const { employee } = innerProps;
  const onConfirmDelete = () => {
    employeeDeleteOneOwnFx({ employeeId: employee.employeeId });
    context.closeModal(id);
  };
  const onCancelOwnOrg = () => {
    orgCancelOneOwnFx({ orgId: employee.orgId });
    context.closeModal(id);
  };
  const onCancelOwnEmployee = () => {
    employeeCancelOneOwnFx({ employeeId: employee.employeeId });
    context.closeModal(id);
  };

  const onRedirect = () => {
    context.closeModal(id);
  };

  const currentView = cond<EmployeeEntityType[], ReactNode>([
    /* -- ORG STATUS --- */
    [
      anyPass([
        pathEq('considering' as StatusValueType, ['employeeOrg', 'status']),
        pathEq('registered' as StatusValueType, ['employeeOrg', 'status']),
        pathEq('received' as StatusValueType, ['employeeOrg', 'status']),
      ]),
      always(
        <ProcessingView entityType='org' onCancelConfirm={onCancelOwnOrg} />,
      ),
    ],
    [
      either(
        pathEq('remove_processing' as StatusValueType, [
          'employeeOrg',
          'status',
        ]),
        pathEq('remove_registered' as StatusValueType, [
          'employeeOrg',
          'status',
        ]),
      ),
      always(<RemovingView />),
    ],
    [
      pathEq('denied' as StatusValueType, ['employeeOrg', 'status']),
      always(
        <DeniedView
          entityType='org'
          confirmDeleteCallback={onConfirmDelete}
          redirectCallback={onRedirect}
        />,
      ),
    ],
    [
      pathEq('canceled' as StatusValueType, ['employeeOrg', 'status']),
      always(
        <CanceledView
          entityType='org'
          confirmDeleteCallback={onConfirmDelete}
        />,
      ),
    ],
    [
      pathEq('removed' as StatusValueType, ['employeeOrg', 'status']),
      always(<Text>Запись удалена!</Text>),
    ],
    /* -- ORG STATUS --- */

    /* -- EMPLOYEE STATUS --- */
    [
      anyPass([
        pathEq('considering' as StatusValueType, ['status']),
        pathEq('registered' as StatusValueType, ['status']),
        pathEq('received' as StatusValueType, ['status']),
      ]),
      always(
        <ProcessingView
          entityType='employee'
          onCancelConfirm={onCancelOwnEmployee}
        />,
      ),
    ],
    [
      propEq('canceled' as StatusValueType, 'status'),
      always(
        <CanceledView
          entityType='employee'
          confirmDeleteCallback={onConfirmDelete}
        />,
      ),
    ],
    [
      propEq('fired' as StatusValueType, 'status'),
      always(<FiredView confirmDeleteCallback={onConfirmDelete} />),
    ],
    [
      propEq('denied' as StatusValueType, 'status'),
      always(
        <DeniedView
          redirectCallback={onRedirect}
          entityType='employee'
          confirmDeleteCallback={onConfirmDelete}
        />,
      ),
    ],
    [
      propEq('removed' as StatusValueType, 'status'),
      always(<Text>Запись удалена!</Text>),
    ],
    /* -- EMPLOYEE STATUS --- */

    /* -- REST --- */
    [
      T,
      always(
        <ApprovedView
          isOwner={employee.employeeRole?.roleValue === 'owner'}
          onConfirmDelete={onConfirmDelete}
          onConfirmCloseOrg={() =>
            console.log(
              // TODO: достать ручку у бэков на закрытие организации (создание заявки на это)
              'Тут могла быть ваша реклама (а должна быть ручка от бэков)',
            )
          }
        />,
      ),
    ],
  ]);

  return <Fragment>{currentView(employee)}</Fragment>;
};

export default EmploymentInfoModal;
