import { createEvent } from 'effector';
import {
  OrgEntityType,
  SessionEntityType,
  UserEntityType,
} from 'entities/entities-types';

import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { NavigateFunction, To } from 'react-router-dom';
import { TipsApiResType } from 'shared/api';

export const appReset = createEvent();
export const userSignOutDone = createEvent();
export const highlightedElementIdChanged = createEvent<
  'EMPLOYEE_SWITCHER' | ''
>();
export const highlightedElementIdReset = createEvent();

export const translationInstanceChanged = createEvent<TFunction>();
export const userInStatStateChanged = createEvent<'visit' | 'withdrawal'>();

export const navigateInstanceChanged = createEvent<NavigateFunction>();
export const navigateUsed = createEvent<To>();
export const orgOneOwnActiveChanged = createEvent<OrgEntityType | undefined>();

export const userOneOwnReceived = createEvent<UserEntityType>();
export const userOneOwnInvalidated = createEvent<void>();
export const currentSessionReceived = createEvent<
  TipsApiResType<SessionEntityType>
>();
export const currentSessionInvalidated = createEvent<void>();

export const collapsableBoxChildrenEdit = createEvent<ReactNode>();
