import dayjs from 'dayjs';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { visitGetStatisticFx } from 'entities/visit/effects';
import { ENDPOINTS_VISIT } from 'entities/visit/endpoints';

export const $$currentStatForUser = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_VISIT.GET_STATISTICS.req,
  typeof ENDPOINTS_VISIT.GET_STATISTICS.res
>({
  initialReq: {
    createdAtMin: dayjs().startOf('month').toDate(),
    createdAtMax: dayjs().toDate(),
    receiverEntities: ['user'],
    userIds: [0],
    groupBy: 'month',
  },
  initialFx: visitGetStatisticFx,
});
