import { Flex, Skeleton, Stack, Text, useMantineTheme } from '@mantine/core';
import { useUnit } from 'effector-react';
import {
  $balanceOneOwnOfOrg,
  $balanceOneOwnOfUser,
  balanceGetOneOwnOfOrgFx,
  balanceGetOneOwnOfUserFx,
} from 'entities/balance';
import { $currentSessionInfo } from 'entities/store';
import { always, equals, ifElse, propIs } from 'ramda';
import { useEffect } from 'react';
import { ChevronRight as ChevronRightIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { HeaderPressableBox } from 'shared/ui/buttons';

type PropsType = {
  balanceOwner: 'org' | 'user';
};

export const BalanceInfoBox = ({ balanceOwner }: PropsType) => {
  const navigate = useNavigate();
  const [
    currentSessionInfo,
    ownUserBalance,
    ownOrgBalance,
    ownUserBalanceIsLoading,
    ownOrgBalanceIsLoading,
  ] = useUnit([
    $currentSessionInfo,
    $balanceOneOwnOfUser,
    $balanceOneOwnOfOrg,
    balanceGetOneOwnOfOrgFx.pending,
    balanceGetOneOwnOfUserFx.pending,
  ]);
  const isLoading = ownUserBalanceIsLoading || ownOrgBalanceIsLoading;

  const balanceValue = ifElse(
    equals('org'),
    always(ownOrgBalance?.amount || 0),
    always(ownUserBalance?.amount || 0),
  )(balanceOwner);

  const theme = useMantineTheme();

  useEffect(() => {
    if (propIs(Number, 'userId', currentSessionInfo))
      balanceGetOneOwnOfUserFx();
    if (propIs(Number, 'orgId', currentSessionInfo)) balanceGetOneOwnOfOrgFx();
  }, [currentSessionInfo]);

  const handleGoToBalanceWithdraw = () =>
    balanceOwner === 'org'
      ? navigate(getHref({ '/org': { currentView: 'balance-withdrawal' } }))
      : navigate(getHref({ '/user': { currentView: 'balance-withdrawal' } }));

  return (
    <HeaderPressableBox variant='sublte' onClick={handleGoToBalanceWithdraw}>
      <Stack
        justify='center'
        c='white'
        gap={0}
        style={{ borderRadius: '16px' }}>
        <Text fz={14} lh={1.2} opacity={0.7}>
          {'Баланс: '}
        </Text>
        <Skeleton visible={isLoading}>
          <Text fz={32} fw='bold' lh={1.2}>
            {`${balanceValue.toLocaleString('ru-RU')} ₽`}
          </Text>
        </Skeleton>
      </Stack>
      <Flex align='center' justify='start' flex={1}>
        <ChevronRightIcon
          width={30}
          height={30}
          opacity={0.5}
          strokeWidth={2}
        />
      </Flex>
    </HeaderPressableBox>
  );
};

// return (
//   <Flex gap={10} align='center' wrap='wrap' justify='space-between'>
//     <Stack
//       justify='center'
//       c='white'
//       gap={0}
//       style={{ borderRadius: '16px' }}>
//       <Text fz={14} lh={1.2} opacity={0.7}>
//         {'Баланс: '}
//       </Text>
//       <Skeleton visible={isLoading}>
//         <Text fz={32} fw='bold' lh={1.2}>
//           {`${balanceValue.toLocaleString('ru-RU')} ₽`}
//         </Text>
//       </Skeleton>
//     </Stack>
//
//     <Button
//       p='12px 24px'
//       radius={12}
//       fz={15}
//       h={42}
//       w={120}
//       variant='white'
//       onClick={handleGoToBalanceWithdraw}>
//       Вывести
//     </Button>
//   </Flex>
// );
