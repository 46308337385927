import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset, orgOneOwnActiveChanged } from 'entities/events';
import { orgEditOneOwnFx } from 'entities/org/effects';

import { formRules } from 'shared/lib/forms';

export const $orgOwnNameAndInnChangeForm = createForm<{
  inn: string;
  name: string;
}>({
  validateOn: ['change', 'submit'],
  fields: {
    inn: {
      init: '',
      rules: [formRules.required(), formRules.innIsValid()],
    },
    name: {
      init: '',
      rules: [formRules.required(), formRules.maxLength(50)],
    },
  },
});

sample({
  clock: orgOneOwnActiveChanged,
  fn: (clockData) => {
    return {
      inn: clockData?.inn || '',
      name: clockData?.name || '',
    };
  },
  target: $orgOwnNameAndInnChangeForm.setForm,
});

sample({
  clock: [orgEditOneOwnFx.done, appReset],
  target: [$orgOwnNameAndInnChangeForm.reset],
});
