import { Button, Flex, List, Notification, Select, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useUnit } from 'effector-react';
import {
  $$orgInvOneCurrent,
  getIsOrgInvExpired,
  getIsOrgInvUsedUp,
} from 'entities/org-invitation';
import { orgInvitationUseOneFx } from 'entities/org-invitation/effects';
import { OrgInvBar } from 'features/org-invitation';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = ContextModalProps;

const getNow = () => new Date().getTime();

const OrgInviteUseModal = ({ context, id, innerProps }: PropsType) => {
  const { t } = useTranslation();
  const {
    0: orgInvitationCurrent,
    1: getIsLoading,
    2: useIsLoading,
    3: orgInvitationCurrentReqStatus,
    4: orgInvitationCurrentReq,
  } = useUnit([
    $$orgInvOneCurrent.$store.map((s) => s?.res?.data),
    $$orgInvOneCurrent.attachedFx.pending,
    orgInvitationUseOneFx.pending,
    $$orgInvOneCurrent.$store.map((s) => s.res?.status),
    $$orgInvOneCurrent.$store.map((s) => s.req),
  ]);

  const orgCurrent = orgInvitationCurrent?.orgInvitationOrg;

  const [selectedCategoryItemIdsMap, setSelectedCategoryItemIdsMap] = useState(
    {},
  );
  const selectedCategoryItemIds = Object.values(selectedCategoryItemIdsMap)
    .filter((item) => Boolean(item))
    .map((mapItem) => Number(mapItem));

  const handleSubmit = () => {
    if (!orgInvitationCurrentReq.code) return;
    orgInvitationUseOneFx({
      code: orgInvitationCurrentReq.code,
      categoryItem: { ids: [...selectedCategoryItemIds] },
    }).then(() => context.closeModal(id));
  };

  const isNotReceived = isEmpty(orgInvitationCurrent);
  const isExpired = getIsOrgInvExpired(orgInvitationCurrent);
  const isUsedUp = getIsOrgInvUsedUp(orgInvitationCurrent);
  const isNotValid = isExpired || isUsedUp || isNotReceived;

  return (
    <Stack>
      <OrgInvBar placement='in-user-page' />
      {isNotValid && (
        <Notification
          title='Приглашение недействительно'
          withCloseButton={false}
          color='red'>
          <List>
            {isExpired && (
              <List.Item fz='inherit'>
                Срок действия приглашения истёк.
              </List.Item>
            )}
            {isUsedUp && (
              <List.Item fz='inherit'>
                Приглашение уже использовано максимальное колличество раз.
              </List.Item>
            )}
            {isNotReceived && (
              <List.Item fz='inherit'>
                Ошибка при получении приглашения. Пожалуйста, попробуйте пройти
                по полученной ссылке ещё раз.
              </List.Item>
            )}
          </List>
        </Notification>
      )}

      {!isNotValid &&
        orgCurrent?.orgCategoryTitles
          ?.filter((item) => (item?.categoryTitleItems?.length || 0) > 0)
          .map((title, index) => (
            <Select
              size='md'
              key={index}
              label={title.name}
              data={title.categoryTitleItems?.map((item) => {
                return { value: String(item.categoryItemId), label: item.name };
              })}
              onChange={(value) =>
                setSelectedCategoryItemIdsMap({
                  ...selectedCategoryItemIdsMap,
                  [String(title.categoryTitleId)]: value,
                })
              }
              searchable
              clearable
            />
          ))}

      <Flex align='center' justify='space-around' gap='md'>
        <Button
          size='sm'
          style={{ flex: 1 }}
          variant='light'
          onClick={() => context.closeModal(id)}>
          {/* {t('SHARED.commonVerbs.cancel')} */}
          {t('SHARED.commonVerbs.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          style={{ flex: 1 }}
          size='sm'
          loading={useIsLoading || getIsLoading}
          disabled={isNotValid}>
          {/* {t('SHARED.commonVerbs.confirm')} */}
          Принять
        </Button>
      </Flex>
    </Stack>
  );
};
export default OrgInviteUseModal;
