import { Text } from '@mantine/core';
import { AnimatedSwitchFade100ms } from 'shared/ui/switch-transition-animation';

import { useTranslation } from 'react-i18next';

type PropsType = {
  resultErrorText: string | undefined;
  isLeftIconShown: boolean;
  inputHeight: number;
  gap: number;
};

export const MyInputError = ({
  resultErrorText,
  isLeftIconShown,
  inputHeight,
  gap,
}: PropsType) => {
  const { t } = useTranslation();
  return (
    <AnimatedSwitchFade100ms watchedKey={Boolean(resultErrorText)}>
      <Text
        lh={1}
        mih='sm'
        fz='sm'
        c='red'
        // pl={isLeftIconShown ? inputHeight + gap : gap}
      >
        {t(resultErrorText as TFunctionArg) || resultErrorText}
      </Text>
    </AnimatedSwitchFade100ms>
  );
};
