import { Flex, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { RatingEntityType } from 'entities/entities-types';
import { getGradeEnding } from 'shared/lib';
import { IconTeapot } from 'shared/model/icons';
import { Condition } from 'shared/ui/display';

type PropTypes = {
  rating?: RatingEntityType | undefined | null;
  ratingFromEmployee?: number;
  amountOfRating?: number;
  isFeedbackNumber?: boolean;
};
const RatingWithStars = ({
  rating,
  ratingFromEmployee,
  amountOfRating,
  isFeedbackNumber = true,
}: PropTypes) => {
  const theme = useMantineTheme();
  const averageGrade = Boolean(ratingFromEmployee)
    ? ratingFromEmployee
    : rating?.ratingValue;
  const totalAmount: number = amountOfRating
    ? amountOfRating
    : (rating?.bad ?? 0) + (rating?.good ?? 0) + (rating?.perfect ?? 0);

  return (
    <Flex align='left'>
      <Stack style={{ gap: '4px' }}>
        <Group
          px={8}
          gap={4}
          h={24}
          justify='center'
          bg={theme.colors.gray[2]}
          style={{ borderRadius: '16px', flexWrap: 'nowrap' }}>
          <Text fz={12}>{averageGrade ? averageGrade : 0}</Text>
          <IconTeapot height={16} width={16} color='#FFCF00' />
        </Group>

        <Condition when={isFeedbackNumber}>
          <Text fz={13}>
            {totalAmount}&nbsp;{getGradeEnding(totalAmount)}
          </Text>
        </Condition>
      </Stack>
    </Flex>
  );
};

export default RatingWithStars;
