import {
  Affix,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  NavLink,
  Paper,
  Stack,
  Text,
  Title,
  Transition,
} from '@mantine/core';
import { RatingEntityType } from 'entities/entities-types';
import React, { FC, useEffect, useLayoutEffect } from 'react';
import { always, assoc, forEach, is, isNotEmpty, map, objOf, pipe, prop, propOr, when } from 'ramda';
import { getPic } from 'entities';
import { Link, useSearchParams } from 'react-router-dom';
import { ChevronRight as IconChevronRight } from 'react-feather';
import { getUserFioShort } from 'entities/user';
import s from './style.module.css';
import { IconFallbackOrg, IconFallbackUser } from 'shared/model/vector-images';
import { ratingGetManyFx, ratingGetOneFx } from 'entities/rating/effects';
import { $ratingMany } from 'entities/rating';
import { useUnit } from 'effector-react';
import { Condition } from 'shared/ui/display';
import { useIsMobile } from 'shared/lib/hooks';
import { useHeader } from 'features';
import { useIntersection } from '@mantine/hooks';
import { LogoTipsImg } from 'shared/model';


type PropsType = {
  orgRatings: RatingEntityType[];
  userRatings: RatingEntityType[];
  employeeRatings: RatingEntityType[];
  showWith: string;
};


const EntitySelectPage: FC<PropsType> = ({ orgRatings, userRatings, employeeRatings, showWith }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const isMobile = useIsMobile();
  const [employees] = useUnit([$ratingMany]);

  const { ref: flagButtonRef, entry } = useIntersection({
    root: null,
    threshold: 1,
  });


  const header = (showWith && orgRatings.length === 1) ? {
    navTitle: (
      <Flex gap={8} align="center">
        <Avatar size={24} src={getPic(orgRatings[0].ratingOrg?.avatar)}>
          <IconFallbackOrg />
        </Avatar>
        <Text fw={600}>{orgRatings[0].ratingOrg?.name}</Text>
      </Flex>
    ),
  } : {};


  useHeader(header);


  useEffect(() => {
    if (showWith && orgRatings.length === 1) {
      orgRatings[0].ratingOrg?.orgId &&
      ratingGetManyFx({ orgIds: [orgRatings[0].ratingOrg?.orgId], receiverEntities: ['employee', 'org'] });
    }
  }, [showWith]);


  const orgSearchParams = (orgId?: number) => {
    if (orgRatings.length > 1 && showWith === 'emps' || showWith === 'empsAndOrg') {
      setSearchParams(pipe(
        assoc('oid', orgId),
      )(searchParamsObj));
      return orgId && ratingGetManyFx({ orgIds: [orgId], receiverEntities: ['employee', 'org'] });
    } else {
      setSearchParams(pipe(
        assoc('showWith', ''),
        assoc('uid', ''),
        assoc('eid', ''),
        assoc('oid', orgId),
      )(searchParamsObj));
    }
  };

  const empsSearchParams = (employeeId: number | undefined) => {
    setSearchParams(pipe(
      assoc('showWith', ''),
      assoc('uid', ''),
      assoc('eid', employeeId),
      assoc('oid', ''),
    )(searchParamsObj));
    forEach(when(is(Number), pipe(objOf('employeeId'), ratingGetOneFx)))([employeeId]);
  };

  return (
    <Stack>
      {orgRatings.length > 1 && (
        <Stack gap={40}>
          <Flex align="center" justify="space-between" gap={10}>
            <Stack w={122}>
              <Link to="/">
                <LogoTipsImg />
              </Link>
            </Stack>
            <Text maw={150} ta="right" c="brand.0" fz={12}>
              Вы помогаете развивать культуру чаевых!
            </Text>
          </Flex>
          <Stack gap={24}>
            <Title order={2}>Выберите заведение для чаевых</Title>
            <Stack>
              {map((item) => {
                const org = prop('ratingOrg', item);
                const orgId = org?.orgId;
                return (
                  <NavLink
                    key={orgId}
                    active
                    styles={{ label: { fontSize: '16px', fontWeight: '600' } }}
                    bg="background"
                    color="gray"
                    c="black"
                    p={4}
                    className={s.navlink}
                    style={{ transition: '0.3s background', borderRadius: '32px 16px 16px 32px' }}
                    onClick={() => orgSearchParams(orgId)}
                    leftSection={
                      <Avatar
                        variant="transparent"
                        size="64px"
                        src={getPic(propOr('', 'avatar', org))}
                      >
                        <IconFallbackOrg width={64} height={64} />
                      </Avatar>
                    }
                    label={item?.ratingOrg?.name}
                    // rightSection={<IconChevronRight />}
                  />
                );
              }, orgRatings)}
            </Stack>
          </Stack>
        </Stack>
      )}

      {isNotEmpty(employees) && orgRatings.length === 1 && (
        <Stack gap={24} mt={8}>
          <Title order={2}>Кому хотите оставить чаевые?</Title>
          <Stack gap="md">
            {/*<Text fz={14}>Все сотрудники</Text>*/}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(167px, 1fr))',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {employees.map((item) => {
                const employee = item.ratingEmployee;
                const employeeId = employee?.employeeId;
                const orgId = employee?.orgId;
                const user = employee?.employeeUser;

                return (
                  <Condition
                    key={orgId}
                    when={
                      Boolean(item.ratingEmployee) &&
                      item.ratingEmployee?.employeeRole?.roleValue !== 'owner'
                    }
                  >
                    <NavLink
                      active
                      h={140}
                      ta="center"
                      styles={{
                        label: { fontSize: '16px', fontWeight: '600' },
                        root: { flexDirection: 'column', gap: '12px' },
                        section: { marginInlineEnd: '0px' },
                      }}
                      bg="background"
                      color="gray"
                      p={12}
                      c="black"
                      className={s.navlink}
                      style={{
                        transition: '0.3s background',
                        borderRadius: '16px',
                      }}
                      onClick={() => empsSearchParams(employeeId)}
                      leftSection={
                        <Avatar
                          variant="transparent"
                          size={80}
                          src={getPic(String(user?.avatar))}
                        >
                          <IconFallbackUser width={80} height={80} />
                        </Avatar>
                      }
                      label={getUserFioShort(user, true)}
                    />
                  </Condition>
                );
              })}
            </div>
          </Stack>
        </Stack>

      )}

      {isNotEmpty(employeeRatings) && (showWith !== 'emps' && showWith !== 'empsAndOrg') && (
        <Stack gap="xl">
          <Title order={2}>Выберите сотрудника</Title>
          <Stack>
            {map((item) => {
              const employee = item.ratingEmployee;
              const employeeId = employee?.employeeId;
              const orgId = employee?.orgId;
              const user = employee?.employeeUser;
              return (
                <NavLink
                  key={orgId}
                  active
                  styles={{ label: { fontSize: '16px', fontWeight: '600' } }}
                  bg="background"
                  color="gray"
                  c="black"
                  p={4}
                  className={s.navlink}
                  style={{ transition: '0.3s background', borderRadius: '32px 16px 16px 32px' }}
                  onClick={() => setSearchParams(pipe(
                    assoc('uid', ''),
                    assoc('eid', employeeId),
                    assoc('oid', orgId),
                  )(searchParamsObj))}
                  leftSection={
                    <Avatar
                      variant="transparent"
                      size={64}
                      src={getPic(String(user?.avatar))}
                    >
                      <IconFallbackUser width={64} height={64} />
                    </Avatar>
                  }
                  label={getUserFioShort(user)}
                  description="Сотрудник"
                />
              );
            }, employeeRatings)}
          </Stack>
        </Stack>
      )}

      {isNotEmpty(userRatings) && (
        <Stack gap="xl">
          <Title order={2}>Выберите сотрудника</Title>
          <Divider />
          <Stack>
            {map((item) => {
              const user = prop('ratingUser', item);
              const userId = user?.userId;
              return (
                <NavLink
                  key={userId}
                  active
                  styles={{ label: { fontSize: '16px', fontWeight: '600' } }}
                  bg="background"
                  color="gray"
                  c="black"
                  p={4}
                  style={{ transition: '0.3s background', borderRadius: '32px 16px 16px 32px' }}
                  onClick={() => setSearchParams(pipe(
                    assoc('uid', userId),
                    assoc('eid', ''),
                    assoc('oid', ''),
                  )(searchParamsObj))}
                  leftSection={
                    <Avatar
                      size={64}
                      src={getPic(propOr('', 'avatar', user))}
                    >
                      <IconFallbackUser width={64} height={64} />
                    </Avatar>
                  }
                  label={getUserFioShort(user)}
                  description="Сотрудник"
                />
              );
            }, userRatings)}
          </Stack>
        </Stack>
      )}


      {orgRatings.length === 1 && showWith === 'empsAndOrg' && (
        <Box>
          <Button
            ref={flagButtonRef}
            fullWidth
            onClick={() => setSearchParams(pipe(
              assoc('showWith', ''),
              assoc('uid', ''),
              assoc('eid', ''),
              assoc('oid', orgRatings[0].ratingOrg?.orgId),
            )(searchParamsObj))}
          >
            Отблагодарить весь персонал
          </Button>


          <Transition
            mounted={isMobile && !entry?.isIntersecting}
            transition="slide-up"
            duration={300}
            timingFunction="ease"
          >
            {(styles) => (
              <Affix style={styles} h={74} w="100%" position={{ bottom: 0 }}>
                <Paper
                  display="flex"
                  shadow="0 0 24px 0 rgba(51, 51, 52, 0.35)"
                  bg="white"
                  p={12}
                  style={{ gap: '8px' }}
                  radius="16px 16px 0px 0px"
                >
                  <Button
                    fullWidth
                    onClick={() => setSearchParams(pipe(
                      assoc('showWith', ''),
                      assoc('uid', ''),
                      assoc('eid', ''),
                      assoc('oid', orgRatings[0].ratingOrg?.orgId),
                    )(searchParamsObj))}
                  >
                    Отблагодарить весь персонал
                  </Button>
                </Paper>
              </Affix>
            )}
          </Transition>

        </Box>
      )}
    </Stack>
  );
};

export default EntitySelectPage;