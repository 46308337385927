// Создаем эффект для отправки данных по ссылке и получения ответа

import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_SBP } from './endpoints';

// export const getPaymentSbpLinkFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK.req,
//   typeof ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK.res
// >({
//   fxName: false,
//   service: 'SBP',
//   // Предполагается, что ENDPOINTS_PAYMENT_SBP_LINK содержит путь для отправки данных
//   endpoint: ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK,
// });
//
// export const getPaymentCardLinkFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_PAYMENT_CARD_LINK.req,
//   typeof ENDPOINTS_SBP.GET_PAYMENT_CARD_LINK.res
// >({
//   fxName: false,
//   service: 'SBP',
//   // Предполагается, что ENDPOINTS_PAYMENT_SBP_LINK содержит путь для отправки данных
//   endpoint: ENDPOINTS_SBP.GET_PAYMENT_CARD_LINK,
// });
//
// export const getPayoutCardLinkFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_PAYOUT_CARD_LINK.req,
//   typeof ENDPOINTS_SBP.GET_PAYOUT_CARD_LINK.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.GET_PAYOUT_CARD_LINK,
// });
//
// export const getPayoutSbpLinkFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_PAYOUT_SBP_LINK.req,
//   typeof ENDPOINTS_SBP.GET_PAYOUT_SBP_LINK.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.GET_PAYOUT_SBP_LINK,
// });
// export const getPayinLink = createApiEffect<
//   typeof ENDPOINTS_SBP.PAYIN.req,
//   typeof ENDPOINTS_SBP.PAYIN.res>({
//   fxName: false,
//   service: 'API',
//   endpoint: ENDPOINTS_SBP.PAYIN,
// });
// export const getPayoutLink = createApiEffect<
//   typeof ENDPOINTS_SBP.PAYOUT.req,
//   typeof ENDPOINTS_SBP.PAYOUT.res>({
//   fxName: false,
//   service: 'API',
//   endpoint: ENDPOINTS_SBP.PAYOUT,
// });

export const getSbpPrecheckExecuterFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYOUT_SBP_CHECK.req,
  typeof ENDPOINTS_SBP.PAYOUT_SBP_CHECK.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYOUT_SBP_CHECK,
});

export const getSbpPrecheckOrgFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYOUT_SBP_CHECK_ORG.req,
  typeof ENDPOINTS_SBP.PAYOUT_SBP_CHECK_ORG.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYOUT_SBP_CHECK_ORG,
});

// export const getSbpPayoutFx = createApiEffect<
//   typeof ENDPOINTS_SBP.PAYOUT_SBP.req,
//   typeof ENDPOINTS_SBP.PAYOUT_SBP.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.PAYOUT_SBP,
// });
//
// export const getPayoutCardFx = createApiEffect<
//   typeof ENDPOINTS_SBP.PAYOUT_CARD.req,
//   typeof ENDPOINTS_SBP.PAYOUT_CARD.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.PAYOUT_CARD,
// });
//
// export const getPayinCardFx = createApiEffect<
//   typeof ENDPOINTS_SBP.PAYIN_CARD.req,
//   typeof ENDPOINTS_SBP.PAYIN_CARD.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.PAYIN_CARD,
// });
//
// export const getPayinSbpFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK.req,
//   typeof ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK.res
// >({
//   fxName: false,
//   service: 'SBP',
//   endpoint: ENDPOINTS_SBP.GET_PAYMENT_SBP_LINK,
// });
//
// export const getAmountWithCommissionFx = createApiEffect<
//   typeof ENDPOINTS_SBP.GET_ONE_COMMISSION.req,
//   typeof ENDPOINTS_SBP.GET_ONE_COMMISSION.res
// >({
//   fxName: false,
//   service: 'API',
//   endpoint: ENDPOINTS_SBP.GET_ONE_COMMISSION,
// });
export const getPayoutExecuterLinkFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYOUT.req,
  typeof ENDPOINTS_SBP.PAYOUT.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYOUT,
});
export const getPayoutOrgLinkFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYOUT_ORG.req,
  typeof ENDPOINTS_SBP.PAYOUT_ORG.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYOUT_ORG,
});
export const getPayInEmpFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYMENT_PAY_IN_EMP.req,
  typeof ENDPOINTS_SBP.PAYMENT_PAY_IN_EMP.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYMENT_PAY_IN_EMP,
});

export const getPayInOrgFx = createApiEffect<
  typeof ENDPOINTS_SBP.PAYMENT_PAY_IN_ORG.req,
  typeof ENDPOINTS_SBP.PAYMENT_PAY_IN_ORG.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.PAYMENT_PAY_IN_ORG,
});

export const getAmountWithCommissionFx = createApiEffect<
  typeof ENDPOINTS_SBP.GET_ONE_COMMISSION.req,
  typeof ENDPOINTS_SBP.GET_ONE_COMMISSION.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_SBP.GET_ONE_COMMISSION,
});
