import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_FEEDBACK } from './endpoints';

export const feedbackGetManyFx = createApiEffect<
  typeof ENDPOINTS_FEEDBACK.GET_MANY.req,
  typeof ENDPOINTS_FEEDBACK.GET_MANY.res
>({
  endpoint: ENDPOINTS_FEEDBACK.GET_MANY,
  service: 'API',
  fxName: false,
});
