import { SharingRuleEntityType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api';

export const ENDPOINTS_SHARING_RULE = {
  GET_ALL_CATEGORY_ITEM_DONORS: {
    path: 'sharing-rules/get_all_category_item_donors',
    method: 'post' as HttpMethod,
    req: { donorCategoryItemId: 1 } as
      | { donorCategoryItemId: number }
      | { donorOrgId: number }
      | { donorEmployeeId: number },
    res: {
      data: [
        { employeeId: 1, totalShareRatio: 0.92 },
        { employeeId: 3, totalShareRatio: 0.74 },
        { employeeId: 4, totalShareRatio: 0.25 },
        { employeeId: 12, totalShareRatio: 0.12 },
        { employeeId: 16, totalShareRatio: 0.52 },
        { employeeId: 25, totalShareRatio: 0.02 },
      ],
    } as TipsApiResType<{ employeeId: number; totalShareRatio: number }[]>,
  },

  GET_ALL: {
    path: 'sharing-rules/get_all',
    method: 'post' as HttpMethod,
    req: { donorCategoryItemId: [1] } as {
      sharingRuleIds?: number[];

      orgIds?: number[];

      donorCategoryItemId?: number[];
      donorEmployeeId?: number[];
      donorOrgId?: number[];

      receiverCategoryItemId?: number[];
      receiverEmployeeId?: number[];
      receiverOrgId?: number[];
    },
    res: {
      // data: [
      //   {
      //     sharingRuleId: 1,
      //     shareRatio: 0.05,
      //     donorCategoryItemId: 1,
      //     receiverCategoryItemId: 5,
      //   },
      //   {
      //     sharingRuleId: 1,
      //     shareRatio: 0.05,
      //     donorCategoryItemId: 1,
      //     receiverCategoryItemId: 4,
      //   },
      // ],
    } as TipsApiResType<SharingRuleEntityType[]>,
  },

  GET_ALL_OWN: {
    path: 'sharing-rules/get_all_own',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<SharingRuleEntityType[]>,
  },

  GET_ALL_OWN_IN_ORG: {
    path: 'sharing-rules/get_all_own_in_org',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<SharingRuleEntityType[]>,
  },

  // ручка требуется для того, чтобы в одной транзации добавлять и удалять правила.
  // если уже существует правило с сочетанием донора и получателя
  // и такое же сочетание появляется в запросе, то оно замещается новым (поэтому PUT)
  PUT_AND_DELETE_MANY_OWN: {
    path: 'sharing-rules/put_and_delete_many_own',
    method: 'patch' as HttpMethod,
    res: { data: 'DONE' } as TipsApiResType<string>,
    req: {
      put: {
        donorCategoryItemId: 1,
        receivers: [
          { receiverCategoryItemId: 2, shareRatio: 0.05 },
          { receiverCategoryItemId: 3, shareRatio: 0.15 },
        ],
      },
      delete: {
        sharingRuleIds: [1, 12, 65],
      },
    } as {
      put: {
        donorEmployeeId?: number;
        donorOrgId?: number;
        donorCategoryItemId?: number;
        receivers: {
          shareRatio: number;
          receiverCategoryItemId?: number;
          receiverEmployeeId?: number;
          receiverOrgId?: number;
        }[];
      };
      delete: {
        sharingRuleIds: number[];
      };
    },
  },

  DELETE_MANY_OWN: {
    path: 'sharing-rules/delete_many_own_in_org',
    method: 'delete' as HttpMethod,
    req: { sharingRuleIds: [1, 5] } as { sharingRuleIds: number[] },
    res: { data: 'Успех' } as TipsApiResType<string>,
  },
};
