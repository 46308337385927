import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useUnit } from 'effector-react';
import {
  $orgInvitationAllInOrgCurrent,
  getIsOrgInvExpired,
  getIsOrgInvUsedUp,
  orgInvGetAllInOrgFx,
} from 'entities/org-invitation';
import { either, isEmpty, reject } from 'ramda';
import { Plus as PlusIcon, Settings as SettingsIcon } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'shared/api/common';
import { getHref } from 'shared/lib';
import { useCopyToClipboardAndNotify } from 'shared/lib/hooks';
import { IconCopy as CopyIcon } from 'shared/model/icons';

type PropsType = ContextModalProps<{ host: string }>;

const OrgInvListModal = ({ context, id, innerProps }: PropsType) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const origin = window.location.origin;

  useQuery({
    fn: orgInvGetAllInOrgFx,
    // condition: () => !isNilOrEmpty(employeeId),
  });
  const { 0: orgInvitations, 1: isLoading } = useUnit([
    $orgInvitationAllInOrgCurrent,
    orgInvGetAllInOrgFx.pending,
  ]);
  const copyLinkClipboard = useCopyToClipboardAndNotify(
    'Копирование ссылки на приглашение',
  );

  const filteredOrgInvs = reject(
    either(getIsOrgInvExpired, getIsOrgInvUsedUp),
    orgInvitations,
  );

  return (
    <Stack gap='xs'>
      <LoadingOverlay visible={isLoading} />
      <Divider />

      {isEmpty(filteredOrgInvs) && (
        <Stack align='center'>
          <Text c='gray' ta='center'>
            В организации нет активных ссылок для приглашения
          </Text>
          <Button
            component={Link}
            to={getHref({
              '/org/settings': { currentView: 'invitations-management' },
            })}
            leftSection={<PlusIcon />}
            size='md'
            variant='light'
            onClick={() => {
              context.closeModal(id);
            }}>
            Добавить
          </Button>
        </Stack>
      )}
      {filteredOrgInvs.map((item) => {
        const url = innerProps.host + '/bridge?oic=' + item.code;

        return (
          <Flex key={item.code} align='center' gap='md'>
            <ActionIcon
              size='lg'
              color='gray'
              variant='light'
              onClick={() => copyLinkClipboard(url)}>
              <CopyIcon />
            </ActionIcon>
            <Text fz='sm' style={{ flex: 1 }} truncate='end'>
              {url}
            </Text>
          </Flex>
        );
      })}
      <Divider />
      <Flex gap='sm' align='center'>
        <Text fz='sm' ta='end' flex={1}>
          Отправьте одну из ссылок сотрудникам, чтобы они могли подать заявку на
          вступление
        </Text>
        <ActionIcon
          size='xl'
          color='gray'
          variant='light'
          onClick={() => {
            navigate(
              getHref({
                '/org/settings': {
                  currentView: 'invitations-management',
                },
              }),
            );
            context.closeModal(id);
          }}>
          <SettingsIcon color={theme.colors.gray[6]} size='80%' />
        </ActionIcon>
      </Flex>
    </Stack>
  );
};

export default OrgInvListModal;
