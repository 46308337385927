import {
  CategoryItemEntityType,
  CategoryTitleEntityType,
  EmployeeEntityType,
  RoleValueType,
  StatusValueType,
} from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';

export type EmployeeGetOneResType = TipsApiResType<
  Omit<EmployeeEntityType, 'employeeCategoryItems' | 'employeeRole'> & {
    employeeRole: RoleValueType;
    employeeCategoryItems: [
      {
        employeeMetaItem: CategoryItemEntityType;
        employeeMetaTitle: CategoryTitleEntityType;
      },
    ];
  }
>;
export const ENDPOINTS_EMPLOYEE = {
  GET_ONE: {
    path: 'employee/get_one',
    method: 'post' as HttpMethod,
    req: { employeeId: 1 } as Pick<EmployeeEntityType, 'employeeId'>,
    res: {} as EmployeeGetOneResType,
  },

  // CREATE_ONE_OWN_PROPOSED: {
  //   path: 'employee/create_one',
  //   // path: 'employee/create_one_own_proposed',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     userId: 1,
  //     orgId: 1,
  //     roleValue: 'user',
  //   },
  //   res: {} as TipsApiResType<void>,
  // },

  CANCEL_ONE_OWN: {
    path: 'employee/cancel_one_own',
    method: 'patch' as HttpMethod,
    req: { employeeId: 1 } as Pick<EmployeeEntityType, 'employeeId'>,
    res: { data: 'OK' } as TipsApiResType<string>,
  },

  DELETE_ONE_OWN: {
    path: 'employee/delete_one_own',
    method: 'delete' as HttpMethod,
    req: { employeeId: 1 } as Pick<EmployeeEntityType, 'employeeId'>,
    res: { data: 'OK' } as TipsApiResType<string>,
  },

  SWITCH_ONE_OWN: {
    path: 'employee/switch_one_own',
    method: 'patch' as HttpMethod,
    req: { employeeId: 1 } as Pick<EmployeeEntityType, 'employeeId'>,
    res: {} as TipsApiResType<void>,
  },

  LOGOUT_ONE_OWN: {
    path: 'employee/logout_own',
    method: 'patch' as HttpMethod,
    req: undefined as undefined,
    res: {} as TipsApiResType<void>,
  },

  EDIT_MANY: {
    path: 'employee/edit_many',
    method: 'post' as HttpMethod,
    req: {
      // обращаю внимание ан camelCase. Хочу Id, а не ID
      employeeIds: [8],
      status: 'approved',
      roleValue: 'employee',
      categoryItemIds: {
        // ключ - это categoryTitleId, значениие - это categoryItemId
        // таким образом обеспечивается то, что на одной категории
        // не может быть больше одной записи у одного EMPLOYEE

        // тут назначаем/переназначаем что-то новое (удаляется старая запись, делается новая)
        '1': 1,
        // тут сбрасываем привязку к категории (удаляется старая запись)
        // надо отправлять при этом number <=0
        '2': -1,
        // если ничего больше не указано, то другие категории не трогаем
      },
    } as {
      employeeIds: number[] | [];
      status?: StatusValueType;
      roleValue?: RoleValueType;
      categoryTitleMap?: { [key: string]: number | null };
    },
    res: {} as TipsApiResType<void>,
  },

  GET_MANY_OWN_IN_ORG: {
    path: 'employee/get_many_own_in_org',
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<{
      user?: { ids?: number[] };
      employee?: { ids?: number[]; status?: StatusValueType[] };
      categoryItem?: { ids?: number[] };
      fioSearchString?: string;
    }>,
    res: {} as TipsApiResType<PaginatedResType<EmployeeEntityType>>,
  },

  GET_EMPLOYEE_LIST: {
    path: 'employee/employee_list',
    method: 'post' as HttpMethod,
    req: { currentPage: 1, itemsPerPage: 3 } as PaginatedReqType<{}>,
    res: {} as TipsApiResType<PaginatedResType<EmployeeEntityType>>,
  },
  GET_EMPLOYEE_PROPOSED_LIST: {
    path: 'employee/employee_log_list',
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<void>,
    res: {} as TipsApiResType<PaginatedResType<EmployeeEntityType>>,
  },
  POST_EMPLOYEE_APPROVE_ONE: {
    path: 'employee/employee_approve',
    method: 'post' as HttpMethod,
    req: {} as Pick<EmployeeEntityType, 'employeeLogId'> & {
      logStatus: 'accepted';
    },
    res: {} as TipsApiResType<any>,
  },
};
