import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_GOAL } from './endpoints';

export const goalCreateOneOwnFx = createApiEffect<
  typeof ENDPOINTS_GOAL.CREATE_ONE_OWN.req,
  typeof ENDPOINTS_GOAL.CREATE_ONE_OWN.res
>({
  fxName: 'goalCreateOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.CREATE_ONE_OWN,
});

export const goalGetOneFx = createApiEffect<
  typeof ENDPOINTS_GOAL.GET_ONE.req,
  typeof ENDPOINTS_GOAL.GET_ONE.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.GET_ONE,
});

export const goalGetManyFx = createApiEffect<
  typeof ENDPOINTS_GOAL.GET_MANY_OWN.req,
  typeof ENDPOINTS_GOAL.GET_MANY_OWN.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.GET_MANY_OWN,
});

export const goalEditOneOwnFx = createApiEffect<
  typeof ENDPOINTS_GOAL.EDIT_ONE_OWN.req,
  typeof ENDPOINTS_GOAL.EDIT_ONE_OWN.res
>({
  fxName: 'goalEditOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.EDIT_ONE_OWN,
});

export const goalSwitchOneOwnFx = createApiEffect<
  typeof ENDPOINTS_GOAL.SWITCH_ONE_OWN.req,
  typeof ENDPOINTS_GOAL.SWITCH_ONE_OWN.res
>({
  fxName: 'goalSwitchOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.SWITCH_ONE_OWN,
});

export const goalDeleteOneOwnFx = createApiEffect<
  typeof ENDPOINTS_GOAL.DELETE_ONE_OWN.req,
  typeof ENDPOINTS_GOAL.DELETE_ONE_OWN.res
>({
  fxName: 'goalDeleteOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_GOAL.DELETE_ONE_OWN,
});
