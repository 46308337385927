import { OrgInvitationEntityType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api/types';

export const ENDPOINTS_ORG_INVITATION = {
  GET_ONE: {
    path: 'org_invitation/get_one',
    method: 'post' as HttpMethod,
    req: { code: '2821488228' } as Pick<OrgInvitationEntityType, 'code'>,
    res: {} as TipsApiResType<OrgInvitationEntityType>,
  },

  // содаёт заявку на вступление в организацию по OrgInvitation
  USE_ONE: {
    path: 'org_invitation/use_one',
    method: 'post' as HttpMethod,
    req: {
      code: '2821488228',
      categoryItem: { ids: [1, 25, 73] },
    } as {
      code: string;
      categoryItem?: { ids?: number[] };
    },
    res: {} as TipsApiResType<void>,
  },

  // с помощью этой ручки менеджер может создвавать приглашения на вступление в организацию,
  // к которым сразу привязываются ссылки.
  // К инвайту привязывается ROLE_ID
  CREATE_ONE_OWN_IN_ORG: {
    path: 'org_invitation/create_one_own_in_org',
    method: 'post' as HttpMethod,
    req: {
      expiresAt: new Date(),
      maxUsesCount: 5,
      employeeRoleID: 2,
    } as Pick<OrgInvitationEntityType, 'expiresAt' | 'maxUsesCount'> & {
      employeeRoleID: number;
    },
    res: {} as TipsApiResType<OrgInvitationEntityType>,
  },

  DELETE_ONE_OWN_IN_ORG: {
    path: 'org_invitation/delete_one',
    method: 'delete' as HttpMethod,
    req: { orgInvitationId: 15 } as Pick<
      OrgInvitationEntityType,
      'orgInvitationId'
    >,
    res: {} as TipsApiResType<void>,
  },

  GET_ALL_OWN_IN_ORG: {
    path: `org_invitation/get_all_own_in_org`,
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<OrgInvitationEntityType[]>,
  },
};
