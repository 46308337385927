import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { feedbackGetManyFx } from 'entities/feedback/effects';
import { ENDPOINTS_FEEDBACK } from 'entities/feedback/endpoints';

export const $$feedbackForCurrentUser = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_FEEDBACK.GET_MANY.req,
  typeof ENDPOINTS_FEEDBACK.GET_MANY.res
>({
  initialReq: { currentPage: 1, itemsPerPage: 3 },
  initialFx: feedbackGetManyFx,
});

export const $$feedbackForFeedbackPage = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_FEEDBACK.GET_MANY.req,
  typeof ENDPOINTS_FEEDBACK.GET_MANY.res
>({
  initialReq: { currentPage: 1, itemsPerPage: 0 },
  initialFx: feedbackGetManyFx,
});
