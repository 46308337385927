import { Center, Flex, NavLink } from '@mantine/core';
import { default as AppMainLayout } from 'app/providers/app-main-layout';
import { default as ModalsProvider } from 'app/providers/modals-provider';
import { default as Auth } from 'pages/auth';
import { default as Bridge } from 'pages/bridge';
import { default as Docs } from 'pages/docs';
import { default as Home } from 'pages/home';
import { default as InDevelopment } from 'pages/in-development';
import { default as Org } from 'pages/org';
import { default as OrgSettings } from 'pages/org-settings';
import { default as Public } from 'pages/public';
import { default as User } from 'pages/user';
import { default as Welcome } from 'pages/welcome';
import { ChevronRight, Search } from 'react-feather';
import {
  Link,
  Navigate,
  RouterProvider as ReactRouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { MessagePanel } from 'shared/ui/display';
import { default as SessionProtectedRouteWrapper } from './protected-route-wrapper';

const RouterProvider = () => {
  const router = createBrowserRouter([
    {
      element: (
        <ModalsProvider>
          <AppMainLayout />
        </ModalsProvider>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to='/welcome' replace />,
        },
        {
          path: '/in-development',
          element: <InDevelopment />,
        },
        {
          path: 'bridge',
          element: <Bridge />,
        },
        {
          path: 'docs',
          element: <Docs />,
        },
        {
          path: 'public',
          element: <Public />,
        },
        {
          path: 'welcome',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/home'
              allowedRoles={['unauthorized']}>
              <Welcome />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: 'auth',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/home'
              allowedRoles={['unauthorized']}>
              <Auth />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: 'home',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/auth'
              allowedRoles={['user', 'owner', 'employee', 'manager']}>
              <Home />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: 'user',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/home'
              allowedRoles={['user', 'owner', 'employee', 'manager']}>
              <User />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: 'org',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/home'
              allowedRoles={['owner', 'employee', 'manager']}>
              <Org />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: 'org/settings',
          element: (
            <SessionProtectedRouteWrapper
              rejectNavigatePath='/home'
              allowedRoles={['owner', 'manager']}>
              <OrgSettings />
            </SessionProtectedRouteWrapper>
          ),
        },
        {
          path: '*',
          element: (
            <Center h='100vh' w='100vw'>
              <Flex direction='column' align='center'>
                <MessagePanel
                  size={50}
                  icon={<Search size='100%' />}
                  title='Страница не найдена'
                  dscr=''
                />
                <NavLink
                  component={Link}
                  to='/'
                  label='Перейти на главную'
                  rightSection={<ChevronRight />}
                  style={{ borderRadius: 20, transition: '0.3 all' }}
                />
              </Flex>
            </Center>
          ),
        },
      ],
    },
  ]);

  return <ReactRouterProvider router={router} />;
};

export default RouterProvider;
