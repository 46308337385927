import { useUnit } from 'effector-react';

import {
  Box,
  Button,
  LoadingOverlay,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import {
  employeeDeleteOneOwnFx,
  employeeSwitchOneOwnFx,
} from 'entities/employee';
import {
  employeeCancelOneOwnFx,
  employeeLogoutOneOwnFx,
} from 'entities/employee/effects';
import { orgCancelOneOwnFx } from 'entities/org';
import { $currentSessionInfo } from 'entities/store';
import { $userOneOwn } from 'entities/user';
import { isEmpty } from 'ramda';
import { List as IconList, Plus as PlusIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { MessagePanel } from 'shared/ui/display';
import { OwnEmployeeSwitcherItem } from './own-employee-switcher-item';

type PropsType = {};

export const MyEmployeeSwitcher = ({}: PropsType) => {
  const {
    0: currentSessionInfo,
    1: employees,
    2: isLoadingSwitch,
    3: isLoadingLogout,
    4: isLoadingCancelEmployee,
    5: isLoadingCancelOrg,
    6: isLoadingDeleteEmployee,
  } = useUnit([
    $currentSessionInfo,
    $userOneOwn.map((s) => s?.userEmployees || []),
    employeeSwitchOneOwnFx.pending,
    employeeLogoutOneOwnFx.pending,
    employeeCancelOneOwnFx.pending,
    orgCancelOneOwnFx.pending,
    employeeDeleteOneOwnFx.pending,
  ]);

  const isLoading =
    isLoadingSwitch ||
    isLoadingLogout ||
    isLoadingCancelEmployee ||
    isLoadingCancelOrg ||
    isLoadingDeleteEmployee;

  const theme = useMantineTheme();
  const navigate = useNavigate();
  const handleClick = () => {
    return navigate(getHref({ '/user': { currentView: 'org-application' } }));
  };

  const selectedEmployee = employees.find(
    (item) => item.employeeId === currentSessionInfo?.employeeId,
  );
  const resultEmployees = employees.filter(
    (item) => item.employeeId !== currentSessionInfo?.employeeId,
  );
  if (selectedEmployee) resultEmployees.unshift(selectedEmployee);

  return (
    <Box
      pos='relative'
      style={{ overflow: 'hidden', borderRadius: 16 }}
      bg='white'>
      <LoadingOverlay visible={isLoading} />

      {isEmpty(employees) && (
        <Box p='md'>
          <MessagePanel
            icon={<IconList size='100%' />}
            title='У вас нет записей в организациях'
            dscr='Для прикрепления к организации попросите у вашего менеджера ссылку-приглашение'
          />
        </Box>
      )}

      <Stack pb={1} bg='white' style={{ overflow: 'hidden' }} gap={0}>
        {resultEmployees.map((item) => (
          <OwnEmployeeSwitcherItem
            key={item.employeeId}
            employee={item}
            isSelected={item.employeeId === currentSessionInfo?.employeeId}
          />
        ))}
      </Stack>

      <Button
        justify='left'
        radius={0}
        fullWidth
        h={52}
        fz={14}
        onClick={handleClick}
        leftSection={<PlusIcon />}
        variant='subtle'
        style={{ alignSelf: 'self-start', transition: '0.3s all' }}>
        Зарегистрировать организацию
      </Button>
      {/* <MyOrgCreateLinkButton /> */}
    </Box>
  );
};
