import { always, cond, equals, T } from 'ramda';

export const getTransactionStatus = (status: string) => {
  return cond([
    [equals('in_progress'), always('Чаевые в обработке')],
    [equals('completed'), always('Чаевые получены')],
    [equals('failed'), always('Операция отклонена')],
    [T, always('В обработке')],
  ])(status ?? '');
};
