import { Box } from '@mantine/core';
import useEmblaCarousel from 'embla-carousel-react';
import { always, ifElse, isNotEmpty, map } from 'ramda';
import { Fragment, ReactElement } from 'react';
import s from './carousel.module.css';

type PropsType = { children: ReactElement[] };

const Carousel = ({ children }: PropsType) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true });

  const items = ifElse(
    isNotEmpty,
    map((item: ReactElement) => <Box key={item.key}>{item}</Box>),
    always(<Fragment />),
  )(children);

  return (
    <Box>
      <div ref={emblaRef} className={s.embla}>
        <div className={s.embla__container}>{items}</div>
      </div>
    </Box>
  );
};

export default Carousel;
