import {
  Avatar,
  Divider,
  Flex,
  LoadingOverlay,
  NavLink,
  Stack,
  Text,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { CategoryItemsGroup } from 'entities/category';
import {
  $sharingRulesAllOwn,
  sharingRuleGetAllOwnFx,
} from 'entities/sharing-rule';
import { $currentSessionInfo } from 'entities/store';
import { $userOneOwn, getUserFioShort, userGetOneOwnFx } from 'entities/user';
import { SharingRulesGroupedList } from 'features/sharing-rule';
import { isEmpty, or } from 'ramda';
import { useEffect } from 'react';
import { CameraOff as IconCameraOff, List as IconList } from 'react-feather';
import { MessagePanel } from 'shared/ui/display';

const MyEmployeeSharingRules = () => {
  const {
    0: sharingRulesAllOwn,
    1: userOneOwn,
    2: currentSessionInfo,
    3: userIsLoading,
    4: rulesAreLoading,
  } = useUnit([
    $sharingRulesAllOwn,
    $userOneOwn,
    $currentSessionInfo,
    userGetOneOwnFx.pending,
    sharingRuleGetAllOwnFx.pending,
  ]);
  useEffect(() => {
    sharingRuleGetAllOwnFx();
  }, []);

  const activeEmployee = userOneOwn?.userEmployees?.find?.(
    (emp) => emp?.employeeId === currentSessionInfo?.employeeId,
  );

  return (
    <Stack>
      <LoadingOverlay visible={or(userIsLoading, rulesAreLoading)} />
      <NavLink
        disabled={true}
        opacity={1}
        color='gray'
        styles={{
          body: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          },
        }}
        label={<Text fw={500}>{or(getUserFioShort(userOneOwn), '-')}</Text>}
        description={
          <Flex direction='column'>
            <CategoryItemsGroup
              categoryItems={or(activeEmployee?.employeeCategoryItems, [])}
            />
          </Flex>
        }
        leftSection={
          <Avatar
            variant='transparent'
            size='lg'
            radius={1000}
            src={userOneOwn?.avatar}>
            <IconCameraOff size='50%' />
          </Avatar>
        }
      />

      <Divider />

      <Flex direction='column'>
        {!isEmpty(sharingRulesAllOwn) && (
          <SharingRulesGroupedList
            rules={sharingRulesAllOwn}
            withEditTools={false}
            // handleSelect={always}
            // handleClick={always}
          />
        )}

        {isEmpty(sharingRulesAllOwn) && (
          <MessagePanel
            icon={<IconList size='100%' />}
            title='Тут пусто...'
            dscr='У вашего профиля ещё нет правил рапределения чаевых'
          />
        )}
      </Flex>
    </Stack>
  );
};

export default MyEmployeeSharingRules;
