import { createStore } from 'effector';
import { appReset } from 'entities/events';
import {
  paymentRedirectLinkReset,
  withdrawalFormChanged,
} from 'entities/payment-system/events';
import * as ef from './effects';
import { getPayInEmpFx, getPayInOrgFx } from './effects';

export const $withdrawalForm = createStore<{
  withdrawalSumm?: number | null | undefined;
  email?: string | undefined;
  withdrawalType: 'card' | 'sbp';
}>({ withdrawalSumm: null, email: '', withdrawalType: 'sbp' }).on(
  withdrawalFormChanged,
  (state, payload) => {
    return { ...state, ...payload };
  },
);

export const $sbpPrechekPam = createStore<string | null>(null).on(
  ef.getSbpPrecheckExecuterFx.doneData,
  (_, payload) => {
    return payload.data;
  },
);

export const $paymentRedirectLink = createStore<string>('')
  .on(getPayInEmpFx.doneData, (state, payload) => payload.data)
  .on(getPayInOrgFx.doneData, (state, payload) => payload.data)
  .on(paymentRedirectLinkReset, () => '')
  .reset(appReset);
