import { DefaultMantineColor } from '@mantine/core';

export default function generateColorFromId(id: number | undefined) {
  const colors: DefaultMantineColor[] = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'indigo',
  ];
  if (typeof id === 'number') return colors[id % colors.length];
  else return 'gray';
}
