import {
  always,
  head,
  ifElse,
  is,
  join,
  keys,
  map,
  pipe,
  toPairs,
  values,
} from 'ramda';
import { PATHS } from 'shared/model/const';
import { OneOfType } from 'shared/model/types';

type Args = OneOfType<typeof PATHS>;
export const getHref = (req: Args): string => {
  const getFirstPropValue = pipe(values, head);
  const getFirstPropKey = pipe<Args[], string[], string>(keys, head);

  const pathname = getFirstPropKey(req);
  const query = getFirstPropValue(req);
  const getQueryStr = ifElse(
    is(Object),
    pipe(toPairs, map(join('=')), join('&')),
    always(''),
  );
  return pathname + '?' + getQueryStr(query);
};
