import {
  ActionIcon,
  Group,
  Indicator,
  Loader,
  Paper,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';
import { useUnit } from 'effector-react';
import { $$employeeManyOwnForActiveList } from 'entities/employee';

import {
  $orgSelectedCategoryItemIds,
  orgSelectedActiveEmployeesToggled,
} from 'entities/app-flows/org-flow';
import { categoryTitleGetAllOwnInOrgFx } from 'entities/category';
import { EmployeeEntityType } from 'entities/entities-types';
import { EmployeeSelectorList } from 'features/employee';
import { inc } from 'ramda';
import { useEffect, useState } from 'react';
import { Filter, Users as IconUsers, Search } from 'react-feather';
import { MessagePanel } from 'shared/ui/display';

type PropTypes = ContextModalProps<{
  employeeId: number;
  handleEmployeePick: (emp: EmployeeEntityType) => void;
  disabledEmployeeIds: number[];
}>;

const ActiveEmployeePickerModal = ({ context, id, innerProps }: PropTypes) => {
  const theme = useMantineTheme();
  const { handleEmployeePick, disabledEmployeeIds } = innerProps;
  const {
    0: employeeListActive,
    1: employeeListActiveTotalItems,
    2: employeeListActiveIsLoading,
    3: orgSelectedCategoryItemIds,
    4: lastRequestedCategoryItemIds,
  } = useUnit([
    $$employeeManyOwnForActiveList.$store.map((s) => s.res?.data?.items || []),
    $$employeeManyOwnForActiveList.$store.map((s) => s.res?.data?.totalItems),
    $$employeeManyOwnForActiveList.attachedFx.pending,
    $orgSelectedCategoryItemIds,
    $$employeeManyOwnForActiveList.$store.map(
      (s) => s.req.categoryItem?.ids || [],
    ),
  ]);

  const [filterModalCloseCount, setFilterModalCloseCount] = useState(0);

  const handleCloseModal = () =>
    setFilterModalCloseCount(inc(filterModalCloseCount));

  const handleClickFilter = () =>
    modals.openContextModal({
      modal: 'categoryItemsFilterModal',
      title: 'Фильтры',
      innerProps: {},
      onClose: handleCloseModal,
    });
  const handleClickItem = (emp: EmployeeEntityType) => {
    handleEmployeePick(emp);
    handleCloseModal();
    context.closeModal(id);
  };

  const fetchSideEffect = () => {
    $$employeeManyOwnForActiveList.argEditEvent({
      currentPage: 1,
      itemsPerPage: 100,
      employee: { status: ['approved'] },
      categoryItem: { ids: orgSelectedCategoryItemIds },
    });
  };

  useEffect(() => {
    if (
      lastRequestedCategoryItemIds.join('') !=
      orgSelectedCategoryItemIds.join('')
    )
      fetchSideEffect();
  }, [filterModalCloseCount]);

  useEffect(() => {
    fetchSideEffect();
    categoryTitleGetAllOwnInOrgFx();
  }, []);

  return (
    <Paper style={{ overflow: 'hidden' }} p='xs'>
      <Group justify='space-between' align='center' py='xs'>
        <TextInput flex={1} leftSection={<Search />} />
        <Indicator
          withBorder
          size='md'
          position='bottom-start'
          label={orgSelectedCategoryItemIds.length}
          disabled={orgSelectedCategoryItemIds.length < 1}>
          <ActionIcon
            variant='light'
            color={orgSelectedCategoryItemIds.length > 0 ? 'brand' : 'gray'}
            size='lg'
            onClick={handleClickFilter}>
            <Filter size='80%' />
          </ActionIcon>
        </Indicator>
      </Group>

      <EmployeeSelectorList
        employees={employeeListActive}
        disabledEmployeeIds={disabledEmployeeIds}
        isSelectorVisible={false}
        selectedEmployeeIds={[]}
        handleClick={handleClickItem}
        ownEmployeeId={innerProps.employeeId}
        handleSelect={orgSelectedActiveEmployeesToggled}
      />
      {typeof employeeListActiveTotalItems === 'number' &&
        employeeListActiveTotalItems < 1 && (
          <MessagePanel
            icon={<IconUsers size='100%' />}
            title='Записей нет'
            dscr='Пригласите сотрудников, чтобы они отображались в списке'
          />
        )}
      {employeeListActiveIsLoading && (
        <Stack align='center'>
          <Loader type='dots' size='xl' />
        </Stack>
      )}
    </Paper>
  );
};

export default ActiveEmployeePickerModal;
