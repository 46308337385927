import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';

import type { PropsWithChildren } from 'react';
import {
  ActiveEmployeePickerModal,
  CategoryItemPickerModal,
  CategoryItemPutModal,
  CategoryItemsFilterModal,
  CategoryTitlePutModal,
  ManageEmployeesModal,
  OrgDeclinedModal,
  OrgInvListModal,
  categoryWarningDeleteModal,
} from '../../../widgets/org-flow/modals';
import {
  EmploymentInfoModal,
  OrgInviteUseModal,
  SharingRulesNotificationModal,
} from '../../../widgets/user-flow/modals';

import { TransferPaymentModal } from '../../../widgets/guest-flow/modals';

import { useTree } from '@mantine/core';
import '@mantine/dates/styles.css';
import {
  BanksPickerModal,
  SbpConfirmWithdrawModal,
  SbpWithdrawalSuccessModal,
} from 'widgets/public-flow';
import { VisitDataModal } from '../../../widgets/user-flow/modals';

const ModalsProvider = ({ children }: PropsWithChildren) => {
  const {} = useTree();
  return (
    <MantineModalsProvider
      modals={{
        orgInvListModal: OrgInvListModal,
        manageEmployeesModal: ManageEmployeesModal,
        orgDeclinedModal: OrgDeclinedModal,
        categoryItemPutModal: CategoryItemPutModal,
        categoryWarningDeleteModal: categoryWarningDeleteModal,
        categoryTitlePutModal: CategoryTitlePutModal,
        employmentInfoModal: EmploymentInfoModal,
        orgInviteUseModal: OrgInviteUseModal,
        transferPaymentModal: TransferPaymentModal,
        sbpConfirmWithdrawModal: SbpConfirmWithdrawModal,
        categoryItemsFilterModal: CategoryItemsFilterModal,
        sbpWithdrawalSuccessModal: SbpWithdrawalSuccessModal,
        sharingRulesNotificationModal: SharingRulesNotificationModal,
        activeEmployeePickerModal: ActiveEmployeePickerModal,
        categoryPickerModal: CategoryItemPickerModal,
        banksPickerModal: BanksPickerModal,
        visitDataModal: VisitDataModal,
      }}>
      {children}
    </MantineModalsProvider>
  );
};
export default ModalsProvider;
