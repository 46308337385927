import { Stack, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { or } from 'ramda';
import { ReactNode } from 'react';

type PropsType = {
  icon: ReactNode;
  title?: string;
  dscr: string;
  size?: number;
};
const MessagePanel = ({ icon, dscr, title, size }: PropsType) => {
  const theme = useMantineTheme();
  return (
    <Stack align='center' gap={5}>
      <ThemeIcon variant='transparent' color='gray' size={or(size, 50)}>
        {icon}
      </ThemeIcon>
      <Text fz='lg' ta='center'>
        {title}
      </Text>
      <Text c='dimmed' ta='center'>
        {dscr}
      </Text>
    </Stack>
  );
};

export default MessagePanel;
