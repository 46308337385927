import {
  EmployeeEntityType,
  SharingRuleEntityType,
} from 'entities/entities-types';
import { __, filter, includes, path, pipe, pluck, sum } from 'ramda';

export const getEmployeeCategoryItemTotalSharePercentFromRules = (
  emp: EmployeeEntityType | undefined | null,
  allRules: SharingRuleEntityType[],
) => {
  if (!emp) return 0;
  const categoryItemIds =
    emp.employeeCategoryItems?.map((i) => i.categoryItemId) || [];

  const filtered = filter<SharingRuleEntityType>(
    pipe(
      path(['donor', 'categoryItem', 'categoryItemId']),
      Number,
      includes(__, categoryItemIds),
    ),
  )(allRules);

  const mapped = pluck('shareRatio', filtered);
  return Math.round(sum(mapped) * 100);
};
