import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { GoalEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';
import { formRules } from 'shared/lib/forms';
import { $$goalGetOne } from '../attaches';
import * as ef from '../effects';
type GoalFormType = Pick<GoalEntityType, 'goalSum' | 'goalTitle'>;

export const $formGoalChange = createForm<GoalFormType>({
  validateOn: ['change', 'blur'],
  fields: {
    goalSum: {
      init: 0,
      rules: [formRules.required(), formRules.minSum(0, 'Минимальная сумма')],
    },
    goalTitle: {
      init: '',
      rules: [formRules.required(), formRules.isEmpty()],
    },
  },
});

sample({
  clock: ef.goalGetOneFx.done,
  source: $$goalGetOne.$store.map((s) => s.res?.data),
  fn: (sourceData, clockData) => {
    return {
      goalTitle: clockData.result.data.goalTitle,
      goalSum: clockData.result.data.goalSum,
    };
  },
  target: $formGoalChange.setForm,
});

sample({
  clock: [ef.goalGetManyFx.done, appReset],
  target: [$formGoalChange.reset],
});
