import {
  Checkbox,
  Flex,
  Progress,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useUnit } from 'effector-react';
import { GoalEntityType } from 'entities/entities-types';
import { goalDeleteOneOwnFx } from 'entities/goal';
import { Check, ChevronRight, MoreHorizontal } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { CrudModalActionsBar } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';

type PropTypes = {
  goalData: GoalEntityType;
  bgColor?: string;
  userEdit?: boolean;
  isArray?: boolean;

  isChecked?: boolean;
  handleCheckClick?: (gid: number) => void;
  // isCompleted?: boolean;
};
const UserGoalBar = ({
  goalData,
  bgColor,
  userEdit,
  isArray,
  isChecked,
  handleCheckClick,
}: // isCompleted,
PropTypes) => {
  const navigate = useNavigate();
  const { 0: isLoadingDelete } = useUnit([goalDeleteOneOwnFx.pending]);
  const onConfirmDelete = () =>
    goalDeleteOneOwnFx({
      goalId: goalData?.goalId,
    });

  const isCompleted = goalData.goalSum === goalData.currentSum;

  const handleDeleteClick = () =>
    modals.openConfirmModal({
      title: 'Удалить цель',
      children: <Text>Вы уверены, что хотите удалить цель?</Text>,
      labels: {
        cancel: 'Нет',
        confirm: 'Да',
      },
      confirmProps: { size: 'sm', loading: isLoadingDelete },
      cancelProps: { color: 'brand', size: 'sm' },
      onCancel: () => console.log('Cancel'),
      onConfirm: onConfirmDelete,
    });

  const handleEditClick = () =>
    navigate(
      getHref({
        '/user': { currentView: 'my-goal-edit', gid: goalData?.goalId },
      }),
    );

  const { currentSum, goalSum: neededAmmount, goalTitle: title } = goalData;
  const currPercent = neededAmmount && (currentSum / neededAmmount) * 100;
  const remains = neededAmmount && currentSum && neededAmmount - currentSum;

  const theme = useMantineTheme();

  return (
    <Stack
      flex={1}
      gap='xs'
      py='xs'
      px='md'
      bg={bgColor ? bgColor : ''}
      c={!bgColor || isCompleted ? ' white ' : 'dark'}
      style={{ borderRadius: 12 }}
      justify='center'>
      <Flex justify='space-between'>
        <Flex align='center' gap='xs'>
          {Boolean(handleCheckClick && !isCompleted) && (
            <Checkbox
              checked={isChecked}
              onClick={() => {
                if (!handleCheckClick) return;
                handleCheckClick(goalData.goalId);
              }}
            />
          )}
          <Text>{title}</Text>
        </Flex>
        <Condition when={Boolean(isArray)}>
          <ChevronRight opacity={0.5} />
        </Condition>
        <Condition when={Boolean(userEdit && !isCompleted)}>
          <CrudModalActionsBar
            // color={isCompleted && 'white'}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}>
            <MoreHorizontal size='24px' color='gray' />
          </CrudModalActionsBar>
        </Condition>
      </Flex>

      <Condition when={Boolean(!isCompleted)}>
        <Stack gap={4}>
          <Text fz={12} c={bgColor ? 'gray' : 'brand.2'}>
            Осталось {remains?.toLocaleString?.('ru-RU')} ₽ из{' '}
            {neededAmmount?.toLocaleString?.('ru-RU')} ₽
          </Text>

          {currPercent !== null && (
            <Progress
              size='4px'
              bg='gray.3'
              value={currPercent}
              w='100%'
              color='green'
            />
          )}
        </Stack>
      </Condition>

      <Condition when={Boolean(isCompleted)}>
        <Flex align='center' gap={10}>
          <Text lang='' fw={600} fz={28} c='white'>
            {currentSum && currentSum.toLocaleString('fr')}
            <span style={{ fontSize: '16px', fontWeight: 400 }}>₽</span>
          </Text>

          <Flex p={5} style={{ borderRadius: '100px' }} bg='green'>
            <Check size='15px' color='white' />
          </Flex>
        </Flex>
      </Condition>
    </Stack>
  );
};

export default UserGoalBar;
