import { PaymentCardSBPType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api/types';

export const ENDPOINTS_SBP = {
  // PAYIN_CARD: {
  //   path: 'payer/get_payment_link',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     orgId: 3,
  //     email: 'redkokasha.d@gmail.com',
  //     amount: 1000,
  //     bill: 200,
  //     feedback: 'Превосходно, просто восхитительно!',
  //     grade: 5,
  //     redirectUrl:
  //       'https://chainik.fun/public?currentView=tip-payment-success&',
  //     isCommissionCompensated: true,
  //     failRedirectUrl: 'https://app.clickup.com/t/86bykcpr2',
  //   } as {
  //     employeeId?: number;
  //     userId?: number;
  //     orgId?: number;
  //     email: string | null;
  //     amount: number | null;
  //     bill: number;
  //     feedback: string | null;
  //     grade: FeedbackGradeValueType;
  //     redirectUrl: string;
  //     isCommissionCompensated: boolean;
  //     failRedirectUrl: string;
  //   },
  //   res: {} as TipsApiResType<string>,
  // },
  //
  // PAYOUT_CARD: {
  //   path: 'payer/payout_card',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     payoutWho: 'user',
  //     amount: 100,
  //     email: null,
  //     redirectUrl: 'https://chainik.fun/home?',
  //   } as {
  //     payoutWho: 'user' | 'org';
  //     amount: number;
  //     email?: string | null;
  //     redirectUrl: string;
  //   },
  //   res: {} as TipsApiResType<string>,
  // },

  // PAYOUT_SBP: {
  //   path: 'payer/payout_sbp',
  //   method: 'post' as HttpMethod,
  //   req: undefined as void,
  //   res: {} as TipsApiResType<{ status: string }>,
  // },
  //
  // GET_PAYMENT_SBP_LINK: {
  //   path: 'payer/get_payment_sbp_link',
  //   method: 'post' as HttpMethod,
  //   req: {} as {
  //     employeeId?: number;
  //     userId?: number;
  //     orgId?: number;
  //     email: string | null;
  //     amount: number | null;
  //     bill: number;
  //     feedback: string | null;
  //     grade: FeedbackGradeValueType;
  //     redirectUrl: string;
  //     isCommissionCompensated: boolean;
  //     failRedirectUrl: string;
  //   },
  //   res: {} as {
  //     data: string;
  //   },
  // },
  //
  // GET_ONE_COMMISSION: {
  //   path: 'payment/get_amount_with_commission',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     transactionType: 'enrollment',
  //     netValue: 1000,
  //     orgId: 12,
  //   } as {
  //     transactionType: 'withdrawal' | 'enrollment';
  //     netValue: number;
  //     orgId?: number;
  //     employeeId?: number;
  //   },
  //   res: {} as TipsApiResType<{ grossAmount: number }>,
  // },
  PAYOUT_SBP_CHECK: {
    path: 'payment/sbp/pre_check',
    method: 'post' as HttpMethod,
    req: {
      bankId: '1crt88888881',
      amount: 1000,
      phoneNumber: '79605656209',
    } as {
      bankId: string;
      amount: number;
      phoneNumber: string;
    },
    res: {} as TipsApiResType<string>,
  },

  PAYOUT_SBP_CHECK_ORG: {
    path: 'payment/sbp/pre_check_org',
    method: 'post' as HttpMethod,
    req: {
      bankId: '1crt88888881',
      amount: 1000,
      phoneNumber: '79605656209',
    } as {
      bankId: string;
      amount: number;
      phoneNumber: string;
    },
    res: {} as TipsApiResType<string>,
  },

  PAYMENT_PAY_IN_EMP: {
    path: 'payment/pay_in_emp',
    method: 'post' as HttpMethod,
    req: {} as PaymentCardSBPType,
    res: {} as {
      data: string;
    },
  },

  PAYMENT_PAY_IN_ORG: {
    path: 'payment/pay_in_org',
    method: 'post' as HttpMethod,
    req: {} as PaymentCardSBPType,
    res: {} as {
      data: string;
    },
  },

  PAYOUT_ORG: {
    path: 'payment/pay_out_org',
    method: 'post' as HttpMethod,
    req: {} as {
      amount: number;
      bankId: string;
      email: string | null;
      failRedirectUrl: string;
      paymentMethod: 'CARD' | 'SBP';
      phoneNumber: string;
      redirectUrl: string;
    },
    res: {} as TipsApiResType<{ form: string; withForm: boolean }>,
  },

  PAYOUT: {
    path: 'payment/pay_out',
    method: 'post' as HttpMethod,
    req: {} as {
      amount: number;
      bankId: string;
      email: string | null;
      failRedirectUrl: string;
      paymentMethod: 'CARD' | 'SBP';
      phoneNumber: string;
      redirectUrl: string;
    },
    res: {} as TipsApiResType<{ form: string; withForm: boolean }>,
  },

  GET_ONE_COMMISSION: {
    path: 'payment/get_amount_with_commission',
    method: 'post' as HttpMethod,
    req: {
      transactionType: 'payout',
      netAmount: 1000,
      orgId: 12,
    } as
      | {
          transactionType: 'payout' | 'payin';
          netAmount: number;
          employeeId: number;
        }
      | {
          transactionType: 'payout' | 'payin';
          netAmount: number;
          orgId: number;
        },
    res: {} as TipsApiResType<{ grossAmount: number }>,
  },
};
