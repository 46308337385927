import {
  NavLink,
  NavLinkProps,
  getThemeColor,
  useMantineTheme,
} from '@mantine/core';
import { useRef } from 'react';
import style from './style.module.css';

type PropsType = NavLinkProps & {
  onClick?: (() => void) | undefined;
};

export const NavLinkBorder = ({
  onClick,
  label,
  description,
  leftSection,
  rightSection,
  ...rest
}: PropsType) => {
  const theme = useMantineTheme();
  const themeColor = getThemeColor('gray', theme);
  const refInput = useRef<HTMLInputElement>(null);

  return (
    <NavLink
      onClick={onClick}
      h={70}
      classNames={{
        body: style.body,
        label: style.label,
        root: style.root,
        section: style.section,
      }}
      label={label}
      description={description}
      leftSection={leftSection}
      rightSection={rightSection}
      {...rest}
    />
  );
};
