import {
  ActionIcon,
  Chip,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useUnit } from 'effector-react';
import { $orgSelectedCategoryItemIds } from 'entities/app-flows/org-flow';
import {
  $categoryTitlesAllCurrent,
  categoryTitleGetAllOwnInOrgFx,
} from 'entities/category';
import {
  CategoryItemEntityType,
  CategoryTitleEntityType,
} from 'entities/entities-types';

import {
  equals,
  filter,
  includes,
  length,
  lt,
  pathSatisfies,
  pipe,
} from 'ramda';
import { List as IconList, Settings as SettingsIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { generateColorFromId } from 'shared/lib/generators';
import { Condition, MessagePanel } from 'shared/ui/display';

type PropTypes = ContextModalProps<{
  categoryTitles: CategoryTitleEntityType[];
  disabledCatItemIds: number[];
  handleCatItemPick: (catItem: CategoryItemEntityType) => void;
}>;

const CategoryItemPickerModal = ({ context, id, innerProps }: PropTypes) => {
  const { categoryTitles, handleCatItemPick, disabledCatItemIds } = innerProps;
  const theme = useMantineTheme();

  const {
    0: orgSelectedCategoryItemIds,
    1: categoryTitlesAllCurrent,
    2: categoryTitlesAllCurrentIsLoading,
  } = useUnit([
    $orgSelectedCategoryItemIds,
    $categoryTitlesAllCurrent,
    categoryTitleGetAllOwnInOrgFx.pending,
  ]);
  const handleCloseModal = () => context.closeModal(id);
  const handleClickItem = (catItem: CategoryItemEntityType) => {
    handleCatItemPick(catItem);
    handleCloseModal();
  };

  const getFilteredItems = filter(
    pipe(pathSatisfies(lt(0), ['categoryTitleItems', 'length'])),
  );
  const isArrayEmpty = pipe(length, equals(0));

  return (
    <Stack>
      <LoadingOverlay visible={categoryTitlesAllCurrentIsLoading} />
      <Divider />
      {getFilteredItems(categoryTitlesAllCurrent).map((title) => (
        <Stack key={title.categoryTitleId}>
          <Title order={4}>{title.name}</Title>
          <Group>
            {title?.categoryTitleItems?.map?.((item) => (
              <Chip
                variant='light'
                key={item.categoryItemId}
                // color={generateColorFromId(item.categoryItemId)}
                color={generateColorFromId(item.categoryTitleId)}
                disabled={includes(item.categoryItemId, disabledCatItemIds)}
                onChange={() => handleClickItem(item)}>
                {item.name}
              </Chip>
            ))}
          </Group>
        </Stack>
      ))}

      <Condition
        when={isArrayEmpty(getFilteredItems(categoryTitlesAllCurrent))}>
        <MessagePanel
          icon={<IconList size='100%' />}
          title='Нет категорий'
          dscr='Добавьте категории и пункты категорий в соответствующем меню, чтобы тут появились пункты филтрации'
        />
      </Condition>

      <Divider />

      <Group align='center'>
        <Text style={{ flex: 1 }} fz='sm' ta='end' c='dimmed'>
          {'Подсказка: сотрудника можно прикрепить' +
            ' не\xa0более, чем\xa0к\xa0одному пункту категории'}
        </Text>
        <ActionIcon
          component={Link}
          to={getHref({
            '/org/settings': { currentView: 'category-management' },
          })}
          size='xl'
          color='gray'
          variant='light'
          onClick={() => {
            context.closeModal(id);
          }}>
          <SettingsIcon />
        </ActionIcon>
      </Group>
    </Stack>
  );
};

export default CategoryItemPickerModal;
