import {
  ActionIcon,
  Avatar,
  Group,
  NavLink,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useUnit } from 'effector-react';
import { SharingRuleEntityType } from 'entities/entities-types';
import {
  getSharingRuleUiParams,
  sharingRuleDeleteManyOwnFx,
} from 'entities/sharing-rule';
import {
  ShareRatioProgress,
  getEmployeeCategoryItemTotalSharePercentFromRules,
} from 'features/sharing-rule';
import { concat, cond, groupBy, is, path, pipe } from 'ramda';
import { ChevronRight, Trash as TrashIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MyPaper } from 'shared/ui/display';

type PropsType = {
  rules: SharingRuleEntityType[];
  withEditTools: boolean;
  handleDeleteGroup?: (sharingRuleIds: number[]) => void;
  handleEditGroup?: (donor: SharingRuleEntityType['donor']) => void;

  // handleClick: (srid: number) => void;
  // handleSelect: (srid: number) => void;
};

const SharingRulesGroupedList = ({
  rules,
  withEditTools,
  handleEditGroup,
}: // handleSelect,
// handleClick,
PropsType) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { 0: deleteLoading } = useUnit([sharingRuleDeleteManyOwnFx.pending]);
  const handleDeleteGroup = (sharingRuleIds: number[]) => {
    sharingRuleDeleteManyOwnFx({ sharingRuleIds });
  };

  const groupedRules = groupBy<SharingRuleEntityType, string>(
    cond([
      [
        pipe(path(['donor', 'org', 'orgId']), is(Number)),
        pipe(path(['donor', 'org', 'orgId']), String, concat('donorOrgId_')),
      ],
      [
        pipe(path(['donor', 'employee', 'employeeId']), is(Number)),
        pipe(
          path(['donor', 'employee', 'employeeId']),
          String,
          concat('donorEmployeeId_'),
        ),
      ],
      [
        pipe(path(['donor', 'categoryItem', 'categoryItemId']), is(Number)),
        pipe(
          path(['donor', 'categoryItem', 'categoryItemId']),
          String,
          concat('donorCategoryItemId_'),
        ),
      ],
    ]),
  );

  return (
    <Stack>
      {Object.keys(groupedRules(rules)).map((key) => {
        const items = groupedRules(rules)[key];
        const totalShareRatio =
          items?.reduce?.((acc, ruleItem) => {
            if (typeof ruleItem?.shareRatio !== 'number') return acc;
            return acc + Math.round(ruleItem.shareRatio * 100);
          }, 0) || 0;
        const donor = items?.[0].donor;
        const donorUi = getSharingRuleUiParams(items?.[0], 'donor');
        const groupSharingRuleIds = items?.map((item) => item.ruleId) || [];
        const maxShareRatio = 0;
        const notShareRation = 100 - totalShareRatio - maxShareRatio;

        const handleDeleteClick = () => {
          modals.openConfirmModal({
            title: 'Подтвердите действие',
            children: (
              <Text>
                Вы уверены, что хотите удалить группу правил распределения (
                {groupSharingRuleIds.length} шт.)?
              </Text>
            ),
            labels: {
              confirm: t('SHARED.commonVerbs.confirm'),
              cancel: t('SHARED.commonVerbs.cancel'),
            },
            confirmProps: {
              color: 'red',
              size: 'sm',
              loading: deleteLoading,
            },
            cancelProps: { size: 'sm' },
            onConfirm: () => handleDeleteGroup(groupSharingRuleIds),
          });
        };

        return (
          <MyPaper key={key}>
            <Group wrap='nowrap' justify='space-between'>
              <NavLink
                onClick={() => {
                  if (!donor) return;
                  handleEditGroup?.(donor);
                }}
                p={0}
                w='auto'
                style={{
                  borderRadius: 10,
                  transition: '0.3s all',
                }}
                label={donorUi.name}
                description={donorUi.entity}
                rightSection={<ChevronRight />}
                leftSection={
                  <Avatar
                    variant='transparent'
                    bg='white'
                    color='brand'
                    src={donorUi.avatar}>
                    {donorUi.avatarPlaceholder}
                  </Avatar>
                }
              />
              {withEditTools && (
                <ActionIcon
                  size='lg'
                  variant='light'
                  radius={1000}
                  onClick={handleDeleteClick}>
                  <TrashIcon size='66%' />
                </ActionIcon>
              )}
            </Group>
            <ShareRatioProgress
              withText={true}
              currentSharePercent={totalShareRatio}
              categorySharePercent={getEmployeeCategoryItemTotalSharePercentFromRules(
                donor?.employee,
                rules,
              )}
            />
          </MyPaper>
        );
      })}
    </Stack>
  );
};

export default SharingRulesGroupedList;
