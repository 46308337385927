import { createStore } from 'effector';
import { BalanceEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';
import * as ef from './effects';

export const $balanceOneOwnOfUser = createStore<BalanceEntityType | null>(null)
  .on(ef.balanceGetOneOwnOfUserFx.doneData, (_, payload) => {
    return payload.data;
  })
  .reset(appReset);

export const $balanceOneOwnOfOrg = createStore<BalanceEntityType | null>(null)
  .on(ef.balanceGetOneOwnOfOrgFx.doneData, (_, payload) => {
    return payload.data;
  })
  .reset(appReset);
