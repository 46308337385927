import {
  Avatar,
  Box,
  Collapse,
  Flex,
  Group,
  Stack,
  Text,
  getThemeColor,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  TransactionEntityType,
  VisitEntityType,
} from 'entities/entities-types';
import { useGetFeedbackUiParamsByGrade } from 'entities/feedback';
import { getPic } from 'entities/files';
import { useGetTransactionUiParams } from 'entities/transaction';
import { getUserFioShort } from 'entities/user';
import { always, equals, ifElse, or } from 'ramda';
import { useState } from 'react';
import { Clock as IconClock } from 'react-feather';
import { roundNumber } from 'shared/lib/formatters';
import { visitTransactionStatusColor } from 'shared/lib/generators';
import { IconCoins } from 'shared/model';

import {
  IconBill as BillIcon,
  IconArrow,
  MessageCircleIcon,
} from 'shared/model/icons';
import { IconFallbackOrg, IconFallbackUser } from 'shared/model/vector-images';
import { PressableBox } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';
//
// jibun - (яп. 自分) 1. сам; ~no свой; личный, собственный
// используем у себя для обозначения собственного визита
//
// gaijin - (яп. 外人) — сокращение японского слова гайкокудзин (яп. 外国人), переводящееся как «иностранец».
// используем у себя для обозначения чужого визита, который будет отображатться с лицом и именем получателя
//
type VariantType = 'jibun' | 'gaijin';
type PropsType = {
  variant: VariantType;
  visitsOrTransaction: VisitEntityType | TransactionEntityType;
};

export const VisitBar = ({ visitsOrTransaction, variant }: PropsType) => {
  const theme = useMantineTheme();
  const brandColor = getThemeColor('brand', theme);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const getFeedbackUiParams = useGetFeedbackUiParamsByGrade();
  const getTransUiParams = useGetTransactionUiParams();

  const visit = visitsOrTransaction as VisitEntityType;
  const transaction = visitsOrTransaction as TransactionEntityType;

  const toggleIsCommentOpen = () => setIsCommentOpen((prev) => !prev);

  const visitTransaction = visit?.visitTransaction;
  const isInProgressTransaction = [
    'received',
    'in_progress',
    undefined,
  ].includes(transaction?.status);

  const isInProgress = ['received', 'in_progress', undefined].includes(
    visitTransaction?.status,
  );

  const grade = visit?.visitFeedback?.grade;
  const message = visit?.visitFeedback?.message;
  const billAmount = visit?.bill ? `${visit?.bill} ₽` : ' - ₽';
  const totalCommissionAmount = roundNumber(
    visitTransaction?.totalCommission || 0,
    2,
  );
  const netAmount = roundNumber(visitTransaction?.netAmount || 0, 2);
  const grossAmount = roundNumber(visitTransaction?.grossAmount || 0, 2);
  const isCommissionCompensated = visitTransaction?.isCommissionCompensated;
  // console.log(visit);

  const getBarParams = ifElse(
    equals('gaijin'),
    always({
      topText: (
        <Text fz='md' fw={500} component={Group} gap={6} align='center'>
          {or(
            getUserFioShort(visit.visitEmployee?.employeeUser),
            visit.visitOrg?.name,
          )}
          {Boolean(message) && (
            <MessageCircleIcon width={16} height={16} color='orange' />
          )}
          <Condition when={!visit.visitId}>
            {Boolean(isInProgressTransaction) && (
              <IconClock width={16} height={16} color='orange' />
            )}
          </Condition>
          <Condition when={Boolean(visit.visitId)}>
            {Boolean(isInProgress) && (
              <IconClock width={16} height={16} color='orange' />
            )}
          </Condition>
        </Text>
      ),
      bottomText: (
        <>
          <Condition when={!visit.visitId}>
            <Text>Без комиссии</Text>
          </Condition>
          <Condition when={Boolean(visit.visitId)}>
            <Text c={getFeedbackUiParams(grade).textColor}>
              {getFeedbackUiParams(grade).text}
            </Text>
          </Condition>
        </>
      ),

      avatar: (
        <Avatar
          variant='transparent'
          size={45}
          radius={1000}
          src={getPic(
            or(
              visit?.visitEmployee?.employeeUser?.avatar,
              visit.visitOrg?.avatar,
            ) as string,
          )}>
          {visit?.visitOrg?.orgId ? <IconFallbackOrg /> : <IconFallbackUser />}
        </Avatar>
      ),
    }),
    always({
      topText: (
        <Text fz='md' fw={600} component={Group} gap={6} align='center'>
          <Condition when={!visit.visitId}>Перевод</Condition>
          <Condition when={Boolean(visit.visitId)}>Чаевые</Condition>
          {Boolean(message) && (
            <MessageCircleIcon width={16} height={16} color='orange' />
          )}

          <Condition when={!visit.visitId}>
            {' '}
            {Boolean(isInProgressTransaction) && (
              <IconClock width={16} height={16} color='orange' />
            )}
          </Condition>
          <Condition when={Boolean(visit.visitId)}>
            {' '}
            {Boolean(isInProgress) && (
              <IconClock width={16} height={16} color='orange' />
            )}
          </Condition>
        </Text>
      ),
      bottomText: (
        <>
          <Condition when={!visit.visitId}>
            <Text c='dimmed'>Комиссия 0 ₽</Text>
          </Condition>
          <Condition when={Boolean(visit.visitId)}>
            <Text c={getFeedbackUiParams(grade).textColor}>
              {getFeedbackUiParams(grade).text}
            </Text>
          </Condition>
        </>
      ),
      avatar: (
        <>
          <Condition when={Boolean(visit.visitId)}>
            <Avatar size={45} radius={1000} color='gray'>
              <IconCoins />
            </Avatar>
          </Condition>
          <Condition when={!Boolean(visit.visitId)}>
            <Avatar size={45} radius={1000}>
              <IconArrow height={20} width={20} />
            </Avatar>
          </Condition>
        </>
      ),
    }),
  );

  return (
    <PressableBox
      onClick={() =>
        modals.openContextModal({
          modal: 'visitDataModal',
          title: '',
          innerProps: {
            visit: visit,
            isOwnVisit: variant === 'jibun',
          },
        })
      }
      hidePassiveFill
      noActive={!Boolean(visit.visitId)}
      color={theme.colors.brand[1]}>
      <Stack
        p='12px 16px'
        // pt={8}
        // pb={8}
        bg='white'
        style={{ borderRadius: theme.fontSizes.md }}>
        <Flex
          align='center'
          gap='md'
          justify='space-between'
          onClick={toggleIsCommentOpen}>
          <Flex gap='sm' align='center'>
            {getBarParams(variant).avatar}
            <Stack gap={0} justify='center'>
              {getBarParams(variant).topText}
              {getBarParams(variant).bottomText}
            </Stack>
          </Flex>
          <Stack gap={0} align='end'>
            <Flex align='center' gap='xs'>
              <Condition when={Boolean(visit.visitId)}>
                <Text align='end' fz='md' component={Stack} gap={0}>
                  <Text
                    fw={500}
                    fz='md'
                    c={visitTransactionStatusColor(visitTransaction?.status)}>
                    {visitTransaction?.status !== 'failed' && '+'}{' '}
                    {` ${netAmount} ₽`}
                  </Text>

                  <Text fz='sm' c='dimmed'>
                    <Condition when={Boolean(visit.bill)}>
                      <Flex align='end' gap={4}>
                        {billAmount}
                        <BillIcon width={20} height={20} />
                      </Flex>
                    </Condition>
                  </Text>
                </Text>
              </Condition>
              <Condition when={!visit.visitId}>
                <Text align='end' fz='md' component={Stack} gap={0}>
                  <Text
                    fw={500}
                    fz='md'>{`- ${transaction.grossAmount} ₽`}</Text>
                </Text>
              </Condition>
              {/* <Condition when={Boolean(visit.visitId)}> */}
              {/*  <ActionIcon variant="light" radius={1000}>*/}
              {/*    <ChevronDownIcon*/}
              {/*      size="80%"*/}
              {/*      style={{*/}
              {/*        transition: '0.3s',*/}
              {/*        transform: isCommentOpen ? 'rotate(0.5turn)' : '',*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </ActionIcon>*/}
              {/* </Condition> */}
            </Flex>
          </Stack>
        </Flex>
        <Collapse in={isCommentOpen}>
          <Stack gap='xs'>
            <Box
              style={{
                borderLeft: `2px ${theme.colors.gray[2]} solid`,
                paddingLeft: theme.fontSizes.sm,
              }}>
              <Text
                c={getTransUiParams(visitTransaction?.status).statusTextColor}>
                {getTransUiParams(visitTransaction?.status).statusText}
              </Text>
              <Condition when={!isCommissionCompensated}>
                <Condition when={Boolean(visit.visitId)}>
                  <Text
                    fz='md'
                    c='dimmed'
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{`Сумма перевода: ${grossAmount} ₽`}</Text>
                    <Text>{`Комиссия: ${totalCommissionAmount} ₽`}</Text>
                  </Text>
                </Condition>
                <Condition when={!visit.visitId}>
                  <Text
                    fz='md'
                    c='dimmed'
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{`Сумма перевода: ${transaction.grossAmount} ₽`}</Text>
                    <Text>{`Комиссия: ${transaction.totalCommissionAmount} ₽`}</Text>
                  </Text>
                </Condition>
              </Condition>
              <Condition when={Boolean(isCommissionCompensated)}>
                <Text c='green.2'>Комиссия оплачена гостем!</Text>
              </Condition>
            </Box>
            <Condition when={Boolean(message)}>
              <Box
                pl='sm'
                style={{
                  borderLeft: `2px ${getThemeColor('orange', theme)} solid`,
                }}>
                <Text c='dimmed'>Отзыв:</Text>
                <Text style={{ whiteSpace: 'break-spaces' }}>{message}</Text>
              </Box>
            </Condition>
          </Stack>
        </Collapse>
      </Stack>
    </PressableBox>
  );
};
