import { createEvent, createStore } from 'effector';
import { ReactNode } from 'react';

type FooterStateType = {
  isVisibleDoc: boolean;
  content: ReactNode | null;
};
export const footerEdited = createEvent<Partial<FooterStateType>>();
export const footerReset = createEvent();

export const $footer = createStore<FooterStateType>({
  isVisibleDoc: false,
  content: null,
})
  .on(footerEdited, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .reset(footerReset);
