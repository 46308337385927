import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_EMPLOYEE } from 'entities/employee/endpoints';

export const employeeLogoutOneOwnFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.LOGOUT_ONE_OWN.req,
  typeof ENDPOINTS_EMPLOYEE.LOGOUT_ONE_OWN.res
>({
  fxName: 'employeeLogoutOwnFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.LOGOUT_ONE_OWN,
});

export const employeeGetOneFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.GET_ONE.req,
  typeof ENDPOINTS_EMPLOYEE.GET_ONE.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.GET_ONE,
});

export const employeeDeleteOneOwnFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.DELETE_ONE_OWN.req,
  typeof ENDPOINTS_EMPLOYEE.DELETE_ONE_OWN.res
>({
  fxName: 'employeeDeleteOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.DELETE_ONE_OWN,
});

export const employeeCancelOneOwnFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.CANCEL_ONE_OWN.req,
  typeof ENDPOINTS_EMPLOYEE.CANCEL_ONE_OWN.res
>({
  fxName: 'employeeCancelOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.CANCEL_ONE_OWN,
});

export const employeeSwitchOneOwnFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.SWITCH_ONE_OWN.req,
  typeof ENDPOINTS_EMPLOYEE.SWITCH_ONE_OWN.res
>({
  fxName: 'employeeSwitchOneFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.SWITCH_ONE_OWN,
});

export const employeeEditManyFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.EDIT_MANY.req,
  typeof ENDPOINTS_EMPLOYEE.EDIT_MANY.res
>({
  fxName: 'employeeEditManyFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.EDIT_MANY,
});

export const employeeGetManyOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.req,
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG,
});
