import { Accordion, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { IconThinPlus } from 'shared/model/icons';
import styles from './style.module.css';
export const Questions = () => {
  const theme = useMantineTheme();

  const allQuestions = [
    {
      id: 0,
      title: 'Какие способы оплаты существуют?',
      description:
        'Для оплаты на платформе доступны самые удобные методы — Система быстрых платежей (СБП) или оплата с использованием данных карты.',
    },
    {
      id: 1,
      title: 'Сохраняют ли данные моей карты?',
      description:
        'Вы можете самостоятельно решить, сохранять карту для последующих оплат или нет, поставив или убрав галочку на странице оплаты. Все данные хранятся в обезличенном виде.',
    },
    {
      id: 2,
      title: 'Где разместить QR-код ?',
      description: `Наиболее эффективное место для размещения QR-кода – нижняя часть чека. Также рекомендуется использовать печатные материалы, такие как тейбл-тенты, наклейки и флаеры.`,
    },
    {
      id: 3,
      title: 'Как можно распределять чаевые в заведении?',
      description: `Чаевые могут быть общими, когда все средства идут на аккаунт команды и делятся в конце смены, или индивидуальными, когда каждый сотрудник получает чаевые на свой личный аккаунт.
Также вы можете настроить распределение чаевых: с каждой полученной транзакции чаевых официанту небольшой процент будет уходить на аккаунт команды барменов, кухни или др.`,
    },
    {
      id: 4,
      title: 'Облагаются ли налогом чаевые?',
      description:
        'Нет. Физические и онлайн чаевые не облагаются налогом в соответствии с п. 18.1 ст. 217 Налогового кодекса Российской Федерации.',
    },
    //     {
    //       id: 5,
    //       title: 'Какие есть лимиты ?',
    //       description: `1. Вы сможете хранить больше 15.000р на балансе «Чайник» после привязки банковской карты.
    // 2. Лимит для хранения на балансе «Чайник». Выведите часть средств, как только достигнете лимита в 60.000р.
    // 3. Максимальный оборот средств для одной виртуальной карты «Чайник». Как только вы его достигните, обратитесь в нашу техническую поддержку, и мы сделаем новую.`,
    //     },
  ];

  return (
    <Flex
      mb={{ sm: 0, base: -25 }}
      direction='column'
      gap={{ base: 24, sm: 64 }}
      id='questions'>
      <Title ta='center' fz={{ base: 28, md: 56 }}>
        Частые вопросы
      </Title>

      <Accordion
        styles={{ chevron: { width: '24px' } }}
        chevron={<IconThinPlus className={styles.icon} />}
        classNames={{ chevron: styles.chevron }}
        transitionDuration={300}
        defaultValue='Apples'>
        {allQuestions.map((item) => (
          <Accordion.Item key={item.id} value={item.title}>
            <Accordion.Control
              pb={{ sm: 24, base: 12 }}
              fz={{ base: 16, sm: 24 }}
              styles={{ label: { fontWeight: 600 } }}>
              {item.title}
            </Accordion.Control>
            <Accordion.Panel mt={0} fz={{ base: 14, sm: 20 }} c='gray'>
              <Text style={{ whiteSpace: 'pre-wrap' }}>{item.description}</Text>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Flex>
  );
};
