import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { orgInvitationGetOneFx } from 'entities/org-invitation/effects';
import { ENDPOINTS_ORG_INVITATION } from 'entities/org-invitation/endpoints';

export const $$orgInvOneCurrent = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ORG_INVITATION.GET_ONE.req,
  typeof ENDPOINTS_ORG_INVITATION.GET_ONE.res
>({
  initialReq: { code: '' },
  initialFx: orgInvitationGetOneFx,
});
