import { createForm } from 'effector-forms';
import { formRules } from 'shared/lib/forms';
import { ENDPOINTS_GOAL } from '../endpoints';

export const $formGoal = createForm<typeof ENDPOINTS_GOAL.CREATE_ONE_OWN.req>({
  validateOn: ['submit', 'blur'],
  fields: {
    goalSum: {
      init: 0,
      rules: [formRules.required(), formRules.minSum(1, 'Минимальная сумма')],
    },
    goalTitle: {
      init: '',
      rules: [formRules.required(), formRules.isEmpty()],
    },
    initSum: {
      init: 0,
      rules: [formRules.minSum(0, 'Минимальная сумма')],
    },
  },
});
