import { propOr } from 'ramda';
import { useEffect } from 'react';

import { Effect } from 'effector';

type ParamsType = {
  fn: Effect<any, any, any>;
  args?: any;
  deps?: any[];
  condition?: () => boolean;
};

export const useQuery = (funcSignature: ParamsType) => {
  const fn = () => {
    const conditionFn: () => boolean = propOr(
      () => true,
      'condition',
      funcSignature,
    );
    if (conditionFn()) {
      funcSignature?.fn?.(funcSignature.args);
    }
  };
  useEffect(fn, propOr([], 'deps', funcSignature));

  return { retry: fn };
};
