import { Notification, Stack } from '@mantine/core';
// import { Check as IconCheck, X as IconX } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ButtonWithConfirm } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';

type PropsType = {
  isOwner: boolean;
  onConfirmDelete: () => void;
  onConfirmCloseOrg: () => void;
};

const ApprovedView = ({
  isOwner,
  onConfirmDelete,
  onConfirmCloseOrg,
}: PropsType) => {
  const { t } = useTranslation();
  return (
    <Stack style={{ overflow: 'hidden' }}>
      <Notification withCloseButton={false}>
        Эта ваша активная запись в организации
      </Notification>

      <Condition when={isOwner}>
        <Notification withCloseButton={false} color='yellow'>
          Вы являетесь владельцем данной организации
        </Notification>
        {/* <Button>
       Закрыть организацию (на самом деле подать заявку)
       При подаче заявки предупреждать, что все сотрудники удалятся и прочее
       </Button> */}
      </Condition>

      <Condition when={!isOwner}>
        <ButtonWithConfirm
          onConfirmCallback={onConfirmDelete}
          confirmColor='red'
          buttonText='Удалить запись'
          buttonSize='sm'
        />
      </Condition>
    </Stack>
  );
};
export default ApprovedView;
