import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { employeeGetManyOwnInOrgFx } from 'entities/employee/effects';
import { ENDPOINTS_EMPLOYEE } from 'entities/employee/endpoints';

export const $$employeeAvatarGroupWithCount = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.req,
  typeof ENDPOINTS_EMPLOYEE.GET_MANY_OWN_IN_ORG.res
>({
  initialReq: { currentPage: 1, itemsPerPage: 0 },
  initialFx: employeeGetManyOwnInOrgFx,
});
