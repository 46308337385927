import dayjs from 'dayjs';
import { groupBy } from 'ramda';

type PropsType = {
  array: Array<any> | undefined;
  dateAttributeName: string;
};
//
type ResultType = { [key: string]: Array<any> };

export function groupArrByDate({ array, dateAttributeName }: PropsType) {
  if (!array) return {};
  return groupBy((item: any) => {
    const timestamp = item?.[dateAttributeName];
    if (!timestamp && timestamp?.getMonth === 'function') return '';
    return dayjs(timestamp).format('YYYY-MM-DD');
  })(array);
}
