import { createEvent } from 'effector';

export const orgSelectedActiveEmployeesToggled = createEvent<number>();
export const orgSelectedActiveEmployeesReplaced = createEvent<number[]>();
export const orgSelectedEmployeeAppsToggled = createEvent<number>();
export const orgSelectedEmployeeAppsReplaced = createEvent<number[]>();
export const orgSelectedCategoryItemIdsToggled = createEvent<number>();

export const orgSelectedCategoryItemIdsReplaced = createEvent<number[]>();
export const orgSelectedPositionIdsChange = createEvent<{
  [key: string]: number;
}>();
export const orgEmployeesSelectorVisibleChange = createEvent<boolean>();
