import { Button, Stack, Text, Title } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { PiggyBankIcon } from 'shared/model';

type PropType = ContextModalProps<{ withdrawalSum: number }>;

const SbpWithdrawalSuccessModal = ({ context, id, innerProps }: PropType) => {
  const navigate = useNavigate();

  return (
    <Stack align='center'>
      <PiggyBankIcon />
      <Title order={3}>
        Вы вывели {innerProps.withdrawalSum?.toLocaleString() ?? 0} ₽
      </Title>
      <Text ta='center' c='dimmed'>
        Деньги скоро придут на карту. Обычно это занимает несколько минут, в
        редких случаях до двух дней
      </Text>
      <Button
        fullWidth
        onClick={() => {
          context.closeModal(id);
          navigate('/home');
        }}>
        Отлично!
      </Button>
    </Stack>
  );
};

export default SbpWithdrawalSuccessModal;
