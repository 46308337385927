import { createStore, sample } from 'effector';
import { OrgEntityType } from 'entities/entities-types';
import {
  appReset,
  orgOneOwnActiveChanged,
  userOneOwnInvalidated,
  userOneOwnReceived,
} from 'entities/events';
import { $currentSessionInfo } from 'entities/store';
import {
  orgCancelOneOwnFx,
  orgChangeOneOwnAvatarFx,
  orgCreateOneOwnProposedFx,
  orgDenyOneOwnFx,
  orgEditOneOwnFx,
} from './effects';

sample({
  clock: [
    orgCancelOneOwnFx.done,
    orgCreateOneOwnProposedFx.done,
    orgChangeOneOwnAvatarFx.done,
    orgDenyOneOwnFx.done,
    orgEditOneOwnFx.done,
  ],
  target: userOneOwnInvalidated,
});

sample({
  clock: userOneOwnReceived,
  source: $currentSessionInfo,
  fn: (sourceData, clockData) =>
    clockData?.userEmployees?.find?.(
      (emp) => emp?.employeeId === sourceData?.employeeId,
    )?.employeeOrg,
  target: orgOneOwnActiveChanged,
});

export const $orgOneOwnActive = createStore<OrgEntityType | null>(null)
  .on(orgOneOwnActiveChanged, (state, payload) => payload)
  .reset(appReset);
