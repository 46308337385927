import { Button, Stack, Text, Title } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconWarning } from 'shared/model/icons';

type PropsType = ContextModalProps<{
  text: string;
  name: string;
  titleName: string;
}>;

export function categoryWarningDeleteModal({
  context,
  id,
  innerProps,
}: PropsType) {
  const onSuccess = () => {
    context.closeModal(id);
  };

  return (
    <Stack gap={12} align='center'>
      <IconWarning />

      <Title ta='center' order={3}>
        Невозможно удалить {innerProps.titleName} ”{innerProps.name}”
      </Title>

      <Stack gap={24}>
        <Text c='gray.6' ta='center'>
          {innerProps.text}
        </Text>

        <Button fullWidth onClick={onSuccess}>
          Хорошо
        </Button>
      </Stack>
    </Stack>
  );
}
