import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_TRANSACTION } from 'entities/transaction/endpoints';

export const transactionGetManyFx = createApiEffect<
  typeof ENDPOINTS_TRANSACTION.GET_MANY.req,
  typeof ENDPOINTS_TRANSACTION.GET_MANY.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_TRANSACTION.GET_MANY,
});

export const transactionChangeProgressFx = createApiEffect<
  typeof ENDPOINTS_TRANSACTION.CHANGE_ONE_INTO_PROGRESS.req,
  typeof ENDPOINTS_TRANSACTION.CHANGE_ONE_INTO_PROGRESS.res
>({
  fxName: false,
  service: 'API',
  endpoint: ENDPOINTS_TRANSACTION.CHANGE_ONE_INTO_PROGRESS,
});

export const transactionCreateOneWithdrawalFx = createApiEffect<
  typeof ENDPOINTS_TRANSACTION.CREATE_ONE_OWN_WITHDRAWAL.req,
  typeof ENDPOINTS_TRANSACTION.CREATE_ONE_OWN_WITHDRAWAL.res
>({
  fxName: 'transactionCreateOneWithdrawalFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_TRANSACTION.CREATE_ONE_OWN_WITHDRAWAL,
});

export const transactionGetStatisticsFx = createApiEffect<
  typeof ENDPOINTS_TRANSACTION.GET_STATISTICS.req,
  typeof ENDPOINTS_TRANSACTION.GET_STATISTICS.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_TRANSACTION.GET_STATISTICS,
});
