import { OrgEntityType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api/types';

export const ENDPOINTS_ORG = {
  // при выполнении ручки паралельно создаётся EMPLOYEE с ролью OWNER, ID которого берётся из токена
  CREATE_ONE_OWN_PROPOSED: {
    path: 'org/create_one_own_proposed',
    method: 'post' as HttpMethod,
    req: {
      orgId: 1,
      name: 'Макдак',
      inn: '1234567890',
      email: 'fuflo@mail.ru',
      phoneNumber: '84958889900',
      avatar:
        'data:image/jpg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QBaRXhpZgAATU0AKgAAAAg...',
    } as Omit<OrgEntityType, 'orgId' | 'status'>,
    res: {} as TipsApiResType<void>,
  },

  // эта ручка доступна только админам
  CREATE_ONE: {
    path: 'org/create_one_proposed',
    method: 'patch' as HttpMethod,
    req: {
      status: 'canceled',
      ownerId: 1,
      name: 'Макдак',
      inn: '1234567890',
      email: 'fuflo@mail.ru',
      phoneNumber: '84958889900',
      avatar:
        'data:image/jpg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QBaRXhpZgAATU0AKgAAAAg...',
    } as Omit<OrgEntityType, 'orgId'> & { ownerId: number },
    res: {} as TipsApiResType<void>,
  },

  GET_ONE: {
    path: 'org/get_one',
    method: 'post' as HttpMethod,
    req: { orgId: 12 } as Pick<OrgEntityType, 'orgId'>,
    res: {} as TipsApiResType<OrgEntityType>,
  },

  CHECK_INN_OCCUPATION: {
    path: 'org/check_inn_occupation',
    method: 'post' as HttpMethod,
    req: { inn: '0987654321' } as Pick<OrgEntityType, 'inn'>,
    res: {} as TipsApiResType<{
      isOccupied: boolean;
    }>,
  },

  EDIT_ONE_OWN: {
    path: 'org/edit_one_own',
    method: 'patch' as HttpMethod,
    req: {
      orgId: 1,
      name: 'Я сок',
      inn: '1234567890',
      email: 'isock@mail.ru',
      avatar:
        'data:image/jpg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QBaRXhpZgAATU0AKgAAAAg...',
      phoneNumber: '88005553535',
    } as Partial<
      Pick<OrgEntityType, 'name' | 'avatar' | 'email' | 'inn' | 'phoneNumber'>
    >,
    res: {} as TipsApiResType<void>,
  },

  EDIT_ONE_OWN_AVATAR: {
    path: 'org/edit_one_own_avatar',
    method: 'patch' as HttpMethod,
    req: {} as FormData,
    res: {} as TipsApiResType<void>,
  },

  CHANGE_AVATAR_OWN: {
    path: 'org/change_avatar_own',
    method: 'patch' as HttpMethod,
    req: {} as Pick<OrgEntityType, 'avatar'>,
    res: {} as TipsApiResType<void>,
  },

  // ручка отменяет заявку на создание организации
  // ручка должна быть доступна только владельцу заявки.
  CANCEL_ONE_OWN: {
    path: 'org/cancel_one_own',
    method: 'patch' as HttpMethod,
    req: { orgId: 15 } as Pick<OrgEntityType, 'orgId'>,
    res: {} as TipsApiResType<void>,
  },

  // ручка должна быть доступна только тех.поддержке, но пока сделаем её открытой,
  // чтобы мы сами могли баловаться
  ACCEPT_ONE: {
    path: 'org/accept_one',
    method: 'patch' as HttpMethod,
    req: { orgId: 15 } as Pick<OrgEntityType, 'orgId'>,
    res: {} as TipsApiResType<void>,
  },

  // ручка должна быть доступна только тех.поддержке, но пока сделаем её открытой,
  // чтобы мы сами могли баловаться
  DENY_ONE_OWN: {
    path: 'org/deny_one_own',
    method: 'patch' as HttpMethod,
    req: { orgId: 15 } as Pick<OrgEntityType, 'orgId'>,
    res: {} as TipsApiResType<void>,
  },
};
