import { KyResponse } from 'ky';

const promise = <T>(body: T, isOk?: boolean) =>
  new Promise((resolve, reject) => {
    const handler = isOk ? resolve : reject;
    handler(body);
  }) as Promise<any>;

export const responseProcessingKy = async <T>(
  fn: () => Promise<KyResponse>,
): Promise<T> => {
  const res = await fn().catch((res) => {
    return { ...res, status: 500 };
  });
  try {
    const resBody = (await res.json()) as any;
    return promise({ ...resBody, status: res.status }, res.ok);
  } catch (e) {
    return promise({ status: res.status }, res.ok);
  }
};
