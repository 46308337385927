import { DatesProvider } from '@mantine/dates';
import RouterProvider from 'app/providers/router-provider';
import ThemeProvider from 'app/providers/theme-provider';
import TranslationsProvider from 'app/providers/translations-provider';

const RootProvider = () => {
  return (
    <TranslationsProvider>
      <ThemeProvider>
        <DatesProvider settings={{ locale: 'ru' }}>
          <RouterProvider />
        </DatesProvider>
      </ThemeProvider>
    </TranslationsProvider>
  );
};

export default RootProvider;
