import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_RATING } from './endpoints';

export const ratingGetOneFx = createApiEffect<
  typeof ENDPOINTS_RATING.GET_ONE.req,
  typeof ENDPOINTS_RATING.GET_ONE.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_RATING.GET_ONE,
});

export const ratingGetManyFx = createApiEffect<
  typeof ENDPOINTS_RATING.GET_MANY.req,
  typeof ENDPOINTS_RATING.GET_MANY.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_RATING.GET_MANY,
});
