import type { ReactNode } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

interface PropsType {
  children: ReactNode;
  watchedKey: any;
}

export const AnimatedSwitchFade300ms = ({
  watchedKey,
  children,
}: PropsType) => {
  return (
    <SwitchTransition>
      <CSSTransition classNames='fade300ms' timeout={300} key={watchedKey}>
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};
