import { Flex, MantineSpacing, StyleProp } from '@mantine/core';
import { PropsWithChildren } from 'react';
import s from './style.module.css';

type PropsType = PropsWithChildren<{
  variant: 'sublte' | 'light';
  onClick: (() => void) | undefined;
  padding?: StyleProp<MantineSpacing>;
}>;

const HeaderPressableBox = ({
  children,
  variant,
  onClick,
  padding = 8,
}: PropsType) => {
  const rootClass: string[] = [
    variant === 'light' ? s.pressable_light : s.pressable_subtle,
  ];
  if (Boolean(onClick)) rootClass.push(s.active);

  return (
    <Flex
      p={padding}
      gap={8}
      onClick={onClick}
      className={rootClass.join(' ')}
      pos='relative'>
      {children}
    </Flex>
  );
};

export default HeaderPressableBox;
