import { UserEntityType } from 'entities/entities-types';

export function getUserFioShort(
  user: UserEntityType | undefined | null,
  isNotPatronymic?: boolean,
) {
  if (!user) return '';
  const resultArr = [];
  if (user?.firstname) resultArr.push(user.firstname);
  if (user?.lastname) resultArr.push(user.lastname[0] + '.');
  if (user?.patronymic && !isNotPatronymic)
    resultArr.push(user.patronymic[0] + '.');
  return resultArr.join(' ');
}
