import { LoadingOverlay } from '@mantine/core';
import { T, always, cond, equals } from 'ramda';
import { ReactNode, Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PATHS } from 'shared/model/const';

const dynamicProps = {
  ssr: false,
  loading: () => (
    <LoadingOverlay
      visible={true}
      h='100vh'
      zIndex={1000}
      overlayProps={{ radius: 'xl' }}
      loaderProps={{ type: 'bars' }}
    />
  ),
};
const SharingRulesManagement = lazy(() => import('./sharing-rules-management'));
const SharingRuleForm = lazy(() => import('./sharing-rule-form'));
const OrgCategoryManagment = lazy(() => import('./OrgCategoryManagment'));
const OrgCategoryItemManagment = lazy(
  () => import('./OrgCategoryItemManagment'),
);
const OrgInvitationsManagment = lazy(() => import('./OrgInvitationsManagment'));

type ViewType = typeof PATHS['/org']['currentView'];

const Page = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView') as ViewType;

  const getCurrentView = cond<ViewType[], ReactNode>([
    [
      equals('category-management' as ViewType),
      always(<OrgCategoryManagment />),
    ],
    [
      equals('category-item-management' as ViewType),
      always(<OrgCategoryItemManagment />),
    ],
    [
      equals('invitations-management' as ViewType),
      always(<OrgInvitationsManagment />),
    ],
    [
      equals('sharing-rules-management' as ViewType),
      always(<SharingRulesManagement />),
    ],
    [equals('sharing-rule-form' as ViewType), always(<SharingRuleForm />)],
    [T, always(<OrgCategoryManagment />)],
  ]);

  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible={true}
          h='100vh'
          zIndex={1000}
          overlayProps={{ radius: 'xl' }}
          loaderProps={{ type: 'bars' }}
        />
      }>
      {getCurrentView(currentView)}
    </Suspense>
  );
};
export default Page;
