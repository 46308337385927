import { Divider, Stack } from '@mantine/core';
import { useUnit } from 'effector-react';
import {
  $header,
  $headerCollapsableBoxParams,
} from 'entities/app-flows/header';
import { HeaderNavBar } from 'features/header';

export const HeaderPanel = () => {
  const {
    navBarHeight,
    navBarRightSection,
    navBarBackButtonAction,
    background,
  } = useUnit($header);

  const { opacity } = useUnit($headerCollapsableBoxParams);
  const opacityBoolean = opacity > 0.9;

  return (
    <Stack gap={0} pos='relative'>
      <HeaderNavBar
        backButtonAction={navBarBackButtonAction}
        height={navBarHeight}
        rightSection={navBarRightSection}
        invertColor={background === 'colorful' && opacityBoolean}
      />
      <Divider />
    </Stack>
  );
};
