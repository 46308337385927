import { Box, Flex, Stack, Text, useMantineTheme } from '@mantine/core';
import { useUnit } from 'effector-react';
import { BalanceInfoBox } from 'entities/balance';
import { $currentSessionInfo } from 'entities/store';
import {
  $userOneOwn,
  getEmployeeAndOrgFromUser,
  getUserFio,
} from 'entities/user';
import { QrCodeLinkButton } from 'features/header';
import { Carousel as MyCarousel } from 'shared/ui/containers';

import { useGetEmployeeRoleText } from 'entities/employee';
import { RoleValueType } from 'entities/entities-types';
import { includes, or } from 'ramda';
import { ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { useNotifications } from 'shared/lib/hooks';
import { ButtonFeedbackImg, ButtonStatImg } from 'shared/model/images';
import { IconFallbackOrg, IconFallbackUser } from 'shared/model/vector-images';
import { HeaderPressableBox, HeaderPressableNavLink } from 'shared/ui/buttons';

const managerRoleValues: RoleValueType[] = ['owner', 'manager'];
const getScrollMenuItems = (
  navigate: NavigateFunction,
  userId: number | undefined | null,
): {
  text: string;
  onClick: () => void;
  image: ReactNode;
}[] => [
  {
    text: 'Отзывы гостей',
    onClick: () =>
      navigate(
        getHref({
          '/public': {
            currentView: 'feedback-history',
            uid: String(userId),
            eid: '',
            oid: '',
          },
        }),
      ),
    image: (
      <ButtonFeedbackImg
        style={{ position: 'absolute', right: 0, bottom: 0 }}
      />
    ),
  },
  {
    text: 'Моя статистика',
    onClick: () =>
      navigate(getHref({ '/user': { currentView: 'my-statistics' } })),
    image: (
      <ButtonStatImg style={{ position: 'absolute', right: 0, bottom: 0 }} />
    ),
  },
];

export const HomePageTopPanel = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const notify = useNotifications();
  const getEmployeeRoleText = useGetEmployeeRoleText();
  const [currentSessionInfo, userOneOwn] = useUnit([
    $currentSessionInfo,
    $userOneOwn,
  ]);
  const currentUserId = currentSessionInfo?.userId;
  const currentEmployeeId = currentSessionInfo?.employeeId;

  const {
    org: currentOrg,
    employee: currentEmployee,
  } = getEmployeeAndOrgFromUser(userOneOwn, currentEmployeeId);

  const currentRoleValue: RoleValueType | '' =
    currentEmployee?.employeeRole?.roleValue || '';

  const isManager = includes(currentRoleValue, managerRoleValues);

  const scrollMenuItems = getScrollMenuItems(navigate, currentUserId);
  const handleGoToOrgMenu = () => navigate(`/org`);

  const handleGoToUserMenu = () => navigate('/user');

  return (
    <Stack py={16} gap={8} maw='100vw'>
      <Flex justify='space-between'>
        <Stack px={16} gap={8} w='100%'>
          <Flex align='center' justify='space-between' c='inherit'>
            <HeaderPressableNavLink
              fallbackAvatar={<IconFallbackUser />}
              withChevron={true}
              variant='sublte'
              onClick={handleGoToUserMenu}
              avatarSrcStr={userOneOwn?.avatar || ''}
              label={getUserFio(userOneOwn, true)}
              description={getEmployeeRoleText(currentRoleValue)}
            />

            <QrCodeLinkButton
              invertColor={true}
              orgId={null}
              userId={currentUserId}
              employeeId={currentEmployeeId}
            />
          </Flex>

          <BalanceInfoBox balanceOwner='user' />

          {currentOrg && (
            <HeaderPressableNavLink
              fallbackAvatar={<IconFallbackOrg />}
              withChevron={isManager}
              variant='light'
              onClick={isManager ? handleGoToOrgMenu : undefined}
              avatarSrcStr={or(currentOrg?.avatar, '')}
              label={currentOrg.name}
              description='Организация'
            />
          )}

          <MyCarousel>
            {scrollMenuItems.map((item) => (
              <HeaderPressableBox
                key={item.text}
                variant='light'
                onClick={item.onClick}>
                <Box h={74} w={144} p={8} pos='relative'>
                  <Text fw={600} fz={15} c='white'>
                    {item.text}
                  </Text>
                </Box>
                {item.image}
              </HeaderPressableBox>
            ))}
          </MyCarousel>
        </Stack>
      </Flex>
    </Stack>
  );
};
