import { GoalEntityType } from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';

export const ENDPOINTS_GOAL = {
  // ручка на создание цели
  CREATE_ONE_OWN: {
    path: 'goal/create_one_own',
    method: 'post' as HttpMethod,
    req: {
      goalTitle: 'Коплю на мерс',
      goalSum: 10000,
      initSum: 500,
    } as Pick<GoalEntityType, 'goalTitle' | 'goalSum' | 'initSum'>,
    res: {} as TipsApiResType<void>,
  },

  GET_MANY_OWN: {
    path: 'goal/get_many_own',
    method: 'post' as HttpMethod,
    req: { currentPage: 1, itemsPerPage: 10 } as PaginatedReqType<{}>,
    res: {} as TipsApiResType<PaginatedResType<GoalEntityType>>,
  },

  GET_ONE: {
    path: 'goal/get_one',
    method: 'post' as HttpMethod,
    req: { goalId: 1 } as Pick<GoalEntityType, 'goalId'>,
    res: {} as TipsApiResType<GoalEntityType>,
  },

  SWITCH_ONE_OWN: {
    path: 'goal/switch_one_own',
    method: 'patch' as HttpMethod,
    req: { goalId: 1 } as Pick<GoalEntityType, 'goalId'>,
    res: {} as TipsApiResType<GoalEntityType>,
  },

  EDIT_ONE_OWN: {
    path: 'goal/edit_one_own',
    method: 'patch' as HttpMethod,
    req: {
      goalId: 1,
      goalTitle: 'Коплю на мерс',
      goalSum: 10000000,
    } as Partial<Pick<GoalEntityType, 'goalId' | 'goalTitle' | 'goalSum'>>,
    res: {} as TipsApiResType<void>,
  },

  DELETE_ONE_OWN: {
    path: 'goal/delete_one_own',
    method: 'delete' as HttpMethod,
    req: { goalId: 1 } as Pick<GoalEntityType, 'goalId'>,
    res: {} as TipsApiResType<void>,
  },
};
