import { getThemeColor, Stack, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useUnit } from 'effector-react';
import { useGetEmployeeRoleTextTranslate } from 'entities/employee';
import { $$orgInvOneCurrent } from 'entities/org-invitation/attaches';
import { $currentSessionInfo } from 'entities/store';
import { $userOneOwn, UserSignOutIcon } from 'entities/user';
import { useHeader } from 'features/header';
import { MyUserDataEditBox, UserOwnDeleteButton } from 'features/user';
import { propEq, propOr } from 'ramda';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MyUserPageTopPanel } from 'widgets/user-flow';
import MyEmploymentsPanel from 'widgets/user-flow/my-employments-panel';

const UserMainPage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const [
    currentSessionInfo,
    userOwnOne,
    orgInvOneCurrentReq,
    orgInvOneCurrentCode,
  ] = useUnit([
    $currentSessionInfo,
    $userOneOwn,
    $$orgInvOneCurrent.$store.map((s) => s.req),
    $$orgInvOneCurrent.$store.map((s) => s.req?.code),
  ]);

  const ownUserId = propOr(null, 'userId', currentSessionInfo);
  const ownEmployeeId = propOr(null, 'employeeId', currentSessionInfo);
  const ownEmployee = userOwnOne?.userEmployees?.find?.(
    propEq(ownEmployeeId, 'employeeId'),
  );
  const ownUser = ownEmployee?.employeeUser || userOwnOne;
  const goalCurrent = userOwnOne?.goal;
  const getCurrentEmployeeRole = useGetEmployeeRoleTextTranslate();

  const handleUseInvite = () =>
    modals.openContextModal({
      modal: 'orgInviteUseModal',
      title: 'Приглашение в организацию',
      onClose: () => {
        $$orgInvOneCurrent.argResetEvent();
        return;
      },
      innerProps: {},
    });

  // useFooter({ isVisibleDoc: true });
  useHeader({
    navTitle: t('PAGE_TITLES./user'),
    contentBg: getThemeColor('background', theme),
    navBarBackButtonAction: () => navigate('/home'),
    navBarRightSection: <UserSignOutIcon />,
    background: 'colorful',
    collapsableBoxChildren: <MyUserPageTopPanel />,
  });
  useEffect(() => {
    if (orgInvOneCurrentReq.code) handleUseInvite();
  }, [orgInvOneCurrentReq.code]);

  return (
    <Stack gap={24}>
      {/* <MyEmployments /> */}
      <MyEmploymentsPanel />
      {/* {Boolean(activeEmployeeId) && ( */}
      {/*  <SharingRulesManagementLinkButton level='employee' />*/}
      {/* )} */}

      <MyUserDataEditBox />

      <UserOwnDeleteButton />
    </Stack>
  );
};

export default UserMainPage;
