import { createEvent } from 'effector';
import { EmployeeEntityWithCheckedType } from 'entities/entities-types';

export const employeeOneOwnChanged = createEvent<void>();

export const emoloyeeManyActiveInOrgValidated = createEvent<void>();

export const employeeManyProposedToOrgValidated = createEvent<void>();

export const employeeApproved = createEvent<void>();
export const employeeCheckedToggled = createEvent<EmployeeEntityWithCheckedType>();
export const employeeCheckedAllToggled = createEvent<
  EmployeeEntityWithCheckedType[]
>();
