import { FeedbackEntityType } from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api/types';

export const ENDPOINTS_FEEDBACK = {
  GET_MANY: {
    path: 'feedback/get_many',
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<{
      ids?: [];
      lteID?: number;
      sort?: {
        asc?: ['rating'];
      };
      groupBy?: ['rating'];
      grades?: '1' | '3' | '5'[];
      createdAtMin?: Date;
      createdAtMax?: Date;
      userIds?: number[];
      employee?: {
        ids?: number[];
      };
      visitIds?: number[];
      user?: {
        ids?: number[];
      };
      orgIds?: number[];
    }>,
    res: {} as TipsApiResType<PaginatedResType<FeedbackEntityType>>,
  },
};
