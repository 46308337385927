import { Avatar, Flex, Stack, Text } from '@mantine/core';
import { getPic } from 'entities';
import { ReactNode } from 'react';
import { ChevronRight as ChevronRightIcon } from 'react-feather';
import { default as HeaderPressableBox } from './header-pressable-box';
import s from './style.module.css';

type PropsType = {
  withChevron: boolean;
  variant: 'sublte' | 'light';
  onClick: (() => void) | undefined;
  label: string;
  description: string;
  avatarSrcStr: string;
  fallbackAvatar?: ReactNode;
};

const HeaderPressableNavLink = ({
  withChevron,
  variant,
  avatarSrcStr,
  description,
  label,
  onClick,
  fallbackAvatar,
}: PropsType) => {
  return (
    <HeaderPressableBox variant={variant} onClick={onClick}>
      <Avatar
        variant='transparent'
        style={{ border: 'solid white 1px' }}
        size={40}
        src={getPic(avatarSrcStr)}
        radius={1000}>
        {fallbackAvatar}
      </Avatar>

      <Stack gap={0}>
        <Flex align='center'>
          <Text fw={600} fz={16} style={{ textWrap: 'nowrap' }}>
            {label}
          </Text>
          {withChevron && variant === 'sublte' && (
            <ChevronRightIcon strokeWidth={2.5} />
          )}
        </Flex>
        <Text fw={400} fz={14} opacity={0.7} style={{ textWrap: 'nowrap' }}>
          {description}
        </Text>
      </Stack>

      <Flex className={s.right}>
        {withChevron && variant === 'light' && (
          <ChevronRightIcon
            width={30}
            height={30}
            opacity={0.5}
            strokeWidth={2}
          />
        )}
      </Flex>
    </HeaderPressableBox>
  );
};

export default HeaderPressableNavLink;
