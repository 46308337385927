import { LoadingOverlay, Stack } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$orgInvOneCurrent } from 'entities/org-invitation';
import { OrgInvBar } from 'features/org-invitation';

import { useHeader } from 'features';
import { T, always, cond, equals } from 'ramda';
import { ReactNode, Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PATHS } from 'shared/model/const';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';

const MyOldResource = lazy(() =>
  import('pages/auth/password-recover').then((module) => ({
    default: module.MyOldResource,
  })),
);
const MyNewPassword = lazy(() =>
  import('pages/auth/password-recover').then((module) => ({
    default: module.MyNewPassword,
  })),
);
const AuthStepOneView = lazy(() => import('pages/auth/step-one'));
const AuthStepOneEmailView = lazy(() => import('pages/auth/step-one-email'));
const AuthStepTwoView = lazy(() => import('pages/auth/step-two'));
const AuthStepThreeView = lazy(() => import('pages/auth/step-three'));
const AuthStepPhoneCheckView = lazy(() => import('pages/auth/step-phone'));
const AuthStepOrgAppView = lazy(() => import('pages/auth/step-org'));
const AuthStepEmailCheckView = lazy(() => import('pages/auth/step-email'));

type ViewType = typeof PATHS['/auth']['currentView'];

const AuthPage = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView') as ViewType;
  const { 0: orgInvOneCurrentCode } = useUnit([
    $$orgInvOneCurrent.$store.map((s) => s.req?.code),
  ]);
  const getCurrentView = cond<ViewType[], ReactNode>([
    [equals('step-phone' as ViewType), always(<AuthStepPhoneCheckView />)],
    [equals('step-email' as ViewType), always(<AuthStepEmailCheckView />)],
    [equals('step-two' as ViewType), always(<AuthStepTwoView />)],
    [equals('step-one-email' as ViewType), always(<AuthStepOneEmailView />)],
    [equals('step-three' as ViewType), always(<AuthStepThreeView />)],
    [equals('step-org' as ViewType), always(<AuthStepOrgAppView />)],
    [equals('password-recover' as ViewType), always(<MyOldResource />)],
    [equals('password-new' as ViewType), always(<MyNewPassword />)],
    [T, always(<AuthStepOneView />)],
  ]);
  useHeader({
    contentMaxWidth: 450,
  });
  return (
    <AnimatedSwitchFade300ms watchedKey={currentView || ''}>
      <Stack mt={16}>
        {Boolean(orgInvOneCurrentCode) && (
          <OrgInvBar placement='in-auth-page' />
        )}
        <Suspense
          fallback={
            <LoadingOverlay
              h='100vh'
              visible={true}
              zIndex={1000}
              overlayProps={{ radius: 'xl' }}
              loaderProps={{ type: 'bars' }}
            />
          }>
          {getCurrentView(currentView)}
        </Suspense>
      </Stack>
    </AnimatedSwitchFade300ms>
  );
};
export default AuthPage;
