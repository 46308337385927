import { LoadingOverlay } from '@mantine/core';

import { T, always, cond, equals } from 'ramda';
import { ReactNode, Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PATHS } from 'shared/model/const';

const dynamicProps = {
  ssr: false,
  loading: () => (
    <LoadingOverlay
      visible={true}
      h='100vh'
      zIndex={1000}
      overlayProps={{ radius: 'xl' }}
      loaderProps={{ type: 'bars' }}
    />
  ),
};

const BalanceWithdrawal = lazy(() => import('pages/org/balance-withdrawal'));
const EmployeeList = lazy(() => import('pages/org/employees-list'));
const Employee = lazy(() => import('pages/org/employee'));
const OrgMain = lazy(() => import('pages/org/org-main'));
const PhoneChange = lazy(() => import('pages/org/org-phone-change'));
const EmailChange = lazy(() => import('pages/org/org-email-change'));
const Statistics = lazy(() => import('pages/org/statistics'));
const NameAndInnChange = lazy(
  () => import('pages/org/org-name-and-inn-change'),
);

type ViewType = typeof PATHS['/org']['currentView'];

const Page = () => {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get('currentView') as ViewType;
  const getCurrentView = cond<ViewType[], ReactNode>([
    [equals('statistics' as ViewType), always(<Statistics />)],
    [equals('employee-list' as ViewType), always(<EmployeeList />)],
    [equals('employee' as ViewType), always(<Employee />)],
    [equals('name-and-inn-change' as ViewType), always(<NameAndInnChange />)],
    [equals('phone-change' as ViewType), always(<PhoneChange />)],
    [equals('email-change' as ViewType), always(<EmailChange />)],
    [equals('balance-withdrawal' as ViewType), always(<BalanceWithdrawal />)],
    [T, always(<OrgMain />)],
  ]);

  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible={true}
          h='100vh'
          zIndex={1000}
          overlayProps={{ radius: 'xl' }}
          loaderProps={{ type: 'bars' }}
        />
      }>
      {getCurrentView(currentView)}
    </Suspense>
  );
};

export default Page;
