import {
  EmployeeEntityType,
  OrgEntityType,
  UserEntityType,
} from 'entities/entities-types';
import { find, propEq, propOr } from 'ramda';

export const getEmployeeAndOrgFromUser = (
  user: UserEntityType | undefined | null,
  employeeId: number | undefined | null,
): {
  org: OrgEntityType | undefined;
  employee: EmployeeEntityType | undefined;
} => {
  const employees = propOr<
    EmployeeEntityType[],
    UserEntityType | undefined | null,
    EmployeeEntityType[]
  >([], 'userEmployees', user);
  const employee = find(propEq(employeeId, 'employeeId'), employees);
  const org = propOr<
    undefined,
    EmployeeEntityType | undefined | null,
    OrgEntityType
  >(undefined, 'employeeOrg', employee);
  return { employee, org };
};
