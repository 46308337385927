import { createStore, sample } from 'effector';
import { UserEntityType } from 'entities/entities-types';
import {
  appReset,
  currentSessionInvalidated,
  currentSessionReceived,
  orgOneOwnActiveChanged,
  userInStatStateChanged,
  userOneOwnInvalidated,
  userOneOwnReceived,
} from 'entities/events';
import {
  userChangeOwnAvatarFx,
  userChangeOwnEmailFx,
  userChangeOwnFullNameFx,
  userChangeOwnPhoneNumberFx,
  userGetOneCurrentFx,
  userGetOneOwnFx,
  userGetOwnSessionInfoFx,
  userSignInFx,
  userSignOutFx,
  userSignUpFx,
  userSignUpWithOrgApplicationFx,
} from 'entities/user/effects';

sample({
  clock: currentSessionInvalidated,
  target: userGetOwnSessionInfoFx,
});
sample({
  clock: userOneOwnInvalidated,
  target: userGetOneOwnFx,
});

sample({
  clock: [
    userSignOutFx.done,
    userSignUpFx.done,
    userSignInFx.done,
    userSignUpWithOrgApplicationFx.done,
  ],
  target: [appReset, currentSessionInvalidated, userOneOwnInvalidated],
});

sample({
  clock: [
    userChangeOwnAvatarFx.done,
    userChangeOwnFullNameFx.done,
    userChangeOwnEmailFx.done,
    userChangeOwnPhoneNumberFx.done,
  ],
  target: userOneOwnInvalidated,
});

sample({
  clock: userGetOwnSessionInfoFx.doneData,
  fn: (clockResult) => clockResult,
  target: currentSessionReceived,
});

sample({
  clock: [
    userChangeOwnAvatarFx.done,
    userChangeOwnFullNameFx.done,
    userChangeOwnEmailFx.done,
    userChangeOwnPhoneNumberFx.done,
  ],
  target: userOneOwnInvalidated,
});

sample({
  clock: userGetOneOwnFx.doneData,
  target: userOneOwnReceived,
  fn: (clockResult) => clockResult.data,
});

export const $userOneOwn = createStore<UserEntityType | null>(null)
  .on(userOneOwnReceived, (state, payload) => {
    return payload;
  })
  .reset(appReset);

export const $userOneCurrent = createStore<UserEntityType | null>(null).on(
  userGetOneCurrentFx.doneData,
  (state, payload) => {
    return payload.data;
  },
);

export const $currUserStat = createStore<'visit' | 'withdrawal'>('visit').on(
  userInStatStateChanged,
  (_, payload) => {
    return payload;
  },
);

sample({
  clock: currentSessionReceived,
  source: $userOneOwn,
  fn: (sourceData, clockData) =>
    sourceData?.userEmployees?.find?.(
      (emp) => emp?.employeeId === clockData?.data.employeeId,
    )?.employeeOrg,
  target: orgOneOwnActiveChanged,
});
