import { createStore } from 'effector';
import { VisitEntityType, VisitStatisticsType } from 'entities/entities-types';
import * as ef from './effects';
import * as ev from './events';

export const $visitManyOwn = createStore<VisitEntityType[]>([]);

export const $visitManyOwnInStat = createStore<VisitEntityType[]>([]);
export const $visitManyOwnOrg = createStore<VisitEntityType[]>([]);
export const $visitManyOwnOrgInStat = createStore<VisitEntityType[]>([]);
export const $visitManyOwnOrgInChart = createStore<VisitEntityType[]>([]);
export const $visitStatCurrMonthUser = createStore<number>(11).on(
  ev.visitStatMonthInUserChanged,
  (_, payload) => payload,
);
export const $transactionStatCurrMonthUser = createStore<number>(11).on(
  ev.transactionStatMonthInUserChanged,
  (_, payload) => payload,
);

export const $visitStatCurrMonthOrg = createStore<number>(11).on(
  ev.visitStatMonthInOrgChanged,
  (_, payload) => payload,
);

export const $visitManyCurrentUser = createStore<VisitEntityType[]>([]);

// export const $guestVisitForm = createStore<GuestVisitFormType>({
//   feedbackGrade: null,
//   feedbackMessage: null,
//   netAmount: null,
//   isCommissionCompensated: true,
//   email: '',
// });

export const $currentVisitStatistics = createStore<VisitStatisticsType | null>(
  null,
).on(ef.visitGetStatisticFx.doneData, (state, payload) => {
  return payload.data as VisitStatisticsType;
});

// $guestVisitForm
//   .on(ev.guestVisitFormEdited, (state, payload) => {
//     return { ...state, ...payload };
//   })
//   .reset(ev.guestVisitFormReset);

// $visitManyOwn.on(ef.visitGetManyFx.doneData, (state, payload) => {
//   return payload.data.items;
// });
