import { useUnit } from 'effector-react';
import { $$orgInvOneCurrent } from 'entities/org-invitation';
import { userGetOwnSessionInfoFx } from 'entities/user/effects';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingWindow } from 'shared/ui/panels';

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orgInvitationCode = searchParams.get('oic') || '';

  const { 0: orgInvOneCurrent } = useUnit([
    $$orgInvOneCurrent.$store,
    $$orgInvOneCurrent.argEditEvent,
  ]);
  useEffect(() => {
    $$orgInvOneCurrent.argEditEvent({ code: orgInvitationCode });
  }, []);

  useEffect(() => {
    if (orgInvitationCode) {
      userGetOwnSessionInfoFx()
        .then((res) => {
          if (res?.data?.userId) navigate('/user');
          else navigate('/auth');
        })
        .catch((e) => navigate('/auth'));
    } else {
      navigate('/');
    }
  }, [orgInvOneCurrent.req.code]);

  return <LoadingWindow />;
};

export default Page;
