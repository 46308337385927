import {
  ActionIcon,
  Avatar,
  Flex,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { getPic } from 'entities/files';
import { $$orgInvOneCurrent } from 'entities/org-invitation';
import {
  Frown as IconFrown,
  HelpCircle as IconHelpCircle,
  Home as IconHome,
  X as IconX,
} from 'react-feather';
import { useNotifications } from 'shared/lib/hooks';
import { Condition } from 'shared/ui/display';

type PropsType = {
  placement: 'in-auth-page' | 'in-user-page';
};

export const OrgInvBar = ({ placement }: PropsType) => {
  const theme = useMantineTheme();
  const containerStyle = {
    // color: 'white',
    padding: '0.75rem',
    borderRadius: '1rem',
    transition: 'all 0.3s',
    backgroundColor: theme.white,
    border: `2px dashed ${theme.colors.brand[5]}`,
  };

  const {
    0: orgInvOneCurrent,
    1: getIsLoading,
    2: resStatus,
    3: forgetOrgInv,
  } = useUnit([
    $$orgInvOneCurrent.$store.map((s) => s?.res?.data),
    $$orgInvOneCurrent.attachedFx.pending,
    $$orgInvOneCurrent.$store.map((s) => s.res?.status),
    $$orgInvOneCurrent.argResetEvent,
  ]);

  const toastNote = useNotifications();
  const toastMessage =
    placement === 'in-user-page'
      ? 'Вы перешли по ссылке-приглашению. ' +
        'Нажмите "Принять", чтобы вступить в организацию или "Отменить", чтобы проигнорировать приглашение.'
      : 'Вы перешли по ссылке-приглашению. ' +
        'После авторизации/регистрации вам будет предложено вступить в указанную организацию.';
  const handleHelpClick = () => {
    toastNote('info', 'Прикрепление к организации', toastMessage, 8000);
  };

  const isInviteLinkIrrelevant =
    (orgInvOneCurrent?.maxUsesCount &&
      orgInvOneCurrent?.usesCount &&
      orgInvOneCurrent.maxUsesCount < orgInvOneCurrent.usesCount) ||
    (orgInvOneCurrent?.expiresAt?.getTime &&
      orgInvOneCurrent.expiresAt.getTime() < new Date().getTime());

  let errorMessage = 'Неизвестная ошибка...';
  if (resStatus === 404)
    errorMessage = 'Использованная вами реферальная ссылка недействительна';
  if (resStatus === 500) errorMessage = 'Неизвестная ошибка сервера.';
  if (Boolean(isInviteLinkIrrelevant))
    errorMessage = 'Использованная вами реферальная ссылка уже не актуальна';

  return (
    <Skeleton visible={getIsLoading}>
      <Flex gap='sm' align='center' style={containerStyle}>
        <Condition when={Boolean(orgInvOneCurrent) && resStatus === 200}>
          <>
            <Avatar
              variant='transparent'
              src={getPic(orgInvOneCurrent?.orgInvitationOrg?.avatar)}
              radius={1000}>
              <IconHome />
            </Avatar>
            <Stack gap={0} style={{ flex: 1 }}>
              <Text fw='bold'>{orgInvOneCurrent?.orgInvitationOrg?.name}</Text>
              <Text fz='sm'>{orgInvOneCurrent?.orgInvitationOrg?.inn}</Text>
            </Stack>
            <ActionIcon
              size='md'
              variant='transparent'
              color='gray.9'
              onClick={handleHelpClick}>
              <IconHelpCircle size='100%' />
            </ActionIcon>
          </>
        </Condition>

        <Condition when={!Boolean(orgInvOneCurrent) && resStatus !== 200}>
          <Avatar variant='transparent' radius={1000}>
            <IconFrown />
          </Avatar>
          <Text style={{ flex: 1 }} fz='sm'>
            {errorMessage}
          </Text>
          <Condition when={placement === 'in-auth-page'}>
            <ActionIcon size='md' variant='white' onClick={forgetOrgInv}>
              <IconX size='100%' color={theme.black} />
            </ActionIcon>
          </Condition>
        </Condition>
      </Flex>
    </Skeleton>
  );
};
