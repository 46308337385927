import {
  Box,
  Button,
  Flex,
  Image,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  Title,
  Transition,
} from '@mantine/core';
import { always, cond, equals } from 'ramda';
import { useState } from 'react';
import { useIsMobile } from 'shared/lib/hooks';
import { Employee, Statistics, TelEmployee, TelGuest } from 'shared/model';
import { Condition } from 'shared/ui/display';

export const Advantages = () => {
  const [selectedCategory, setSelectedCategory] = useState('Для бизнеса');
  const [currentCategory, setCurrentCategory] = useState('Для бизнеса');
  const [visible, setVisible] = useState(true);
  const isMobile = useIsMobile(900);

  const handleCategoryChange = (value: string) => {
    setVisible(false);
    setTimeout(() => {
      setCurrentCategory(value);
      setVisible(true);
    }, 400);
    setSelectedCategory(value);
  };

  const content = [
    {
      title: 'Для бизнеса',
      text:
        'Наш сервис помогает управлять чаевыми, позволяя отслеживать показатели сотрудников и настраивать распределение вознаграждений.',
      button: (
        <Button
          fullWidth={isMobile}
          h={50}
          fz={14}
          fw={500}
          w={{ base: '100%', md: 240 }}
          href='/auth'
          component='a'>
          Подключить чаевые
        </Button>
      ),
      img: (
        <Flex w='100%' h={200} justify='space-between' pos='relative'>
          <Image
            pos='absolute'
            alt='400'
            // style={{ objectFit: 'contain' }}
            top='30px'
            w={176}
            h={128}
            src={Statistics}
          />
          <Image
            alt='400'
            w={170}
            pos='absolute'
            right='-15px'
            src={Employee}
          />
        </Flex>
      ),
    },
    {
      title: 'Для сотрудников',
      text:
        'Мы понимаем, как важно получать заслуженное вознаграждение за ваш труд. "Чайник" моментально передает онлайн-чаевые напрямую на ваш счет. Используйте свой уникальный QR-код и получайте чаевые быстро и безопасно.',
      button: (
        <Button
          fullWidth={isMobile}
          h={50}
          fz={14}
          fw={500}
          w={{ base: '100%', md: 240 }}
          href='/auth'
          component='a'>
          Получать чаевые
        </Button>
      ),
      img: <Image w={219} h={196} alt='404' src={TelEmployee} />,
    },
    {
      title: 'Для гостей',
      text:
        'С "Чайником" нет необходимости искать наличные – просто отсканируйте QR-код и отправьте чаевые несколькими нажатиями.',
      img: (
        <Image
          alt='400'
          style={{ objectFit: 'contain' }}
          w={155}
          h={300}
          src={TelGuest}
        />
      ),
    },
  ];

  const renderContent = cond([
    [
      equals('Для бизнеса'),
      always(
        <Flex
          pos='relative'
          gap={24}
          mb={{ base: 20, sm: 44 }}
          mr={{ base: 20, sm: 44 }}
          justify={{ base: 'center', sm: 'space-between' }}>
          <Flex
            w={{ base: 300, md: 560 }}
            direction='column'
            gap={{ base: 16, sm: 40 }}
            ta='left'>
            <Title order={1}>Для бизнеса</Title>

            <Text fz={{ sm: 16, md: 20 }} c='gray.7'>
              Наш сервис помогает управлять чаевыми, позволяя отслеживать
              показатели сотрудников и настраивать распределение вознаграждений.
            </Text>
            <Button
              fullWidth={isMobile}
              h={50}
              fz={14}
              fw={500}
              w={{ base: '100%', md: 240 }}
              href='/auth'
              component='a'>
              Подключить чаевые
            </Button>
          </Flex>
          <Box pos='relative' h={{ base: 209, sm: 300, lg: 346 }} w={318}>
            <Image
              alt='400'
              pos='absolute'
              style={{ objectFit: 'contain' }}
              top='-30%'
              src={Employee}
            />
            <Box>
              <Image
                pos='absolute'
                alt='400'
                top={{ base: '57%', md: '50%' }}
                right={{ base: '57%', md: '80%' }}
                style={{ objectFit: 'contain' }}
                h={210}
                w={281}
                src={Statistics}
              />
            </Box>
          </Box>
        </Flex>,
      ),
    ],
    [
      equals('Для гостей'),
      always(
        <Flex
          mb={{ base: 20, sm: 44 }}
          mr={{ base: 20, sm: 44 }}
          // wrap='wrap'
          justify={{ base: 'center', md: 'space-between' }}>
          <Stack ta='left' maw={535}>
            <Title order={1}>Для гостей</Title>
            <Text fz={{ sm: 16, md: 20 }} c='gray.7'>
              {`С "Чайником" нет необходимости искать наличные – просто отсканируйте QR-код и отправьте чаевые несколькими нажатиями.`}
            </Text>
          </Stack>
          <Flex
            pos='relative'
            w={{ base: '100%', md: 'auto' }}
            justify='flex-end'>
            <Image pos='absolute' alt='400' w={326} src={TelGuest} />
          </Flex>
        </Flex>,
      ),
    ],
    [
      equals('Для сотрудников'),
      always(
        <Flex
          gap={24}
          // wrap='wrap'
          mb={{ base: 20, sm: 44 }}
          mr={{ base: 20, sm: 44 }}
          justify={{ base: 'center', sm: 'space-between' }}>
          <Stack ta='left' maw={535}>
            <Title order={1}>Для сотрудников</Title>

            <Text fz={{ sm: 16, md: 20 }} c='gray.7'>
              {`Мы понимаем, как важно получать заслуженное вознаграждение за ваш труд. "Чайник" моментально передает онлайн-чаевые напрямую на ваш счет. Используйте свой уникальный QR-код и получайте чаевые быстро и безопасно.`}
            </Text>

            <Button
              fullWidth={isMobile}
              h={50}
              fz={14}
              fw={500}
              w={{ base: '100%', md: 240 }}
              href='/auth'
              component='a'>
              Получать чаевые
            </Button>
          </Stack>

          <Image
            h={{ base: 209, sm: 300, lg: 346 }}
            alt='404'
            src={TelEmployee}
          />
        </Flex>,
      ),
    ],
    [always(true), always(null)],
  ]);

  return (
    <Flex direction='column' gap={{ base: 24, sm: 64 }} id='work'>
      <Condition when={!isMobile}>
        <Paper
          style={{ overflow: 'hidden' }}
          withBorder
          w='100%'
          h={{ base: 560, md: 560, lg: 619 }}
          pt={{ base: 20, sm: 44 }}
          pl={{ base: 20, sm: 44 }}
          radius={44}
          shadow='0px'
          bg='gray.8'>
          <Flex
            pr={{ base: 20, sm: 44 }}
            mb={{ base: 24, md: 79 }}
            w='100%'
            display={{ base: 'block', sm: 'flex' }}
            justify='center'>
            <SegmentedControl
              fullWidth
              orientation={isMobile ? 'vertical' : 'horizontal'}
              styles={{
                root: { backgroundColor: 'white' },
              }}
              withItemsBorders={false}
              radius={16}
              color='dark'
              value={selectedCategory}
              onChange={handleCategoryChange}
              data={['Для бизнеса', 'Для сотрудников', 'Для гостей']}
            />
          </Flex>

          <Transition
            mounted={visible}
            transition='pop'
            duration={400}
            timingFunction='ease'>
            {(styles) => (
              <div style={styles}>{renderContent(currentCategory)}</div>
            )}
          </Transition>
        </Paper>
      </Condition>

      <Condition when={isMobile}>
        <Stack gap={44} style={{ overflow: 'hidden' }}>
          {content.map((item) => (
            <Stack
              style={{ alignItems: 'center' }}
              ta='center'
              key={item.title}>
              <Title order={1}>{item.title}</Title>
              <Text fz={{ sm: 16, md: 20 }} c='gray.7'>
                {item.text}
              </Text>

              {item.img}
            </Stack>
          ))}
        </Stack>
      </Condition>

      <Condition when={isMobile}></Condition>
    </Flex>
  );
};
