import dayjs from 'dayjs';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { visitGetStatisticsGroupedFx } from 'entities/visit/effects';
import { ENDPOINTS_VISIT } from 'entities/visit/endpoints';

export const $$visitStatGroupedOrg = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_VISIT.GET_STATISTICS_GROUPED.req,
  typeof ENDPOINTS_VISIT.GET_STATISTICS_GROUPED.res
>({
  initialReq: {
    createdAtMin: dayjs().toDate(),
    createdAtMax: dayjs().toDate(),
    receiverEntities: ['org'],
    orgIds: [0],
    groupBy: 'month',
  },
  initialFx: visitGetStatisticsGroupedFx,
});
