import { createStore, sample } from 'effector';
import { GoalEntityType } from 'entities/entities-types';
import { appReset, userOneOwnInvalidated } from 'entities/events';

import { $$goalGetManyOwn } from './attaches';
import * as ef from './effects';

export const $goalGetOne = createStore<GoalEntityType | null>(null)
  .on(ef.goalGetOneFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset(appReset);

sample({
  clock: [ef.goalSwitchOneOwnFx.done],
  target: userOneOwnInvalidated,
});

sample({
  clock: [ef.goalEditOneOwnFx.done],
  target: userOneOwnInvalidated,
});

sample({
  clock: ef.goalDeleteOneOwnFx.done,
  source: $$goalGetManyOwn.$store,
  target: $$goalGetManyOwn.argEditEvent,
  fn: (sourceData, clockData) => sourceData.req,
});
