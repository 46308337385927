import { Collapse, Group, Progress, Space, Stack, Text } from '@mantine/core';
// eslint-disable-next-line import/no-internal-modules
import s from './style.module.css';

type PropsType = {
  withText: boolean;
  currentSharePercent: number;
  addedSharePercent?: number;
  removedSharePercent?: number;
  categorySharePercent?: number;
};

const ShareRatioProgress = ({
  withText,
  currentSharePercent,
  removedSharePercent = 0,
  addedSharePercent = 0,
  categorySharePercent = 0,
}: PropsType) => {
  const totalDiff = addedSharePercent - removedSharePercent;
  const notSharePercent =
    100 - currentSharePercent - categorySharePercent - Math.abs(totalDiff);

  return (
    <Stack>
      <Collapse in={withText}>
        <Text
          style={{ overflow: 'hidden', transition: '0.3s all' }}
          fz={12}
          lh={1.2}
          fw={500}
          c='green'
          opacity={addedSharePercent > 0 ? 1 : 0}
          h={addedSharePercent > 0 ? 15 : 0}>
          +{addedSharePercent}% добавлено
        </Text>

        <Text
          style={{ overflow: 'hidden', transition: '0.3s all' }}
          fz={12}
          lh={1.2}
          fw={500}
          c='red'
          opacity={removedSharePercent > 0 ? 1 : 0}
          h={removedSharePercent > 0 ? 15 : 0}>
          -{removedSharePercent}% убрано
        </Text>
        <Space h={5} />

        <Group justify='space-between'>
          <Text fz={14} fw={500}>
            {currentSharePercent + categorySharePercent}% распределено
          </Text>
          <Text
            fz={14}
            fw={500}
            className={notSharePercent < 0 ? s.shake : ''}
            c={notSharePercent < 0 ? 'red' : ''}>
            {notSharePercent}% остаётся
          </Text>
        </Group>
      </Collapse>

      <Progress.Root>
        <Progress.Section value={categorySharePercent} color='blue' />
        <Progress.Section value={currentSharePercent} color='brand' />
        <Progress.Section
          value={Math.abs(totalDiff)}
          color={totalDiff > 0 ? 'green' : 'red'}
        />
        <Progress.Section value={notSharePercent} color='gray' />
      </Progress.Root>
    </Stack>
  );
};

export default ShareRatioProgress;
