import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconQr as QrIcon } from 'shared/model/icons';

import { useLocalStorage } from '@mantine/hooks';
import { toString } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { Condition } from 'shared/ui/display';

type PropsType = {
  orgId: number | undefined | null;
  userId: number | undefined | null;
  employeeId: number | undefined | null;
  invertColor?: boolean;
};

export const QrCodeLinkButton = ({
  orgId,
  userId,
  employeeId,
  invertColor,
}: PropsType) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [areSharingRulesShown, setValue] = useLocalStorage({
    key: 'areSharingRulesShown',
    defaultValue: false,
  });
  const fontColor = invertColor ? theme.white : theme.black;

  const goToQrPage = () =>
    navigate(
      getHref({
        '/public': {
          currentView: 'qr',
          oid: orgId ? toString(orgId) : '',
          uid: userId && !employeeId ? toString(userId) : '',
          eid: employeeId ? toString(employeeId) : '',
        },
      }),
    );

  const handleClick = () => {
    return goToQrPage();

    // TODO: make America great again
    // if (areSharingRulesShown) return goToQrPage();
    // return modals.openContextModal({
    //   modal: 'sharingRulesNotificationModal',
    //   title: 'У вас есть правила распределения',
    //   innerProps: {},
    //   onClose: () => {
    //     setValue(true);
    //     goToQrPage();
    //   },
    // });
  };

  return (
    <Condition when={Boolean(userId) || Boolean(orgId)}>
      <ActionIcon
        c='inherit'
        color='inherit'
        variant='subtle'
        size='lg'
        style={{ transition: 'all 0.3s' }}
        onClick={handleClick}
        aria-label='Qr-code link'>
        <QrIcon color={fontColor} />
      </ActionIcon>
    </Condition>
  );
};
