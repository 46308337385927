import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset, orgOneOwnActiveChanged } from 'entities/events';
import { orgEditOneOwnFx } from 'entities/org/effects';
import { formRules } from 'shared/lib/forms';

export const $orgOwnEmailChangeForm = createForm<{ email: string }>({
  validateOn: ['change', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
  },
});

sample({
  clock: orgOneOwnActiveChanged,
  fn: (clockData) => {
    return {
      email: clockData?.email || '',
    };
  },
  target: $orgOwnEmailChangeForm.setForm,
});

sample({
  clock: [orgEditOneOwnFx.done, appReset],
  target: [$orgOwnEmailChangeForm.reset],
});
