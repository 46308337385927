import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_USER } from 'entities/user/endpoints';

export const userGetOwnSessionInfoFx = createApiEffect<
  typeof ENDPOINTS_USER.GET_OWN_SESSION_INFO.req,
  typeof ENDPOINTS_USER.GET_OWN_SESSION_INFO.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.GET_OWN_SESSION_INFO,
});

export const userGetOneOwnFx = createApiEffect<
  typeof ENDPOINTS_USER.GET_ONE_OWN.req,
  typeof ENDPOINTS_USER.GET_ONE_OWN.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.GET_ONE_OWN,
});

export const userGetOneCurrentFx = createApiEffect<
  typeof ENDPOINTS_USER.GET_ONE.req,
  typeof ENDPOINTS_USER.GET_ONE.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_USER.GET_ONE,
});

export const userGetOneFx = createApiEffect<
  typeof ENDPOINTS_USER.GET_ONE.req,
  typeof ENDPOINTS_USER.GET_ONE.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_USER.GET_ONE,
});

export const userChangeOwnAvatarFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_OWN_AVATAR.req,
  typeof ENDPOINTS_USER.CHANGE_OWN_AVATAR.res
>({
  service: 'TIPS',
  fxName: 'userChangeOneAvatarFx',
  endpoint: ENDPOINTS_USER.CHANGE_OWN_AVATAR,
});

export const userChangeOwnFullNameFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_OWN_FULL_NAME.req,
  typeof ENDPOINTS_USER.CHANGE_OWN_FULL_NAME.res
>({
  service: 'TIPS',
  fxName: 'userChangeOneFullNameFx',
  endpoint: ENDPOINTS_USER.CHANGE_OWN_FULL_NAME,
});

export const userChangeOwnPasswordFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_OWN_PASSWORD.req,
  typeof ENDPOINTS_USER.CHANGE_OWN_PASSWORD.res
>({
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.CHANGE_OWN_PASSWORD,
  fxName: 'userChangeOnePasswordFx',
});

export const userChangeForgottenPasswordFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_FORGOTTEN_PASSWORD.req,
  typeof ENDPOINTS_USER.CHANGE_FORGOTTEN_PASSWORD.res
>({
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.CHANGE_FORGOTTEN_PASSWORD,
  fxName: 'userChangeOnePasswordFx',
});

export const userCheckPasswordFx = createApiEffect<
  typeof ENDPOINTS_USER.CHECK_PASSWORD.req,
  typeof ENDPOINTS_USER.CHECK_PASSWORD.res
>({
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.CHECK_PASSWORD,
  fxName: 'userCheckOldPasswordFx',
});

export const userCompareAndChangeOwnPasswordFx = createApiEffect<
  typeof ENDPOINTS_USER.COMPARE_AND_CHANGE_OWN_PASSWORD.req,
  typeof ENDPOINTS_USER.COMPARE_AND_CHANGE_OWN_PASSWORD.res
>({
  service: 'TIPS',
  fxName: 'userChangeOnePasswordFx',
  endpoint: ENDPOINTS_USER.COMPARE_AND_CHANGE_OWN_PASSWORD,
});

export const userChangeOwnEmailFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_OWN_EMAIL.req,
  typeof ENDPOINTS_USER.CHANGE_OWN_EMAIL.res
>({
  service: 'TIPS',
  fxName: 'userChangeOneEmailFx',
  endpoint: ENDPOINTS_USER.CHANGE_OWN_EMAIL,
});

export const userChangeOwnPhoneNumberFx = createApiEffect<
  typeof ENDPOINTS_USER.CHANGE_OWN_PHONE_NUMBER.req,
  typeof ENDPOINTS_USER.CHANGE_OWN_PHONE_NUMBER.res
>({
  service: 'TIPS',
  fxName: 'userChangeOnePhoneNumberFx',
  endpoint: ENDPOINTS_USER.CHANGE_OWN_PHONE_NUMBER,
});

export const userCheckPhoneNumberOccupationFx = createApiEffect<
  typeof ENDPOINTS_USER.CHECK_PHONE_NUMBER_OCCUPATION.req,
  typeof ENDPOINTS_USER.CHECK_PHONE_NUMBER_OCCUPATION.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.CHECK_PHONE_NUMBER_OCCUPATION,
});

export const userCheckEmailOccupationFx = createApiEffect<
  typeof ENDPOINTS_USER.CHECK_EMAIL_OCCUPATION.req,
  typeof ENDPOINTS_USER.CHECK_EMAIL_OCCUPATION.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_USER.CHECK_EMAIL_OCCUPATION,
});

export const userSignInFx = createApiEffect<
  typeof ENDPOINTS_USER.SIGN_IN.req,
  typeof ENDPOINTS_USER.SIGN_IN.res
>({
  service: 'API',
  fxName: 'userSignInFx',
  endpoint: ENDPOINTS_USER.SIGN_IN,
});

export const userSignUpFx = createApiEffect<
  typeof ENDPOINTS_USER.SIGN_UP.req,
  typeof ENDPOINTS_USER.SIGN_UP.res
>({
  service: 'API',
  fxName: 'userSignUpFx',
  endpoint: ENDPOINTS_USER.SIGN_UP,
});

export const userSignUpWithOrgApplicationFx = createApiEffect<
  typeof ENDPOINTS_USER.SIGN_UP_WITH_ORG_APPLICATION.req,
  typeof ENDPOINTS_USER.SIGN_UP_WITH_ORG_APPLICATION.res
>({
  service: 'TIPS',
  fxName: 'userSignUpFx',
  endpoint: ENDPOINTS_USER.SIGN_UP_WITH_ORG_APPLICATION,
});

export const userSignOutFx = createApiEffect<
  typeof ENDPOINTS_USER.SIGN_OUT.req,
  typeof ENDPOINTS_USER.SIGN_OUT.res
>({
  service: 'TIPS',
  fxName: 'userSignOutFx',
  endpoint: ENDPOINTS_USER.SIGN_OUT,
});

export const userDeleteOneOwnFx = createApiEffect<
  typeof ENDPOINTS_USER.DELETE_ONE_OWN.req,
  typeof ENDPOINTS_USER.DELETE_ONE_OWN.res
>({
  service: 'TIPS',
  fxName: 'userDeleteOneFx',
  endpoint: ENDPOINTS_USER.DELETE_ONE_OWN,
});
