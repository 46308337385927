import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset, userOneOwnReceived } from 'entities/events';
import { formRules } from 'shared/lib/forms';
import { ENDPOINTS_USER } from '../endpoints';

//
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ФИО --- */
export const $userOwnFioChangeForm = createForm<
  typeof ENDPOINTS_USER.CHANGE_OWN_FULL_NAME.req
>({
  validateOn: ['change', 'submit'],
  fields: {
    lastname: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.isEmpty(),
      ],
    },
    firstname: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.isEmpty(),
      ],
    },
    patronymic: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.isEmpty(),
      ],
    },
  },
});
sample({
  clock: userOneOwnReceived,
  fn: (clockData) => ({
    firstname: clockData?.firstname ?? '',
    lastname: clockData?.lastname ?? '',
    patronymic: clockData?.patronymic ?? '',
  }),
  target: $userOwnFioChangeForm.setForm,
});
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ФИО --- */
//

//
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ПАРОЛЯ --- */
export const $userOwnPasswordCompareAndChangeForm = createForm<
  typeof ENDPOINTS_USER.COMPARE_AND_CHANGE_OWN_PASSWORD.req & {
    passwordNewConfirm: string;
  }
>({
  validateOn: ['submit', 'blur', 'change'],
  fields: {
    passwordNew: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.minLength(6),
      ],
    },
    passwordOld: {
      init: '',
      rules: [formRules.maxLength(30)],
    },
    passwordNewConfirm: {
      init: '',
      rules: [
        formRules.required(),
        {
          name: 'passwordNewConfirm',
          validator: (value, { passwordNew }) => value === passwordNew,
          errorText: 'USER.form.newPasswordConfirm.notEqual',
        },
      ],
    },
  },
});
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ПАРОЛЯ --- */
//

//
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ТЕЛЕФОНА --- */
export const $userOwnPhoneChangeForm = createForm<{
  phoneNumber: string;
  isPhoneAccess: boolean;
}>({
  // Pick<UserEntityType, 'phoneNumber'>
  validateOn: ['change', 'submit'],
  fields: {
    phoneNumber: {
      init: '',
      rules: [formRules.required(), formRules.phoneIsValid()],
    },
    isPhoneAccess: {
      init: true,
    },
  },
});
// sample({
//   clock: userOneOwnReceived,
//   fn: (clockData) => {
//     return {
//       phoneNumber: clockData?.phoneNumber ?? '',
//     };
//   },
//   target: $userOwnPhoneChangeForm.setForm,
// });
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО ТЕЛЕФОНА --- */
//

//
/* ---ИЗМЕНЕНИЕ СОБСТВЕННОГО EMAIL --- */
export const $userOwnEmailChangeForm = createForm<{
  email: string | null;
  isEmailAccess: boolean;
}>({
  validateOn: ['change', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
    isEmailAccess: {
      init: true,
    },
  },
});

sample({
  clock: userOneOwnReceived,
  fn: (clockData) => {
    return {
      email: clockData?.email ?? '',
    };
  },
  target: $userOwnEmailChangeForm.setForm,
});
/* --- ИЗМЕНЕНИЕ СОБСТВЕННОГО EMAIL --- */
//

//
/* --- ОБЩИЕ СВЯЗИ --- */
sample({
  clock: [appReset],
  target: [
    $userOwnFioChangeForm.reset,
    $userOwnEmailChangeForm.reset,
    $userOwnPhoneChangeForm.reset,
    $userOwnPasswordCompareAndChangeForm.reset,
  ],
});
/* --- ОБЩИЕ СВЯЗИ --- */
//
