import { Notification, Space, Stack, Text } from '@mantine/core';

import { Link } from 'react-router-dom';
import { getHref } from 'shared/lib';
import { ButtonWithConfirm } from 'shared/ui/buttons';
import { Condition } from 'shared/ui/display';

type PropsType = {
  confirmDeleteCallback: () => void;
  redirectCallback: () => void;
  entityType: 'org' | 'employee';
};

const DeniedView = ({
  entityType,
  redirectCallback,
  confirmDeleteCallback,
}: PropsType) => {
  return (
    <Stack style={{ overflow: 'hidden' }}>
      <Condition when={entityType === 'org'}>
        <Notification withCloseButton={false} color='red'>
          {
            'Ваша заявка на регистрацию заведения отклонена администраторами нашего сервиса. Вы можете уточнить причину '
          }
          <Text
            onClick={redirectCallback}
            fz='sm'
            c='brand'
            component={Link}
            to={getHref({ '/docs': { currentView: 'contact-information' } })}>
            в службе поддержки.
          </Text>
        </Notification>
      </Condition>
      <Condition when={entityType === 'employee'}>
        <Notification withCloseButton={false} color='red'>
          <Text fz='sm'>
            Ваша заявка на вступление в организацию отклонена сотрудником
            заведения.
          </Text>
          <Space h='xs' />
          <Text fz='sm'>
            Для уточнения причины обратитесь к контакту, представившему вам
            пригласительную ссылку.
          </Text>
        </Notification>
      </Condition>

      <ButtonWithConfirm
        onConfirmCallback={confirmDeleteCallback}
        confirmColor='red'
        buttonText='Удалить запись'
        buttonSize='sm'
      />
    </Stack>
  );
};

export default DeniedView;
