import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { $balanceOneOwnOfOrg } from 'entities/balance/store';

import { orgOneOwnActiveChanged } from 'entities/events';
import { formRules } from 'shared/lib/forms';

export const $formBalanceWithdrawalOrg = createForm<{
  email: string | null;
  sum: number | null;
  sbpBank?: string | null;
  withdrawalType: 'CARD' | 'SBP';
  phoneNumber: string;
}>({
  validateOn: ['submit', 'change'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
    sum: {
      init: 0,
      rules: [
        formRules.required(),
        formRules.maxSum(3000),
        formRules.minSum(100, 'Минимальная сумма для вывода'),

        {
          name: 'moreThenBalance',
          source: $balanceOneOwnOfOrg,
          validator: (value, _, store) => Number(value) <= store.amount,
          errorText: `Сумма превышает текущий баланс`,
        },
      ],
    },
    withdrawalType: {
      init: 'SBP',
      rules: [formRules.required()],
    },
    sbpBank: {
      init: null,
    },
    phoneNumber: {
      init: '',
      rules: [formRules.phoneIsValid(), formRules.required()],
    },
  },
});

sample({
  clock: orgOneOwnActiveChanged,
  fn: (clockData) => {
    return {
      email: clockData?.email || '',
    };
  },
  target: $formBalanceWithdrawalOrg.setForm,
});
