import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { balanceGetOneOwnOfUserFx } from 'entities/balance/effects';
import { $balanceOneOwnOfUser } from 'entities/balance/store';
import { userOneOwnReceived } from 'entities/events';
import { formRules } from 'shared/lib/forms';

export const $formBalanceWithdrawalUser = createForm<{
  email: string | null;
  sum: number | null;
  sbpBank?: string | null;
  withdrawalType: 'CARD' | 'SBP';
  phoneNumber: string;
}>({
  validateOn: ['submit', 'change'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
    sum: {
      init: 0,
      rules: [
        formRules.required(),
        formRules.maxSum(3000),
        formRules.minSum(100, 'Минимальная сумма для вывода'),
        {
          name: 'moreThenBalance',
          source: $balanceOneOwnOfUser,
          validator: (value, _, store) => Number(value) <= store.amount,
          errorText: `Сумма превышает текущий баланс`,
        },
      ],
    },
    withdrawalType: {
      init: 'SBP',
      rules: [formRules.required()],
    },
    sbpBank: {
      init: null,
    },
    phoneNumber: {
      init: '',
      rules: [formRules.phoneIsValid(), formRules.required()],
    },
  },
});

sample({
  clock: balanceGetOneOwnOfUserFx.done,
  source: userOneOwnReceived,
  fn: (sourceData, clockData) => {
    return {
      email: sourceData.email,
    };
  },
  target: $formBalanceWithdrawalUser.setForm,
});
