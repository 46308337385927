import { MantineColor } from '@mantine/core';
import { T, always, cond, equals } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

type REQ =
  | 'received'
  | 'completed'
  | 'in_progress'
  | 'failed'
  | undefined
  | null;

export function useGetTransactionUiParams() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  return cond<REQ[], { statusText: string; statusTextColor: MantineColor }>([
    [
      equals('success' as REQ),
      always({
        statusText: 'Операция совершена',
        statusTextColor: 'green',
      }),
    ],
    [
      equals('failed' as REQ),
      always({
        statusText: 'Операция завершилась неудачно',
        statusTextColor: 'red',
      }),
    ],
    [
      T,
      always({
        statusText: 'Операция обрабатывается',
        statusTextColor: 'dimmed',
      }),
    ],
  ]);
}
