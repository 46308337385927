import {
  Box,
  Button,
  Flex,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from 'effector-forms';
import { authFlowCurrentActionChanged } from 'entities/app-flows';
import { $userSignUpForm } from 'entities/user';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from 'shared/lib/hooks';
import { Assessment, GoalGuest, InputTips } from 'shared/model';
import { default as CirclesStripes } from './circles-stripes';
import styles from './style.module.css';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export const Main = () => {
  const navigate = useNavigate();
  const { fields } = useForm($userSignUpForm);
  const isMobile = useIsMobile(900);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [
    promptInstall,
    setPromptInstall,
  ] = useState<BeforeInstallPromptEvent | null>(null);

  const handleEnterButtonClick = () => {
    navigate('/auth');
    fields.isOrgApplied?.set(false);
    authFlowCurrentActionChanged('sign-in');
  };

  const handleOrgApplyButtonClick = () => {
    navigate('/auth');
    fields.isOrgApplied?.set(true);
    authFlowCurrentActionChanged('sign-up');
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setPromptInstall(e as BeforeInstallPromptEvent);
      setSupportsPWA(true);
    });
  }, []);

  const handleInstallClick = () => {
    console.log(1);
    promptInstall && promptInstall.prompt();
    setPromptInstall(null);
    setSupportsPWA(false);
    console.log(2);
  };

  const content = (
    <Flex
      direction={{
        base: 'column-reverse',
        md: 'row',
      }}
      justify={{
        base: 'center',
        lg: 'space-between',
      }}
      gap={{ base: 35, md: 0 }}
      align='center'>
      <Flex direction='column' gap={{ base: 24, md: 44 }}>
        <Flex direction='column' ta='left' gap={{ base: 16, md: 24 }}>
          <Title fz={{ base: 28, md: 60 }}>
            Получайте чаевые{' '}
            <Text fw='inherit' fz='inherit' c='brand' component='span'>
              онлайн!
            </Text>
          </Title>
          <Text fz={{ base: 16, sm: 20 }} maw={400} c='gray.6'>
            Забудьте о наличных. «Чайник» позволяет гостю оставить отзыв и
            отблагодарить персонал
          </Text>
        </Flex>

        <Flex
          w='100%'
          wrap='wrap'
          justify={{ base: 'center', md: 'left' }}
          gap={{ base: 12, md: 24 }}>
          <Button
            fullWidth={isMobile}
            h={50}
            fw={500}
            onClick={handleOrgApplyButtonClick}
            radius={80}>
            Подключить заведение
          </Button>
          <Button
            fullWidth={isMobile}
            h={50}
            fw={500}
            onClick={handleEnterButtonClick}
            radius={80}
            variant='white'>
            Получать чаевые
          </Button>
          {supportsPWA && (
            <Button
              className='android-btn'
              fullWidth={isMobile}
              h={50}
              fw={500}
              onClick={handleInstallClick}
              radius={80}
              variant='light'>
              Установить приложение
            </Button>
          )}
        </Flex>
      </Flex>
      <Stack h={{ sm: 'auto', base: 300 }} pos='relative'>
        <Box h={{ md: 'auto', base: 340 }} pos='relative'>
          <Box
            pos={{ sm: 'unset', base: 'absolute' }}
            w={{ md: 400, base: 300 }}>
            <CirclesStripes />
          </Box>
          <Box pos='absolute' style={{ top: '0px' }}>
            <Image
              className={styles.fade_in}
              w={{ md: 400, sm: 300, base: 270 }}
              alt='404'
              src={GoalGuest}
            />
            <Image
              className={styles.fade_in}
              w='100%'
              alt='404'
              left={{ sm: 'auto', base: '-90%' }}
              right={{ sm: '50%', base: 'auto' }}
              pos='absolute'
              top='75%'
              src={Assessment}
            />
            <Image
              className={styles.fade_in}
              w={{ sm: '100%', base: '100%' }}
              left={{ sm: '60%', base: '20%' }}
              pos='absolute'
              top={{ md: '160%', sm: '90%', base: '114%' }}
              alt='404'
              src={InputTips}
            />
          </Box>
        </Box>
      </Stack>
    </Flex>
  );

  return (
    <Box>
      {!isMobile ? (
        <Paper
          h={680}
          display='flex'
          bg='background'
          styles={{
            root: {
              border: '2px solid var(--mantine-color-gray-2)',
              justifyContent: 'center',
            },
          }}
          radius={44}
          px={{ lg: 64, base: 24 }}
          shadow='xl'
          withBorder>
          {content}
        </Paper>
      ) : (
        <Stack>{content}</Stack>
      )}
    </Box>
  );
};
