import {
  Avatar,
  Checkbox,
  Flex,
  NavLink,
  Space,
  Text,
  getThemeColor,
  useMantineTheme,
} from '@mantine/core';
import { getPic } from 'entities';
import { CategoryItemsGroup } from 'entities/category';
import { useGetEmployeeRoleText } from 'entities/employee';
import { EmployeeEntityType } from 'entities/entities-types';
import { getUserFioShort } from 'entities/user';
import { includes, or } from 'ramda';
import { ChevronRight } from 'react-feather';
import { IconFallbackUser } from 'shared/model/vector-images';
// import { UserPageTopPanel } from 'widgets/user-flow';

type PropsType = {
  employees: EmployeeEntityType[];
  selectedEmployeeIds: number[];
  isSelectorVisible: boolean;
  handleClick: (employee: EmployeeEntityType) => void;
  handleSelect: (eid: number) => void;
  disabledEmployeeIds?: number[];
  ownEmployeeId: number | undefined | null;
};

const EmployeeSelectorList = ({
  employees,
  selectedEmployeeIds,
  isSelectorVisible,
  handleSelect,
  handleClick,
  disabledEmployeeIds,
  ownEmployeeId,
}: PropsType) => {
  const theme = useMantineTheme();
  const getCurrentEmployeeRole = useGetEmployeeRoleText();
  const isEmployeeSelectionDisabled = (item: EmployeeEntityType) => {
    return (
      disabledEmployeeIds?.includes?.(or(item?.employeeId, 0)) ||
      (isSelectorVisible && item.employeeId === ownEmployeeId)
    );
  };

  return (
    <Flex direction='column'>
      {employees.map((item) => {
        const isSelected = includes(item.employeeId, selectedEmployeeIds);
        const isOwnEmployee = item.employeeId === ownEmployeeId;
        return (
          <NavLink
            py={0}
            disabled={isEmployeeSelectionDisabled(item)}
            color='gray'
            variant='subtle'
            key={item.employeeId}
            onClick={
              isSelectorVisible
                ? () => handleSelect(or(item.employeeId, 0))
                : () => handleClick(item)
            }
            style={{ transition: 'all 0.2s' }}
            styles={{
              body: {
                paddingTop: 10,
                borderBottom: 'solid 1px',
                borderColor: getThemeColor('gray.3', theme),
              },
            }}
            label={
              <Flex justify='space-between'>
                <Text fz='sm' fw={500}>
                  {or(getUserFioShort(item.employeeUser), '-')}
                </Text>
                <Text c='gray' fz='xs'>
                  ID: {item.employeeId}
                </Text>
              </Flex>
            }
            description={
              <Flex direction='column'>
                <Space h={4} />
                {item.employeeRole?.roleValue && (
                  <Text fz='xs' c='gray'>
                    {getCurrentEmployeeRole(item?.employeeRole?.roleValue)}
                  </Text>
                )}
                <Space h={12} />
                <CategoryItemsGroup
                  categoryItems={or(item?.employeeCategoryItems, [])}
                />
                <Space h={10} />
              </Flex>
            }
            leftSection={
              <Flex align='center'>
                <Checkbox
                  checked={isSelected}
                  radius={1000}
                  disabled={or(!isSelectorVisible, isOwnEmployee)}
                  style={{ transition: 'all 0.3s' }}
                  opacity={isSelectorVisible ? 1 : 0}
                  maw={isSelectorVisible ? 40 : 0}
                  w={40}
                />
                <Avatar
                  variant='transparent'
                  size={48}
                  radius={1000}
                  src={getPic(item.employeeUser?.avatar)}>
                  <IconFallbackUser />
                </Avatar>
              </Flex>
            }
            rightSection={
              <ChevronRight
                color={getThemeColor('gray', theme)}
                style={{
                  transition: 'all 0.3s',
                  opacity: !isSelectorVisible ? 1 : 0,
                  maxWidth: !isSelectorVisible ? 20 : 0,
                }}
              />
            }
          />
        );
      })}
    </Flex>
  );
};

export default EmployeeSelectorList;
