import { Avatar, Flex, NavLink, Text } from '@mantine/core';
import { getPic } from 'entities';
import { RatingEntityType } from 'entities/entities-types';
import { RatingWithStars } from 'features';
import { always, isNotNil, or, when } from 'ramda';
import { ReactNode } from 'react';
import { ChevronRight as IconChevronRight } from 'react-feather';
import { IconFallbackUser } from 'shared/model/vector-images';
import { Condition } from './condition';

type PropsType = {
  topText: string | undefined | null;
  bottomText: string | undefined | null;
  avatar: string | undefined | null;
  onClick?: () => void;
  isUserGoal?: boolean;
  rating?: RatingEntityType | null | undefined;
};

const getRightSection = when<any, ReactNode>(
  isNotNil,
  always(<IconChevronRight opacity={0.5} />),
);

export const EntityPublicInfoBar = ({
  topText,
  bottomText,
  avatar,
  onClick,
  rating,
}: PropsType) => {
  return (
    <Flex direction='column'>
      <NavLink
        styles={{ section: { marginInlineEnd: '8px' } }}
        p={16}
        color='gray'
        opacity={1}
        onClick={onClick}
        disabled={!Boolean(onClick)}
        style={{ transition: 'all 0.2s' }}
        label={
          <Flex align='center' gap={8}>
            <Text fw={600}>{or(topText, '-')}</Text>{' '}
            <Condition when={Boolean(rating)}>
              <RatingWithStars isFeedbackNumber={false} rating={rating} />
            </Condition>
          </Flex>
        }
        description={<Text fz={14}>{or(bottomText, '-')}</Text>}
        leftSection={
          <Avatar
            size={44}
            radius={1000}
            variant='transparent'
            src={getPic(avatar as string)}
            style={{
              border: '2px solid transparent',
              outline: '2px solid white',
            }}>
            <IconFallbackUser />
          </Avatar>
        }
        rightSection={getRightSection(onClick)}
      />
    </Flex>
  );
};
