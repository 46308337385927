import { Button } from '@mantine/core';
import { ConnectedField, useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import {
  categoryTitleCreateOneOwnInOrgFx,
  categoryTitleEditOneOwnInOrgFx,
} from 'entities/category';
import { $categoryTitlePutForm } from 'entities/category/forms';

import { FormEvent } from 'react';
import { Upload as UploadIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { FormContainer } from 'shared/ui';
import { MyTextInput } from 'shared/ui/inputs';

type PropsType = {
  action: 'create' | 'edit';
  onSuccess?: () => void;
};

export const CategoryTitlePutForm = ({ action, onSuccess }: PropsType) => {
  const { t } = useTranslation();
  const {
    fields,
    submit,
    errorText,
    isDirty,
    values: formValues,
    eachValid,
  } = useForm($categoryTitlePutForm);
  const { 0: isLoadingCreate, 1: isLoadingEdit } = useUnit([
    categoryTitleCreateOneOwnInOrgFx.pending,
    categoryTitleEditOneOwnInOrgFx.pending,
  ]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();

    if (!eachValid) return;
    if (action === 'create')
      categoryTitleCreateOneOwnInOrgFx({
        name: formValues?.name ?? '',
        dscr: formValues?.dscr ?? '',
        avatar: formValues?.avatar || null,
      }).then(() => {
        if (onSuccess) onSuccess();
      });
    if (action === 'edit')
      categoryTitleEditOneOwnInOrgFx(formValues).then(() => {
        if (onSuccess) onSuccess();
      });
  };

  return (
    <form onSubmit={handleSubmit} style={{ flex: 1 }}>
      <FormContainer>
        <MyTextInput
          autoFocus
          placeholder='Название'
          autoComplete='off'
          size='lg'
          effectorField={fields.name as ConnectedField<string | null>}
        />
        <MyTextInput
          placeholder='Описание'
          autoComplete='off'
          size='lg'
          effectorField={fields.dscr as ConnectedField<string | null>}
        />

        <Button
          leftSection={<UploadIcon />}
          type='submit'
          loading={isLoadingCreate || isLoadingEdit}
          disabled={!isDirty || !eachValid}>
          {t('SHARED.commonVerbs.save')}
        </Button>
      </FormContainer>
    </form>
  );
};
