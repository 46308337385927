import {
  T,
  __,
  always,
  anyPass,
  both,
  complement,
  compose,
  cond,
  equals,
  modulo,
} from 'ramda';

export const getReviewEnding = compose(
  cond([
    [both(equals(1), complement(equals(11))), always('отзыв')],
    [
      both(
        anyPass([equals(2), equals(3), equals(4)]),
        complement(anyPass([equals(12), equals(13), equals(14)])),
      ),
      always('отзыва'),
    ],
    [T, always('отзывов')],
  ]),
  modulo(__, 100),
);

export const getGradeEnding = compose(
  cond([
    [both(equals(1), complement(equals(11))), always('оценка')],
    [
      both(
        anyPass([equals(2), equals(3), equals(4)]),
        complement(anyPass([equals(12), equals(13), equals(14)])),
      ),
      always('оценки'),
    ],
    [T, always('оценок')],
  ]),
  modulo(__, 100),
);
