import {
  Avatar,
  Button,
  Chip,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Pill,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useUnit } from 'effector-react';
import {
  $orgSelectedCategoryItemIds,
  $orgSelectedPositionIds,
  orgSelectedPositionIdsChange,
} from 'entities/app-flows/org-flow';
import { $categoryTitlesAllCurrent } from 'entities/category';
import { employeeEditManyFx } from 'entities/employee';
import {
  EditStatusActionsType,
  EmployeeEntityType,
  RoleValueType,
  StatusValueType,
} from 'entities/entities-types';
import { getPic } from 'entities/files';

import { assoc, equals, has, includes, omit, or, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { CameraOff as IconCameraOff, Slash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { isNilOrEmpty } from 'shared/lib';
import { IconUntie } from 'shared/model/icons';

type PropTypes = ContextModalProps<{
  employees: EmployeeEntityType[];
  allowedActions: EditStatusActionsType[];
  onSuccess?: () => void;
}>;

const ManageEmployeesModals = ({ context, id, innerProps }: PropTypes) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { allowedActions, employees, onSuccess } = innerProps;
  const {
    0: categoryTitlesAllCurrent,
    1: orgSelectedCategoryItemIds,
    2: editIsLoading,
    3: selectedPositionIds,
  } = useUnit([
    $categoryTitlesAllCurrent,
    $orgSelectedCategoryItemIds,
    employeeEditManyFx.pending,
    $orgSelectedPositionIds,
  ]);

  // const a = employees[0].employeeCategoryItems

  // const initialSelectedPositionIds = ifElse(
  //   pipe(length, equals(1)),
  //   pipe(head,prop('employeeCategoryItems')),
  //   always({}),
  // );

  const [emps, setEmps] = useState(employees);
  useEffect(() => {
    if (emps.length > 0) return;
    context.closeModal(id);
  }, [emps]);

  const [selectedRoleValue, setSelectedRoleValue] = useState<
    RoleValueType | undefined
  >(undefined);

  const handleSubmit = (status: StatusValueType) => {
    employeeEditManyFx({
      employeeIds: employees?.map((employee) => employee?.employeeId as number),
      ...(selectedRoleValue && { roleValue: selectedRoleValue }),
      status: status,
      categoryTitleMap: selectedPositionIds,
    })
      .then(() => {
        onSuccess?.();
        context.closeModal(id);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Stack gap={24} p={0} mih={400}>
      <LoadingOverlay visible={editIsLoading} />
      <Group>
        {emps.map((emp) => (
          <Pill
            p={0}
            size='lg'
            key={emp?.employeeId}
            styles={{
              label: { display: 'flex', gap: 5, alignItems: 'center' },
            }}
            withRemoveButton
            onRemove={() => {
              setEmps(
                emps.filter((item) => item.employeeId !== emp?.employeeId),
              );
            }}>
            <Avatar
              variant='transparent'
              size='sm'
              radius={1000}
              src={getPic(emp?.employeeUser?.avatar)}>
              <IconCameraOff size='50%' />
            </Avatar>
            <Text>{or(emp?.employeeUser?.firstname, '-')}</Text>
          </Pill>
        ))}
      </Group>

      <Divider />

      {includes('promote', allowedActions) && (
        <Stack>
          <Stack>
            <Title fw={600} order={4}>
              Роль сотрудника
            </Title>
            <Group>
              <Chip
                styles={{
                  iconWrapper: { display: 'none' },
                  label: { padding: '0px 20px' },
                }}
                variant='light'
                checked={equals('manager', selectedRoleValue)}
                onClick={() => setSelectedRoleValue('manager')}>
                {t('ROLE.components.roleValue.manager')}
              </Chip>
              <Chip
                styles={{
                  iconWrapper: { display: 'none' },
                  label: { padding: '0px 20px' },
                }}
                variant='light'
                checked={equals('employee', selectedRoleValue)}
                onClick={() => setSelectedRoleValue('employee')}>
                {t('ROLE.components.roleValue.employee')}
              </Chip>
              <Chip
                styles={{
                  iconWrapper: { display: 'none' },
                  label: { padding: '0px 20px' },
                }}
                variant='light'
                checked={isNilOrEmpty(selectedRoleValue)}
                onClick={() => setSelectedRoleValue(undefined)}>
                <Flex align='center' gap={8}>
                  Не изменять <Slash size={15} />
                </Flex>
              </Chip>
            </Group>
          </Stack>
          <Divider />
        </Stack>
      )}

      {categoryTitlesAllCurrent
        ?.filter((t) => or(t?.categoryTitleItems?.length, 0) > 0)
        ?.map((title) => {
          // ...простите меня за это... (меня укусил GoLang)
          const ctid = String(title.categoryTitleId);

          return (
            <Stack key={title.categoryTitleId}>
              <Title fw={600} order={4}>
                {title.name}
              </Title>
              <Group>
                {title?.categoryTitleItems?.map?.((item) => (
                  <Chip
                    styles={{
                      iconWrapper: { display: 'none' },
                      label: { padding: '0px 20px' },
                    }}
                    variant='light'
                    key={item.categoryItemId}
                    checked={equals(
                      item.categoryItemId,
                      // Object.values(selectedPositionIds)[0],
                      prop(ctid, selectedPositionIds),
                    )}
                    onClick={() => {
                      orgSelectedPositionIdsChange(
                        assoc(ctid, item.categoryItemId, selectedPositionIds),
                      );
                    }}>
                    {item.name}
                  </Chip>
                ))}

                <Chip
                  variant='light'
                  styles={{
                    iconWrapper: { display: 'none' },
                    label: { padding: '0px 20px' },
                  }}
                  checked={!has(ctid, selectedPositionIds)}
                  onClick={() => {
                    orgSelectedPositionIdsChange(
                      omit([ctid], selectedPositionIds),
                    );
                  }}>
                  <Flex align='center' gap={8}>
                    Не изменять <Slash size={15} />
                  </Flex>
                </Chip>
                <Chip
                  styles={{
                    iconWrapper: { display: 'none' },
                    label: { padding: '0px 20px' },
                  }}
                  variant='light'
                  checked={equals(0, prop(ctid, selectedPositionIds))}
                  onClick={() => {
                    orgSelectedPositionIdsChange(
                      assoc(ctid, 0, selectedPositionIds),
                    );
                  }}>
                  <Flex align='center' gap={8}>
                    Сбросить <IconUntie />
                  </Flex>
                </Chip>
              </Group>
            </Stack>
          );
        })}

      <Flex mt={10} gap={10}>
        {/* <Divider /> */}
        {includes('deny', allowedActions) && (
          <Button
            radius={12}
            variant='light'
            bg='gray.1'
            c='red'
            flex={1}
            onClick={() => handleSubmit('denied')}>
            Отклонить
          </Button>
        )}

        {includes('fire', allowedActions) && (
          <Button
            radius={12}
            variant='light'
            bg='gray.1'
            c='red'
            flex={1}
            onClick={() => handleSubmit('fired')}>
            Уволить
          </Button>
        )}
        {includes('approve', allowedActions) && (
          <Button radius={12} flex={1} onClick={() => handleSubmit('approved')}>
            Принять
          </Button>
        )}

        {includes('edit', allowedActions) && (
          <Button radius={12} flex={1} onClick={() => handleSubmit('approved')}>
            Применить
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export default ManageEmployeesModals;
