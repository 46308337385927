import {
  Avatar,
  getThemeColor,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  employeeSwitchOneOwnFx,
  useGetEmployeeRoleText,
} from 'entities/employee';
import { EmployeeEntityType, StatusValueType } from 'entities/entities-types';
import { getPic } from 'entities/files';

import { always, anyPass, cond, or, pathEq, propEq, T } from 'ramda';
import { ReactNode } from 'react';
import { Check, Info } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getHref } from 'shared/lib';
import { IconFallbackOrg } from 'shared/model/vector-images';
import { NavLinkBorder } from 'shared/ui/linkBorder';

type PropsType = {
  employee: EmployeeEntityType;
  isSelected: boolean;
};

export const OwnEmployeeSwitcherItem = ({
  employee,
  isSelected,
}: PropsType) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const brandColor = getThemeColor('brand', theme);
  const warnColor = getThemeColor('orange', theme);
  const errorColor = getThemeColor('red', theme);
  const commonColor = getThemeColor('green', theme);
  const getRoleText = useGetEmployeeRoleText();
  const currentRoleValue = employee.employeeRole?.roleValue;

  const handleNavigateOnSuccess = () => {
    if (navBackHref)
      navigate(
        getHref({
          '/public': {
            currentView: 'qr',
            ba: '',
            uid: '',
            oid: '',
            eid: String(employeeId),
          },
        }),
      );
    else if (currentRoleValue === 'manager' || currentRoleValue === 'owner')
      navigate('/org');
    else navigate('/home');
  };

  const navBackHref = searchParams.get('navBackHref');
  const employeeId = or(employee?.employeeId, 0);
  const handleEmployeeClick = () => {
    employeeSwitchOneOwnFx({ employeeId }).then(handleNavigateOnSuccess);
  };

  const infoIcon = (
    <ThemeIcon variant='transparent' color='gray'>
      <Info />
    </ThemeIcon>
  );

  const handleInfoClick = () =>
    modals.openContextModal({
      modal: 'employmentInfoModal',
      title: 'Информация о записи',
      innerProps: { employee },
    });

  const getBarParams = cond<
    EmployeeEntityType[],
    {
      text: string;
      color: string | undefined;
      isOrgApp: boolean;
      rightSection: ReactNode | undefined;
      isDisabled: boolean;
      onClick: (() => void) | undefined;
    }
  >([
    /* -- ORG STATUS --- */
    [
      anyPass([
        pathEq('considering' as StatusValueType, ['employeeOrg', 'status']),
        pathEq('registered' as StatusValueType, ['employeeOrg', 'status']),
        pathEq('received' as StatusValueType, ['employeeOrg', 'status']),
      ]),
      always({
        text: 'Заявка на рассмотрении',
        color: warnColor,
        isOrgApp: true,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    [
      pathEq('denied' as StatusValueType, ['employeeOrg', 'status']),
      always({
        text: 'Заявка отклонена',
        color: errorColor,
        isOrgApp: true,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    [
      pathEq('removed' as StatusValueType, ['employeeOrg', 'status']),
      always({
        text: 'Организация закрыта',
        color: errorColor,
        isOrgApp: true,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    [
      pathEq('canceled' as StatusValueType, ['employeeOrg', 'status']),
      always({
        text: 'Заявка отменена вами',
        color: warnColor,
        isOrgApp: true,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    /* -- ORG STATUS --- */

    /* -- EMPLOYEE STATUS --- */
    [
      anyPass([
        pathEq('considering' as StatusValueType, ['status']),
        pathEq('registered' as StatusValueType, ['status']),
        pathEq('received' as StatusValueType, ['status']),
      ]),
      always({
        text: 'Заявка на рассмотрении',
        color: warnColor,
        isOrgApp: false,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    [
      propEq('canceled' as StatusValueType, 'status'),
      always({
        text: 'Вы отменили заявку',
        color: errorColor,
        isOrgApp: false,
        rightSection: infoIcon,
        isDisabled: true,
        onClick: handleInfoClick,
      }),
    ],
    [
      propEq('fired' as StatusValueType, 'status'),
      always({
        text: 'Вас исключили из организации',
        color: errorColor,
        isOrgApp: false,
        rightSection: infoIcon,
        isDisabled: true,
        onClick: handleInfoClick,
      }),
    ],
    [
      propEq('denied' as StatusValueType, 'status'),
      always({
        text: 'Заявка отклонена',
        color: errorColor,
        isOrgApp: false,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    [
      propEq('removed' as StatusValueType, 'status'),
      always({
        text: 'Удалено',
        color: errorColor,
        isOrgApp: false,
        rightSection: infoIcon,
        isDisabled: false,
        onClick: handleInfoClick,
      }),
    ],
    /* -- EMPLOYEE STATUS --- */

    /* -- REST --- */
    [
      T,
      always({
        text: getRoleText(or(employee.employeeRole?.roleValue, '')),
        color: undefined,
        isOrgApp: false,
        rightSection: isSelected && (
          <ThemeIcon variant='transparent'>
            <Check />
          </ThemeIcon>
        ),
        // <ActionIcon
        //   size='xl'
        //   radius={1000}
        //   variant='light'
        //   aria-label='LogOut'
        //   onClick={(event) => {
        //     handleEmployeeLogout();
        //     event.stopPropagation();
        //   }}>
        //   <LogOut size='66%' />
        // </ActionIcon>
        // : (
        //   <ThemeIcon variant='transparent'>
        //     <Check />
        //   </ThemeIcon>
        // )
        isDisabled: false,
        onClick: isSelected ? handleInfoClick : handleEmployeeClick,
      }),
    ],
  ]);

  const {
    isOrgApp,
    color,
    text,
    rightSection,
    onClick,
    isDisabled,
  } = getBarParams(employee);

  return (
    <NavLinkBorder
      onClick={onClick}
      rightSection={rightSection}
      opacity={1}
      h={60}
      label={<Text fz={16}>{employee.employeeOrg?.name}</Text>}
      description={
        <Text fz={14} c={color}>
          {text}
        </Text>
      }
      leftSection={
        <Avatar
          variant='transparent'
          radius={1000}
          src={getPic(employee?.employeeOrg?.avatar)}>
          <IconFallbackOrg />
        </Avatar>
      }
    />
  );
};
