import { Image } from '@mantine/core';
import { FeedbackGradeValueType } from 'entities/entities-types';
import { MoreHorizontal as MoreHorizontalIcon } from 'react-feather';
import {
  ImgGradeBad,
  ImgGradeExcellent,
  ImgGradeGood,
} from 'shared/model/raster-images';

type PropsType = {
  feedbackGrade: FeedbackGradeValueType | undefined;
  size?: string | number | undefined;
  color?: string;
};

export const FeedbackGradeIcon = ({
  feedbackGrade,
  size = '100%',
  color,
}: PropsType) => {
  let icon = <MoreHorizontalIcon size={size} color={color} />;

  // if (feedbackGrade === 5) icon = <IconExcellent width={size} color={color} />;
  // if (feedbackGrade === 3) icon = <IconGood width={size} color={color} />;
  // if (feedbackGrade === 1) icon = <IconOk width={size} color={color} />;

  if (feedbackGrade === 5)
    icon = <Image w={size} h={size} src={ImgGradeExcellent} loading='lazy' />;
  if (feedbackGrade === 3)
    icon = <Image w={size} h={size} src={ImgGradeGood} loading='lazy' />;
  if (feedbackGrade === 1)
    icon = <Image w={size} h={size} src={ImgGradeBad} loading='lazy' />;

  return icon;
};
