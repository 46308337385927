import { createStore } from 'effector';
import { collapsableBoxChildrenEdit } from 'entities/events';
import {
  headerCollapsableBoxStateEdited,
  headerEdited,
  headerMaxHeightStateEdited,
  headerReset,
} from './events';
import {
  CollapsableBoxStateType,
  HeaderMaxHeightStateType,
  HeaderStateType,
} from './types';

export const $header = createStore<HeaderStateType>({
  background: 'common',
  navTitle: null,
  navBarHeight: 70,
  navBarRightSection: null,
  navBarBackButtonAction: null,
  collapsableBoxChildren: null,
  contentMaxWidth: 600,
  contentBg: '#FFFFFF',
  padding: 16,
})
  .on(headerEdited, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(collapsableBoxChildrenEdit, (state, payload) => {
    setTimeout(() => headerEdited({ collapsableBoxChildren: payload }), 300);
    return {
      ...state,
      collapsableBoxChildren: undefined,
    };
  })
  .reset(headerReset);

export const $headerCollapsableBoxParams = createStore<CollapsableBoxStateType>(
  { scrollY: 0, opacity: 1, gap: 15 },
)
  .on(headerCollapsableBoxStateEdited, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .reset(headerReset);

export const $headerMaxHeight = createStore<HeaderMaxHeightStateType>(600)
  .on(headerMaxHeightStateEdited, (_, newHeight) => newHeight)
  .reset(headerReset);
