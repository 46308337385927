import { DefaultMantineColor, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ReactNode } from 'react';

export function useNotifications() {
  const theme = useMantineTheme();
  return (
    status: 'success' | 'error' | 'info',
    toastTitle: string,
    toastMessage: ReactNode,
    autoClose?: number,
  ) => {
    let color: DefaultMantineColor = 'blue';
    if (status === 'error') color = 'red';
    if (status === 'success') color = 'green';
    notifications.show({
      color: color,
      title: toastTitle,
      message: toastMessage,
      autoClose: autoClose ?? 3000,
      withBorder: true,
    });
  };
}
