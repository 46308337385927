import { EmployeeGetOneResType } from 'entities/employee/endpoints';
import { EmployeeEntityType, RoleEntityType } from 'entities/entities-types';
import { assoc, pipe } from 'ramda';

export const getEmployeeFromXuevyiEmp = (
  xueta: EmployeeGetOneResType['data'] | undefined,
): EmployeeEntityType | null => {
  if (!xueta) return null;
  const newCatItems =
    xueta?.employeeCategoryItems?.map?.((s) => s.employeeMetaItem) || [];
  const newRole: RoleEntityType = { roleValue: xueta.employeeRole, roleId: 10 };
  const newEmp = pipe(
    assoc('employeeCategoryItems', newCatItems),
    assoc('employeeRole', newRole),
  )(xueta);

  return newEmp;
};
