import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_SHARING_RULE } from 'entities/sharing-rule/endpoints';

export const sharingRulePutAndDeleteManyOwnFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.PUT_AND_DELETE_MANY_OWN.req,
  typeof ENDPOINTS_SHARING_RULE.PUT_AND_DELETE_MANY_OWN.res
>({
  fxName: 'sharingRulePutAndDeleteManyFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.PUT_AND_DELETE_MANY_OWN,
});

export const sharingRuleGetAllOwnFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_OWN.req,
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_OWN.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.GET_ALL_OWN,
});
export const sharingRuleGetAllOwnInOrgFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_OWN_IN_ORG.req,
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_OWN_IN_ORG.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.GET_ALL_OWN_IN_ORG,
});
export const sharingRuleGetAllFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.GET_ALL.req,
  typeof ENDPOINTS_SHARING_RULE.GET_ALL.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.GET_ALL,
});
export const sharingRuleGetAllCategoryItemDonorsFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_CATEGORY_ITEM_DONORS.req,
  typeof ENDPOINTS_SHARING_RULE.GET_ALL_CATEGORY_ITEM_DONORS.res
>({
  fxName: false,
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.GET_ALL_CATEGORY_ITEM_DONORS,
});
export const sharingRuleDeleteManyOwnFx = createApiEffect<
  typeof ENDPOINTS_SHARING_RULE.DELETE_MANY_OWN.req,
  typeof ENDPOINTS_SHARING_RULE.DELETE_MANY_OWN.res
>({
  fxName: 'sharingRuleDeleteManyFx',
  service: 'TIPS',
  endpoint: ENDPOINTS_SHARING_RULE.DELETE_MANY_OWN,
});
