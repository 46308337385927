import { createStore } from 'effector';
import {
  TransactionEntityType,
  TransactionStatisticsType,
} from 'entities/entities-types';
import * as ef from './effects';

export const $transactionManyOwn = createStore<TransactionEntityType[]>([]);
export const $transactionProgress = createStore<TransactionEntityType[]>([]);

$transactionManyOwn.on(ef.transactionGetManyFx.doneData, (store, payload) => {
  const doneData = payload.data;
  return [...store, ...doneData];
});

export const $currentTransactionStatistics = createStore<
  TransactionStatisticsType | {}
>({}).on(ef.transactionGetStatisticsFx.doneData, (state, payload) => {
  return payload.data;
});
