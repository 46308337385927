import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { userGetOneFx } from 'entities/user/effects';
import { ENDPOINTS_USER } from 'entities/user/endpoints';

export const $$userOneForFeedbackPage = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_USER.GET_ONE.req,
  typeof ENDPOINTS_USER.GET_ONE.res
>({
  initialReq: { userId: 0 },
  initialFx: userGetOneFx,
});
