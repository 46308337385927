import { BalanceEntityType } from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api/types';

export const ENDPOINTS_BALANCE = {
  GET_ONE_OWN: {
    path: 'balance/get_one_own',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: { data: { amount: 12500 } } as TipsApiResType<BalanceEntityType>,
  },
  GET_ONE_OWN_IN_ORG: {
    path: 'balance/get_one_own_in_org',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: { data: { amount: 12500 } } as TipsApiResType<BalanceEntityType>,
  },
};
