import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { visitGetManyFx } from 'entities/visit/effects';
import { ENDPOINTS_VISIT } from 'entities/visit/endpoints';

export const $$visitManyForHomePage = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_VISIT.GET_MANY.req,
  typeof ENDPOINTS_VISIT.GET_MANY.res
>({
  initialReq: {
    currentPage: 1,
    itemsPerPage: 100,
    userIds: [0],
  },
  initialFx: visitGetManyFx,
});
