import { Stack, Text, Title } from '@mantine/core';
import { useHeader } from 'features/header';

const RestrictedGoods = () => {
  useHeader({ navTitle: 'Перечень запрещенных товаров и услуг' });
  return (
    <Stack>
      <Title order={5} ta='justify'>
        Перечень товаров и услуг, запрещенных к продаже дистанционным способом
        посредством осуществления расчетов с организациями электронной торговли
        с использованием банковских карт в сети Интернет (в рамках оказания
        Банком услуг Интернет эквайринга).
      </Title>
      <Title order={6}>
        Настоящий перечень может быть дополнен и изменен Банком в одностороннем
        порядке.
      </Title>
      <Text>
        1. Вооружение, боеприпасы к нему, военная техника, запасные части,
        комплектующие изделия и приборы к ним, взрывчатые вещества, средства
        взрывания, все виды ракетного топлива, а также специальные материалы и
        специальное оборудование для их производства, специальное снаряжение
        военизированных организаций и нормативно-техническая продукция на их
        производство и эксплуатацию.
      </Text>
      <Text>
        2. Ракетно-космические комплексы, системы связи и управления военного
        назначения и нормативно-техническая документация на их производство и
        эксплуатацию.
      </Text>
      <Text>
        3. Боевые отравляющие вещества, средства защиты от них и
        нормативно-техническая документация на их производство и использование.
      </Text>
      <Text>
        4. Результаты научно-исследовательских и проектных работ, а также
        фундаментальных поисковых исследований по созданию вооружения и военной
        техники.
      </Text>
      <Text>
        5. Услуги, работы и материалы, связанные с осуществлением военной службы
        и военизированной деятельности.
      </Text>
      <Text>
        6. Любое оружие, в том числе охотничье, гражданское и иное, а также
        комплектующие изделия к нему, ножи (за исключением кухонных, перочинных,
        канцелярских), боеприпасы.
      </Text>
      <Text>
        7. Радиоактивные вещества и изотопы, уран и другие делящиеся материалы и
        изделия из них.
      </Text>
      <Text>8. Отходы радиоактивных материалов.</Text>
      <Text>
        9. Драгоценные и редкоземельные металлы, драгоценные камни, а также
        отходы, содержащие драгоценные и редкоземельные металлы и драгоценные
        камни.
      </Text>
      <Text>
        10. Рентгеновское оборудование, приборы и оборудование с использованием
        радиоактивных веществ и изотопов.
      </Text>
      <Text>
        11. Яды, наркотические средства и психотропные вещества, их прекурсоры,
        подлежащие контролю в Российской Федерации или в ином государстве, на
        территории которого Предприятие осуществляет свою деятельность.
      </Text>
      <Text>12. Спирт этиловый, алкогольные напитки.</Text>
      <Text>
        13. Лекарственные препараты, отпускаемые по рецепту, а также
        наркотические, психотропные и спиртосодержащие (с объемной долей
        этилового спирта свыше 25 процентов) лекарственные препараты и бальзамы
        на основе спирта.
      </Text>
      <Text>
        14. Лекарственное сырье, получаемое от северного оленеводства (панты и
        эндокринное сырье).
      </Text>
      <Text>
        15. Табачная продукция, никотинсодержащая продукция, кальяны, устройства
        для потребления никотинсодержащей продукции.
      </Text>
      <Text>
        16. Шифровальная техника, и нормативно-техническая документация на ее
        производство и использование.
      </Text>
      <Text>17. Поддельные денежные знаки.</Text>
      <Text>
        18. Иностранная валюта и иные валютные ценности, монеты и банкноты
        Российской Федерации, находящиеся в обращении.
      </Text>
      <Text>
        19. Радиоэлектронные и специальные технические средства, предназначенные
        для негласного получения информации, а также высокочастотные устройства,
        состоящих из одного или нескольких радиопередающих устройств и (или) их
        комбинаций и вспомогательного оборудования, предназначенных для передачи
        и приема радиоволн на частоте выше 8 ГГц.
      </Text>
      <Text>
        20. Материалы и услуги, нарушающие тайну частной жизни, посягающие на
        честь, достоинство и деловую репутацию граждан и юридических лиц, а
        также содержащие государственную, банковскую, коммерческую и иную тайны.
      </Text>
      <Text>
        21. Государственные награды РФ, РСФСР, СССР, а также их копии.
      </Text>
      <Text>
        22. Государственные удостоверения личности, знаки, пропуска, разрешения,
        сертификаты, проездные документы и лицензии, а также иные документы,
        предоставляющие права или освобождающие от прав/обязанностей, бланки для
        этих документов, а также услуги по их получению.
      </Text>
      <Text>
        23. Объекты культурного наследия народов Российской Федерации, а также
        объекты археологического наследия.
      </Text>
      <Text>24. Человеческие органы и ткани, а также донорские услуги.</Text>
      <Text>
        25. Животные и растения, занесенные в Красную книгу Российской Федерации
        и Красные книги субъектов Российской Федерации, части и органы животных,
        занесенных в Красную книгу Российской Федерации и Красные книги
        субъектов Российской Федерации, а также животные и растения, охраняемые
        международными договорами Российской Федерации.
      </Text>
      <Text>
        26. Шкуры и изделия из шкур редких и находящихся под угрозой
        исчезновения видов животных в соответствии с действующим
        законодательством Российской Федерации.
      </Text>
      <Text>
        27. Рыболовные сети, материалы для их изготовления, а также услуги по их
        изготовлению, электроудочки и капканы, запрещенные к реализации на
        территории Российской Федерации.
      </Text>
      <Text>
        28. Экстремистские материалы, материалы, призывающие к массовым
        беспорядкам, осуществлению террористической деятельности и
        экстремистской деятельности, к участию в массовых публичных
        мероприятиях, разжиганию межнациональной и межконфессиональной розни.
      </Text>
      <Text>
        29. Предметы с нацистской символикой или символикой запрещенных в
        Российской Федерации организаций.
      </Text>
      <Text>30. Контрафактная или краденая продукция или имущество.</Text>
      <Text>
        31. Базы данных, в том числе содержащие персональные данные, которые
        могут способствовать несанкционированным рассылкам.
      </Text>
      <Text>
        32. Материалы, передаваемые исключительно виртуально и не записанные на
        какой-либо материальный носитель (идеи, методы, принципы и т.д.).
      </Text>
      <Text>
        33. Игровое оборудование, используемое для проведения азартных игр,
        лотерейное оборудование, оказание услуг по приему ставок для участия в
        азартных играх в сети Интернет, прием платежей за лотерейные билеты,
        квитанции и иные документы, удостоверяющие право на участие в лотерее, а
        также продажа виртуальной валюты и др.
      </Text>
      <Text>
        34. Документы на транспортные средства, государственные номера на
        транспортные средства.
      </Text>
      <Text>
        35. Товары, оборот которых нарушает интеллектуальные права третьих лиц
        (в том числе патенты, товарные знаки, авторские права и др.).
      </Text>
      <Text>
        36. Инвестиционные услуги, операции с денежными средствами и
        криптовалютами, а также товары и услуги, приобретение/пользование
        которыми, гарантированно приносит заработок и прибыль.
      </Text>
      <Text>
        37. Товары и услуги, реализуемые организацией многоуровневого сетевого
        маркетинга, деятельность которых основана на создании сети независимых
        дистрибьюторов (сбытовых агентов).
      </Text>
      <Text>
        38. Услуги и/или работа интимного/эротического/сексуального характера, а
        также порнографические/эротические материалы, а также предоставление
        доступа к информации порнографического характера.
      </Text>
      <Text>
        39. Товары/услуги, использование которых может быть направлено на
        нарушение действующего законодательства Российской Федерации.
      </Text>
      <Text>
        40. Несуществующие товары или услуги, а также товары/услуги, не имеющие
        потребительской ценности.
      </Text>
      <Text>41. Трансцендентные услуги и услуги нетрадиционной медицины.</Text>
      <Text>
        42. Услуги по замене лицензионного программного обеспечения или
        нарушению работы установленных правообладателем средств технической
        защиты телефонов, смартфонов, ноутбуков, навигаторов, персональных
        компьютеров и пр.
      </Text>
      <Text>
        43. Иные товары/услуги, оборот которых запрещен или ограничен согласно
        законодательству Российской Федерации, а также способен оказать
        негативное влияние на деловую репутацию Банка и/или Международных
        платежных систем.
      </Text>
      <Text>
        44. Инъекционные препараты и растворы, а также вещества, применяемые для
        их изготовления.
      </Text>
      <Text>
        45. Услуги, работы и материалы, связанные с осуществлением деятельности
        оккультных организаций и сект.
      </Text>
      <Text>
        46. Товары и услуги, реализуемые компаниями по форме организации
        финансовых пирамид.
      </Text>
      <Text>47. Антиквариат.</Text>
      <Text>48. Биологически активные добавки (БАДы).</Text>
      <Text>
        49. Услуги онлайн-казино, казино вне выделенных игровых зон,
        определенных законодательством Российской Федерации, или за пределами
        Российской Федерации.
      </Text>
      <Text>
        50. Услуги по организации и проведению азартных игр в букмекерских
        конторах за пределами Российской Федерации.
      </Text>
      <Text>
        51. Брокерские услуги на финансовых рынках или услуги форекс-дилера,
        осуществляемая без лицензии Банка России или за пределами Российской
        Федерации.
      </Text>
      <Text>
        52. Цифровые финансовые активы и цифровые валюты за пределами Российской
        Федерации.
      </Text>
      <Text>
        53. Реализация товаров, а также переводы без цели оплаты услуги/чаевых
        за услугу.
      </Text>
    </Stack>
  );
};

export default RestrictedGoods;
