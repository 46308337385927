import { LoadingOverlay } from '@mantine/core';
import { T, always, cond, equals } from 'ramda';
import { ReactNode, Suspense, lazy } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PATHS } from 'shared/model/const';
import { AnimatedSwitchFade300ms } from 'shared/ui/switch-transition-animation';
import UserMainPage from './main';

// const Main = lazy(() => import('pages/user/main'));
// const Main = () => import('pages/user/main');
const MyFioChange = lazy(() => import('pages/user/my-fio-change'));

const MyOldEmail = lazy(() =>
  import('pages/user/email-change').then((module) => ({
    default: module.MyOldPhone,
  })),
);

const MyNewEmail = lazy(() =>
  import('pages/user/email-change').then((module) => ({
    default: module.MyNewPhone,
  })),
);
const ContactCheckEmail = lazy(() =>
  import('pages/user/email-change').then((module) => ({
    default: module.ContactCheck,
  })),
);

const MyOldPhone = lazy(() =>
  import('pages/user/phone-change').then((module) => ({
    default: module.MyOldPhone,
  })),
);
const MyNewPhone = lazy(() =>
  import('pages/user/phone-change').then((module) => ({
    default: module.MyNewPhone,
  })),
);

const MyOldPassword = lazy(() =>
  import('pages/user/password-change').then((module) => ({
    default: module.MyOldPassword,
  })),
);
const MyNewPassword = lazy(() =>
  import('pages/user/password-change').then((module) => ({
    default: module.MyNewPassword,
  })),
);
const ContactCheck = lazy(() =>
  import('pages/user/phone-change').then((module) => ({
    default: module.ContactCheck,
  })),
);
const MyEmailChange = lazy(() => import('pages/user/my-email-change'));
const MyPasswordChange = lazy(() => import('pages/user/my-password-change'));
const OrgApplication = lazy(() => import('pages/user/org-application'));
const MyStatistics = lazy(() => import('pages/user/my-statistics'));
const AddingGoal = lazy(() => import('pages/user/my-goal-add'));
const MyGoalPage = lazy(() => import('pages/user/my-goal'));
const MyEditGoal = lazy(() => import('pages/user/my-goal-edit'));
const MySharingRules = lazy(() => import('pages/user/my-sharing-rules'));
const MyBalanceWithdrawal = lazy(
  () => import('pages/user/my-balance-withdrawal'),
);

type ViewType = typeof PATHS['/user']['currentView'];

const Page = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get('currentView') as ViewType;
  const getCurrentView = cond<ViewType[], ReactNode>([
    [equals('my-statistics' as ViewType), always(<MyStatistics />)],
    [equals('balance-withdrawal' as ViewType), always(<MyBalanceWithdrawal />)],
    [equals('org-application' as ViewType), always(<OrgApplication />)],
    [equals('my-fio-change' as ViewType), always(<MyFioChange />)],
    [equals('my-phone-change' as ViewType), always(<MyOldPhone />)],
    [equals('my-new-phone' as ViewType), always(<MyNewPhone />)],
    [equals('contact-check' as ViewType), always(<ContactCheck />)],
    [equals('my-email-change' as ViewType), always(<MyOldEmail />)],
    [equals('my-new-email' as ViewType), always(<MyNewEmail />)],
    [equals('contact-check-email' as ViewType), always(<ContactCheckEmail />)],
    // [equals('my-email-change' as ViewType), always(<MyEmailChange />)],
    [equals('my-new-password' as ViewType), always(<MyNewPassword />)],
    [equals('my-password-change' as ViewType), always(<MyOldPassword />)],
    // [equals('my-password-change' as ViewType), always(<MyPasswordChange />)],
    [equals('my-goal-add' as ViewType), always(<AddingGoal />)],
    [equals('my-goal' as ViewType), always(<MyGoalPage />)],
    [equals('my-goal-edit' as ViewType), always(<MyEditGoal />)],
    [equals('my-sharing-rules' as ViewType), always(<MySharingRules />)],
    [T, always(<UserMainPage />)],
  ]);

  return (
    <AnimatedSwitchFade300ms watchedKey={currentView || ''}>
      <Suspense
        fallback={
          <LoadingOverlay
            visible={true}
            h='100vh'
            zIndex={1000}
            overlayProps={{ radius: 'xl' }}
            loaderProps={{ type: 'bars' }}
          />
        }>
        {getCurrentView(currentView)}
      </Suspense>
    </AnimatedSwitchFade300ms>
  );
};

export default Page;
