import '@mantine/carousel/styles.css';
import {
  BackgroundImage,
  Box,
  Flex,
  Image,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useIsMobile } from 'shared/lib/hooks';
import { EllipseBg, PageGuest, PageGuest2, TipsQr } from 'shared/model';
import { Condition } from 'shared/ui/display';

import { EmblaCarousel } from './slider';

export const EasyTips = () => {
  const theme = useMantineTheme();
  const isMobile = useIsMobile(900);

  const contentCard = [
    {
      id: 1,
      title: 'Чаевые по QR-коду',
      text: 'Наведите камеру на QR-код',
      img: (
        <BackgroundImage
          h={`${isMobile ? 340 : 450}`}
          ta='center'
          src={EllipseBg}>
          <Image
            style={{ objectFit: 'contain' }}
            display='initial'
            h='100%'
            // w={{ md: 240, base: 200 }}
            src={TipsQr}
          />
        </BackgroundImage>
      ),
    },
    {
      id: 2,
      title: 'Оценка и сумма',
      text: 'Выберите сумму и оцените сотрудника',
      img: (
        <BackgroundImage h={`${isMobile ? 340 : 450}`} ta='end' src={EllipseBg}>
          <Image
            h='100%'
            style={{ objectFit: `${isMobile ? 'initial' : 'cover'}` }}
            // w={{ sm: '100%', base: 200 }}
            display='initial'
            src={PageGuest}
          />
        </BackgroundImage>
      ),
    },
    {
      id: 3,
      title: 'Оплата!',
      text: 'Выберите удобный для вас способ оплаты',
      img: (
        <BackgroundImage h={`${isMobile ? 340 : 450}`} ta='end' src={EllipseBg}>
          <Image
            display='initial'
            h='100%'
            style={{ objectFit: `${isMobile ? 'initial' : 'cover'}` }}
            // w={{ md: '100%', base: 261 }}
            src={PageGuest2}
          />
        </BackgroundImage>
      ),
    },
  ];

  return (
    <Flex
      h={{ sm: 790, base: 690 }}
      direction='column'
      gap={{ base: 24, sm: 64 }}
      id='easy-tips'
      pos='relative'>
      <Box pt={68} h={524} bg='background' style={{ borderRadius: '44px' }}>
        <Title ta='center' fz={{ base: 24, md: 32 }}>
          Чаевые оставить{' '}
          <Text
            px={16}
            py={4}
            style={{ borderRadius: `${isMobile ? '5px' : '16px'}` }}
            c='white'
            fw='inherit'
            fz='inherit'
            bg='brand'
            component='span'>
            легко!
          </Text>
        </Title>
      </Box>
      <Flex
        justify={{ md: 'space-between', base: 'center' }}
        w='100%'
        gap={{ lg: 32, base: 20 }}
        px={{ lg: 64, md: 16, base: 0 }}
        top='20%'
        pos='absolute'>
        <Condition when={!isMobile}>
          {contentCard.map((item) => (
            <Paper
              key={item.title}
              radius={44}
              display='flex'
              style={{
                justifyContent: 'space-between',
                flexDirection: 'column',
                gap: '28px',
                overflow: 'hidden',
              }}
              w={370}
              pb={24}>
              {item.img}

              <Stack h={100} px={24} gap={10}>
                <Text fz={{ lg: 28, base: 24 }} fw={700}>
                  {item.title}
                </Text>
                <Text c='gray'>{item.text}</Text>
              </Stack>
            </Paper>
          ))}
        </Condition>
        <Condition when={isMobile}>
          <EmblaCarousel slides={contentCard} />
        </Condition>
      </Flex>
    </Flex>
  );
};
