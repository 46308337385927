import {
  CloseButton,
  Flex,
  getThemeColor,
  Stack,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ConnectedField } from 'effector-forms';
import { useRef } from 'react';
import { Condition } from 'shared/ui/display';
import { MyInputError } from './common';
import { InputAutocompleteType } from './types';
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules

type PropsType = TextInputProps & {
  effectorField?: ConnectedField<string | null>;
  autoComplete?: InputAutocompleteType;
};
const MyTextInput = (props: PropsType) => {
  let {
    leftSection,
    error,
    effectorField,
    variant = 'filled',
    styles,
    ...propsForInput
  } = props;
  const { ref, height } = useElementSize();
  const gap = 5;
  const theme = useMantineTheme();
  const themeColor = getThemeColor('gray', theme);
  const refInput = useRef<HTMLInputElement>(null);
  if (effectorField)
    propsForInput = {
      ...propsForInput,
      value: effectorField.value ? String(effectorField.value) : '',
      onChange: (e) => effectorField?.onChange(e.target.value),
      onBlur: () => effectorField?.onBlur(),
    };

  const resultErrorText = effectorField?.errorText();
  return (
    <Stack pos='relative' gap={gap}>
      <Flex ref={ref}>
        <TextInput
          radius='12px'
          ref={refInput}
          {...propsForInput}
          flex={1}
          error={Boolean(resultErrorText)}
          variant={variant}
          styles={{
            label: {
              color: themeColor,
              fontSize: '14px',
              marginBottom: '8px',
            },
          }}
          rightSection={
            <Condition
              when={Boolean(effectorField) && Boolean(effectorField?.value)}>
              <CloseButton onClick={() => effectorField?.reset()} />
            </Condition>
          }
        />
      </Flex>
      <MyInputError
        gap={gap}
        resultErrorText={resultErrorText}
        inputHeight={height}
        isLeftIconShown={Boolean(leftSection)}
      />
    </Stack>
  );
};

export default MyTextInput;
