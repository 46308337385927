import { sample } from 'effector';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { userCheckPhoneNumberOccupationFx } from 'entities/user/effects';
import { ENDPOINTS_USER } from 'entities/user/endpoints';
import { $userSignUpForm } from 'entities/user/form';
import { defineTypeOfInput } from 'shared/lib';

export const $$userPhoneOccupation = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_USER.CHECK_PHONE_NUMBER_OCCUPATION.req,
  typeof ENDPOINTS_USER.CHECK_PHONE_NUMBER_OCCUPATION.res
>({
  initialReq: { phoneNumber: '' },
  initialFx: userCheckPhoneNumberOccupationFx,
});

sample({
  clock: $userSignUpForm.fields.phoneOrEmail.$value,
  filter: (clockData) =>
    Boolean(
      clockData &&
        clockData.length > 10 &&
        defineTypeOfInput(clockData, 1) === 'phone',
    ),
  fn: (clockData) => {
    return { phoneNumber: clockData };
  },
  target: $$userPhoneOccupation.argEditEvent,
});

// Изменение статуса isPhoneOccupied на результат
sample({
  clock: $$userPhoneOccupation.$store,
  filter: (clockData) => typeof clockData?.res?.data?.isOccupied === 'boolean',
  fn: (clockData) => {
    return Boolean(clockData?.res?.data?.isOccupied);
  },
  target: $userSignUpForm.fields.isPhoneOccupied.set,
});
