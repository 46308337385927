import { createStore, sample } from 'effector';
import { CategoryTitleEntityType } from 'entities/entities-types';
import { appReset, currentSessionInvalidated } from 'entities/events';
import * as ef from './effects';
import { ENDPOINTS_CATEGORY_ITEM, ENDPOINTS_CATEGORY_TITLE } from './endpoints';
import * as ev from './events';

sample({
  clock: currentSessionInvalidated,
  target: ef.categoryTitleGetAllOwnInOrgFx,
});

sample({
  clock: [
    ef.categoryItemCreateOneOwnInOrgFx.done,
    ef.categoryItemEditOneOwnInOrgFx.done,
    ef.categoryItemDeleteOneOwnInOrgFx.done,
    ef.categoryTitleCreateOneOwnInOrgFx.done,
    ef.categoryTitleEditOneOwnInOrgFx.done,
    ef.categoryTitleDeleteOneOwnInOrgFx.done,
  ],
  target: ef.categoryTitleGetAllOwnInOrgFx,
});

export const $categoryTitlesAllCurrent = createStore<CategoryTitleEntityType[]>(
  [],
)
  .on(ef.categoryTitleGetAllOwnInOrgFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset([appReset]);

export const $categoryTitlePutForm = createStore<
  typeof ENDPOINTS_CATEGORY_TITLE.CREATE_ONE_OWN_IN_ORG.req
>({
  name: '',
  dscr: '',
  avatar: null,
})
  .on(ev.categoryTitlePutFormChanged, (state, payload) => {
    return payload;
  })
  .reset([
    appReset,
    ef.categoryTitleGetAllOwnInOrgFx.done,
    ef.categoryTitleCreateOneOwnInOrgFx.done,
    ef.categoryTitleEditOneOwnInOrgFx.done,
  ]);
export const $categoryItemPutForm = createStore<
  typeof ENDPOINTS_CATEGORY_ITEM.CREATE_ONE_OWN_IN_ORG.req
>({
  name: '',
  dscr: '',
  avatar: null,
  categoryTitleId: 0,
})
  .on(ev.categoryItemPutFormChanged, (state, payload) => {
    return payload;
  })
  .reset([
    appReset,
    ef.categoryTitleGetAllOwnInOrgFx.done,
    ef.categoryTitleCreateOneOwnInOrgFx.done,
    ef.categoryTitleEditOneOwnInOrgFx.done,
  ]);
