import {
  TransactionEntityType,
  TransactionStatisticsType,
} from 'entities/entities-types';
import { HttpMethod, PaginatedReqType, TipsApiResType } from 'shared/api/types';

export const ENDPOINTS_TRANSACTION = {
  GET_ONE_COMMISSION: {
    // path: transaction/get_one_commission,
    path: 'transaction/get_amount_with_commission',
    method: 'post' as HttpMethod,
    req: {
      // В запросе отправляется сумма транзакци,
      // которая получалась бы после вычитании комиссии
      transactionType: 'enrollment',
      netValue: 1000,
    } as {
      netValue: number;
      transactionType: 'enrollment' | 'withdrawal';
    },
    res: {
      // В ответе отправляется сумма транзакции,
      // после вычитания из которой получается целевая сумма доната
      message: 'OK',
      data: {
        // При 7 процентах, потому что в нашем случае
        // 1000 / (1 - 0.07) = 1075.262
        grossAmount: 1075,
      },
    } as TipsApiResType<{
      grossAmount: number;
    }>,
  },

  CREATE_ONE_OWN_WITHDRAWAL: {
    path: `transaction/create_one_own_withdrawal`,
    method: 'post' as HttpMethod,
    req: {} as Pick<TransactionEntityType, 'grossAmount' | 'action'>,
    res: {} as TipsApiResType<void>,
  },

  CHANGE_ONE_INTO_PROGRESS: {
    path: `transaction/change_one_into_progress`,
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<{
      transactionUUID: string | string[] | undefined;
    }>,
    res: {} as TipsApiResType<void>,
  },

  GET_MANY: {
    path: 'transaction/get_many',
    method: 'post' as HttpMethod,
    req: {} as PaginatedReqType<{
      UUIDs?: string[];
      action?: ('payin' | 'payout')[];
      balanceIds?: number[];
      bankCommissionMax?: number;
      bankCommissionMin?: number;
      bankUUIDs?: string[];
      createdAtMax?: string;
      createdAtMin?: string;
      employeeIds?: number[];
      entityType?: string;
      grossAmountMax?: number;
      grossAmountMin?: number;
      itemsPerPage?: number;
      manyIsCommissionCompensated?: boolean[];
      netAmountMax?: number;
      netAmountMin?: number;
      orderIds?: number[];
      orgIds?: number[];
      ourCommissionMax?: number;
      ourCommissionMin?: number;
      statuses?: string[];
      totalCommissionMax?: number;
      totalCommissionMin?: number;
      userIds?: number[];
    }>,
    res: {} as TipsApiResType<TransactionEntityType[]>,
  },

  GET_STATISTICS: {
    path: `transaction/statistics_withdrawal`,
    method: 'post' as HttpMethod,
    req: {} as {
      createdAtMax?: Date;
      createdAtMin?: Date;
      groupBy: 'day' | 'month' | 'all';
      entities: ('user' | 'org')[];
      balanceIds: number[];
    },
    res: {} as TipsApiResType<TransactionStatisticsType>,
  },
};
