import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset, orgOneOwnActiveChanged } from 'entities/events';
import { orgEditOneOwnFx } from 'entities/org/effects';
import { formRules } from 'shared/lib/forms';

export const $orgOwnPhoneChangeForm = createForm<{ phoneNumber: string }>({
  validateOn: ['change', 'submit'],
  fields: {
    phoneNumber: {
      init: '',
      rules: [formRules.phoneIsValid()],
    },
  },
});

sample({
  clock: orgOneOwnActiveChanged,
  fn: (clockData) => {
    return {
      phoneNumber: clockData?.phoneNumber || '',
    };
  },
  target: $orgOwnPhoneChangeForm.setForm,
});

sample({
  clock: [orgEditOneOwnFx.done, appReset],
  target: [$orgOwnPhoneChangeForm.reset],
});
