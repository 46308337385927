import { createStore } from 'effector';
import { toggleNumber } from 'shared/lib';
import {
  orgEmployeesSelectorVisibleChange,
  orgSelectedActiveEmployeesReplaced,
  orgSelectedActiveEmployeesToggled,
  orgSelectedCategoryItemIdsReplaced,
  orgSelectedCategoryItemIdsToggled,
  orgSelectedEmployeeAppsReplaced,
  orgSelectedEmployeeAppsToggled,
  orgSelectedPositionIdsChange,
} from './events';

export const $orgSelectedActiveEmployees = createStore<number[]>([])
  .on(orgSelectedActiveEmployeesToggled, (state, payload) =>
    toggleNumber(payload, state),
  )
  .on(orgSelectedActiveEmployeesReplaced, (state, payload) => payload);

export const $orgSelectedEmployeeApps = createStore<number[]>([])
  .on(orgSelectedEmployeeAppsToggled, (state, payload) =>
    toggleNumber(payload, state),
  )
  .on(orgSelectedEmployeeAppsReplaced, (state, payload) => payload);

export const $orgSelectedCategoryItemIds = createStore<number[]>([])
  .on(orgSelectedCategoryItemIdsToggled, (state, payload) =>
    toggleNumber(payload, state),
  )
  .on(orgSelectedCategoryItemIdsReplaced, (state, payload) => payload);

// const [selectedPositionIds, setSelectedPositionIds] = useState<{
//   [key: string]: number;
// }>({});

export const $orgSelectedPositionIds = createStore<{ [key: string]: number }>(
  {},
).on(orgSelectedPositionIdsChange, (_, payload) => {
  return payload;
});

export const $orgEmployeesSelectorVisible = createStore<boolean>(false).on(
  orgEmployeesSelectorVisibleChange,
  (_, payload) => {
    return payload;
  },
);
