import {
  CategoryItemEntityType,
  CategoryTitleEntityType,
} from 'entities/entities-types';
import { HttpMethod, TipsApiResType } from 'shared/api';

export const ENDPOINTS_CATEGORY_TITLE = {
  // проверяется, что пользователь находится в организации, которой принадлежит CATEGORY_TITLE
  CREATE_ONE_OWN_IN_ORG: {
    path: 'category_title/create_one_own_in_org',
    method: 'post' as HttpMethod,
    req: {
      name: 'Должность',
      dscr: 'Должности, существующие в нашем заведении',
      avatar: null,
    } as Pick<CategoryTitleEntityType, 'name' | 'dscr' | 'avatar'>,
    res: {} as TipsApiResType<void>,
  },

  // меняется DELETED_AT на текущее время, все CATEGORY_ITEM и EMPLOYEE_CATEGORY также получают DELETED_AT
  DELETE_ONE_OWN_IN_ORG: {
    path: 'category_title/delete_one_own_in_org',
    method: 'delete' as HttpMethod,
    req: { categoryItemId: 1 } as Pick<
      CategoryTitleEntityType,
      'categoryTitleId'
    >,
    res: {} as TipsApiResType<void>,
  },

  EDIT_ONE_OWN_IN_ORG: {
    path: 'category_title/edit_one_own_in_org',
    method: 'patch' as HttpMethod,
    req: {
      categoryTitleId: 1,
      name: 'Должность',
      dscr: 'Должности, существующие в нашем заведении',
      avatar: null,
    } as Pick<
      CategoryTitleEntityType,
      'name' | 'dscr' | 'avatar' | 'categoryTitleId'
    >,
    res: {} as TipsApiResType<void>,
  },

  // проверяется, что пользователь находится в организации, которой принадлежит CATEGORY_TITLE
  GET_ALL_OWN_IN_ORG: {
    // path: 'category_title/get_all',
    path: 'category_title/get_all_own_in_org',
    method: 'post' as HttpMethod,
    req: undefined as void,
    res: {
      data: [
        {
          categoryTitleId: 1,
          orgId: 1,
          avatar: null,
          dscr: null,
          name: 'Должность',
          categoryTitleItems: [
            {
              categoryItemId: 1,
              categoryTitleId: 1,
              name: 'Официант',
              dscr: null,
              avatar: null,
            },
            {
              categoryItemId: 2,
              categoryTitleId: 1,
              name: 'Повар',
              dscr: null,
              avatar: null,
            },
          ],
        },
      ],
    } as TipsApiResType<CategoryTitleEntityType[]>,
  },
};

export const ENDPOINTS_CATEGORY_ITEM = {
  // проверяется, что пользователь находится в организации, которой принадлежит CATEGORY_TITLE
  CREATE_ONE_OWN_IN_ORG: {
    path: 'category_item/create_one_own_in_org',
    // path: 'category_item/create_one',
    method: 'post' as HttpMethod,
    req: {
      name: 'Официант',
      dscr: 'Рядовой сотрудник нашего заведения',
      avatar: null,
    } as Pick<
      CategoryItemEntityType,
      'name' | 'dscr' | 'avatar' | 'categoryTitleId'
    >,
    res: {} as TipsApiResType<void>,
  },

  // меняется DELETED_AT на текущее время, все EMPLOYEE_CATEGORY также получают DELETED_AT
  DELETE_ONE_OWN_IN_ORG: {
    path: 'category_item/delete_one_own_in_org',
    method: 'delete' as HttpMethod,
    req: { categoryItemId: 1 } as Pick<
      CategoryItemEntityType,
      'categoryItemId'
    >,
    res: {} as TipsApiResType<void>,
  },

  EDIT_ONE_OWN_IN_ORG: {
    path: 'category_item/edit_one_own_in_org',
    method: 'patch' as HttpMethod,
    req: {
      name: 'Должность',
      dscr: 'Должности, существующие в нашем заведении',
      avatar: null,
    } as Partial<
      Pick<
        CategoryItemEntityType,
        'name' | 'dscr' | 'avatar' | 'categoryItemId'
      >
    >,
    res: {} as TipsApiResType<void>,
  },
};
