import { DefaultMantineColor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  Effect,
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { $translate } from 'entities/store';
import { HttpMethod, TipsApiResType } from 'shared/api';
import { request } from 'shared/api/base-api';
import { responseProcessingKy } from 'shared/api/common';
import { isNilOrEmpty } from 'shared/lib';

type RequestType = 'TIPS' | 'SBP' | 'FILE' | 'API' | 'ALERT';
type CreateApiEffectParams = {
  service: RequestType;
  endpoint: {
    method: HttpMethod;
    path: string;
    res: any;
    req: any;
  };
  fxName: TServerActions | false; // if provided, notification handler is included
};
type NotificationStatusType = 'success' | 'error' | 'info';
export type AppNotifyPropsType = {
  status?: NotificationStatusType;
  toastTitle: string;
  toastMessage: string;
  autoClose?: number;
};

const withErrorNotification = <T, U>(
  effect: Effect<T, U, TipsApiResType<void>>,
  effectName?: string,
) => {
  sample({
    clock: effect.failData,
    source: $translate,
    fn: (t, err) => {
      const status = String(err.status);
      const defaultTitle = t ? t('SERVER_ACTIONS.common.title') : '';

      const defaultMessage = t
        ? t(`SERVER_ACTIONS.common.status.${status}`) ||
          t(`SERVER_ACTIONS.common.status.500`)
        : '';

      const customTitle = t ? t(`SERVER_ACTIONS.${effectName}.title`) : '';
      const customMessage = t
        ? t(`SERVER_ACTIONS.${effectName}.status.${status}`)
        : '';

      return {
        status: 'error',
        toastTitle: customTitle || defaultTitle,
        toastMessage: customMessage || defaultMessage,
        autoClose: 5000,
      } as AppNotifyPropsType;
    },
    target: appNotifyFx,
  });

  return effect;
};

const withSuccessNotification = <T, U>(
  effect: Effect<T, U, TipsApiResType<void>>,
  effectName?: string,
) => {
  sample({
    clock: effect.doneData,
    source: $translate,
    fn: (t, data) => {
      const defaultTitle = t ? t('SERVER_ACTIONS.common.title') : '';
      const defaultMessage = t ? t(`SERVER_ACTIONS.common.status.200`) : '';
      const customTitle = t ? t(`SERVER_ACTIONS.${effectName}.title`) : '';
      const customMessage = t
        ? t(`SERVER_ACTIONS.${effectName}.status.200`) ||
          t(`SERVER_ACTIONS.${effectName}.status.201`)
        : '';

      return {
        status: 'success',
        toastTitle: customTitle || defaultTitle,
        toastMessage: customMessage || defaultMessage,
        autoClose: 5000,
      } as AppNotifyPropsType;
    },
    target: appNotifyFx,
  });

  return effect;
};

export const createApiEffect = <Req, Res>({
  endpoint,
  service,
  fxName, // if provided, notification handler is included
}: CreateApiEffectParams) => {
  const effect = createEffect<Req, Res, TipsApiResType<void>>((req: Req) =>
    responseProcessingKy<Res>(() => {
      if (req instanceof FormData) {
        return request(service)[endpoint.method](endpoint.path, { body: req });
      }
      return request(service)[endpoint.method](endpoint.path, { json: req });
    }),
  );
  if (fxName) {
    withErrorNotification(effect, fxName);
    withSuccessNotification(effect, fxName);
  }
  return effect;
};

export const appNotifyFx = createEffect(
  ({ status, autoClose, toastTitle, toastMessage }: AppNotifyPropsType) => {
    if (isNilOrEmpty(toastMessage) && isNilOrEmpty(toastTitle))
      return undefined;

    let color: DefaultMantineColor = 'blue';
    if (status === 'error') color = 'red';
    if (status === 'success') color = 'green';
    showNotification({
      color: color,
      title: toastTitle,
      message: toastMessage,
      autoClose: autoClose ?? 5000,
      withBorder: true,
    });
  },
);

export function createStoreAndEventAndAttachForEffect<REQ, RES>({
  initialReq,
  initialFx,
}: {
  initialReq: REQ;
  initialFx: Effect<REQ, RES, TipsApiResType<void>>;
}) {
  const $store = createStore<{
    req: REQ;
    res: RES | null;
  }>({
    req: initialReq,
    res: null,
  });
  const attachedFx = attach({
    source: $store.map((state) => state.req),
    effect: initialFx,
  });
  const argResetEvent = createEvent();
  const argEditEvent = createEvent<REQ>();
  $store
    .on(attachedFx.doneData, (state, payload) => {
      return { ...state, res: payload };
    })
    .on(attachedFx.fail, (state, payload) => {
      return { ...state, res: { status: payload.error.status } as RES };
    })
    .on(argEditEvent, (state, payload) => {
      return { ...state, req: payload };
    })
    .reset(argResetEvent);
  sample({ clock: argEditEvent, target: attachedFx });
  return { $store, argEditEvent, argResetEvent, attachedFx };
}

/*
export function createStoreAndEventAndAttachForPaginatedEffect<
  REQ extends {
    currentPage: number;
    itemsPerPage: number;
  },
  RES extends TipsApiResType<PaginatedResType<any>>
>({
  initialReq,
  initialFx,
}: {
  initialReq: REQ;
  initialFx: Effect<REQ, RES, TipsApiResType<void>>;
}) {
  const $store = createStore<{
    req: REQ;
    res: RES;
  }>({
    req: initialReq,
    res: {
      data: { currentPage: 1, itemsPerPage: 1, totalItems: 1, items: [] },
    },
  });
  const attachedFx = attach({
    source: $store.map((state) => state.req),
    effect: initialFx,
  });
  const argResetEvent = createEvent();
  const argEditEvent = createEvent<REQ>();
  const argNextPageEvent = createEvent();

  $store
    .on(attachedFx.doneData, (state, payload) => {
      const resultItems = [...state.res.data.items, ...payload.data.items];
      return {
        ...state,
        res: { ...state.res, data: { ...state.res.data, items: resultItems } },
      };
    })
    .on(argNextPageEvent, (state, payload) => {
      const nextPage = state.req.currentPage + 1;
      return {
        ...state,
        req: { ...state.req, currentPage: nextPage },
      };
    })
    .on(argEditEvent, (state, payload) => {
      return { ...state, req: payload };
    })
    .on(attachedFx.fail, (state, payload) => {
      return { ...state, res: { status: payload.error.status } as RES };
    })
    .reset(argResetEvent);

  sample({ clock: argEditEvent, target: attachedFx });
  sample({ clock: argNextPageEvent, target: attachedFx });

  return { $store, argEditEvent, argResetEvent, argNextPageEvent, attachedFx };
}
*/
